import { Material, PBRBaseMaterial, TransformNode } from "@babylonjs/core";
import Fishmovement from "./fishmovement";
import { AddWP } from "./GameManager/narration";

export default class FishNetMovement extends Fishmovement {
  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public start(path: string[]): void {
    super.start(path);
  }

  public waypointLogic(flyerContainer: TransformNode) {
    return super.waypointLogic(flyerContainer);
  }

  public moveFlyer(flyerContainer: TransformNode) {
    return super.moveFlyer(flyerContainer);
  }

  public stop(): void {
    this._materialsSet = false;
    return super.stop();
  }

  public runningAway(addWp: AddWP): void {
    return super.runningAway(addWp);
  }

  private _materialsSet: boolean = false;
  public onUpdate(): void {
    if (!this.initDone) return;

    if (!this._materialsSet) {
      let scene = this.getScene();
      this.flyer.getChildMeshes().forEach((mesh) => {
        let url: string;
        switch (mesh.name) {
          case "Mesh.Net.001":
            url = "./assets/environment_assets/fish_net/Net-Net.material";
            break;
          case "Mesh.Plane":
          case "Mesh.Plane.003":
          case "Mesh.Plane.004":
          case "Mesh.Plane.005":
            url = "./assets/environment_assets/fish_net/Medaka_Ball.material";
            break;
          case "Mesh.Mesh_4":
            url =
              "./assets/creatures/medaka/Material_1_Bake-Material_1_Bake.material";
            break;
          default:
            url = null;
            break;
        }

        if (url)
          fetch(url)
            .then((response) => response.json())
            .then((json) => {
              let material = PBRBaseMaterial.Parse(
                json,
                scene,
                "./assets/"
              ).clone(mesh.name) as PBRBaseMaterial;
              mesh.material = material;
              console.log(material._albedoTexture);
            });
        else mesh.material = null;
      });

      this._materialsSet = true;
    }
  }
}
