import { Node } from "@babylonjs/core/node";
import ScrollCamera from "../scroll";
import WaypointManager from "./waypoint-manager";
import { Credits } from "./credits";
import AudioManager from "./audio-manager";
import GameManager from "./game-manager";
import HUD from "./hud";
import { Inventory, InventoryItem } from "./inventory";
import {
  MediaElement,
  MediaInfo,
  MediaOverlay,
  MediaType,
} from "./media-overlay";
import Quiz from "./quiz";
import { Sidenav } from "./sidenav";
import RotationHint from "./rotation-hint";
import { sleep } from "../utilities";
import { Footer } from "./footer";
import TrackingManager, { PageTitle } from "./tracking-manager";
import { HotspotOverlay } from "./hotspot-overlay";
import Narration from "./narration";

export default class GuiManager extends Node {
  private static instance: GuiManager;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public static getInstance(): GuiManager {
    if (!GuiManager.instance) GuiManager.instance = new GuiManager();

    return GuiManager.instance;
  }

  public onInitialize(): void {
    if (this.isTablet() && this.orientationIsPortrait()) {
      HUD.getInstance().setTablet();
    } else if (this.isTablet() && !this.orientationIsPortrait()) {
      HUD.getInstance().setDesktop();
    } else if (this.isMobile()) {
      HUD.getInstance().setMobile();
    } else {
      HUD.getInstance().setDesktop();
    }

    if (this.isMobile()) {
      if (!this.orientationIsPortrait()) {
        RotationHint.getInstance().open();
        // console.log("[GuiManager] Displaying rotation hint.");
      } else {
        RotationHint.getInstance().close();
        // console.log("[GuiManager] Hiding rotation hint.");
      }
    }

    HUD.getInstance().toggleGaugeLabels(true);

    let inventoryItems: InventoryItem[] = [
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Porpoise_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Porpoise_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Porpoise_Silhouette_Black.png",
      },
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Seaturtle_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Seaturtle_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Seaturtle_Silhouette_Black.png",
      },
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Medaka_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Medaka_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Medaka_Silhouette_Black.png",
      },
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Jellyfish_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Jellyfish_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Jellyfish_Silhouette_Black.png",
      },
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Anglerfish_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Anglerfish_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Anglerfish_Silhouette_Black.png",
      },
      {
        unlocked: false,
        previewUrlMobile: "./assets/gui/images/Barreleye_Silhouette.png",
        previewUrlDesktop: "./assets/gui/images/Barreleye_Silhouette.png",
        previewLockedUrl: "./assets/gui/images/Barreleye_Silhouette_Black.png",
      },
    ];

    Inventory.getInstance().setItems(inventoryItems);

    Credits.getInstance();

    Sidenav.getInstance();

    Footer.getInstance().toggleFooter(false);

    window.addEventListener("resize", async () => {
      // It seems that Safari on iOS doesn't trigger the resize event at the right time so we need to force a pause here
      await sleep(100);

      if (this.isTablet() && this.orientationIsPortrait()) {
        HUD.getInstance().setTablet();
      } else if (this.isTablet() && !this.orientationIsPortrait()) {
        HUD.getInstance().setDesktop();
      } else if (this.isMobile()) {
        HUD.getInstance().setMobile();
      } else {
        HUD.getInstance().setDesktop();
      }
      
      Narration.getInstance().applyDesktopOrMobileLayout();

      if (this.isMobile()) {
        if (!this.orientationIsPortrait()) {
          RotationHint.getInstance().open();
          // console.log("[GuiManager] Displaying rotation hint.");
        } else {
          RotationHint.getInstance().close();
          // console.log("[GuiManager] Hiding rotation hint.");
        }
      }

      // Hide gauge labels on mobile and on tablets in portrait mode after the second waypoint
      if (
        WaypointManager.waypointTracker.currentWaypointId >= 2 &&
        (this.isMobile() || (this.isTablet() && this.orientationIsPortrait()))
      ) {
        HUD.getInstance().toggleGaugeLabels(false);
      } else {
        HUD.getInstance().toggleGaugeLabels(true);
      }

      // Update quiz layout
      Quiz.getInstance().applyDesktopOrMobileLayout();

      // Handle footer resizing
      if (!Footer.footerIsShown) {
        Footer.getInstance().getFooterHeightInBackground();
      } else {
        Footer.getInstance().getFooterHeightInForeground();
        HUD.getInstance().toggleGauge(false);
        HUD.getInstance().toggleHeaderbar(false);
      }
    });
  }

  // Detecting mobile browsers as demonstrated on http://detectmobilebrowsers.com/
  // AFAIK tablets are not treated as mobile devices here.
  public isMobile(): boolean {
    let isMobile = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substring(0, 4)
        )
      )
        isMobile = true;
    })(navigator.userAgent || navigator.vendor);

    // console.log("[GuiManager] Mobile browser detected? " + isMobile);
    return isMobile;
  }

  public isMobileOrTablet(): boolean {
    let isMobileOrTablet: boolean = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substring(0, 4)
        )
      )
        isMobileOrTablet = true;
    })(navigator.userAgent || navigator.vendor);

    // Check for touch support to detect false negatives
    if (!isMobileOrTablet && "ontouchend" in document) {
      isMobileOrTablet = true;
    }

    // console.log("[GuiManager] Mobile browser or tablet detected? " + isMobileOrTablet);
    return isMobileOrTablet;
  }

  public isTablet(): boolean {
    if (!this.isMobile() && this.isMobileOrTablet()) {
      return true;
    } else {
      return false;
    }
  }

  // Determine if the mobile layout should be used. Returns true for mobile devices, not tablets or desktop
  public useMobileLayout(): boolean {
    let useMobileLayout: boolean = false;

    if (this.isMobile() && this.orientationIsPortrait()) {
      useMobileLayout = true;
    } else {
      useMobileLayout = false;
    }

    // console.log("[GuiManager] Using mobile layout? " + useMobileLayout);
    return useMobileLayout;
  }

  public orientationIsPortrait(): boolean {
    // console.log("[GuiManager] Screen orientation is portraint? " + (window.outerWidth < window.outerHeight));
    return window.outerWidth < window.outerHeight;
  }

  public openMediaOverlay(id: number): void {
    // -1 is the default value if the hotspotId wasn't set
    if (id === -1 || typeof id === "undefined") {
      console.log("Hotspot ID is not valid.");
      return;
    }

    // Reading JSON file with overlay content
    let mediaOverlayContent: JSON = require("/src/scenes/GameManager/media-overlay-content.json");
    let mediaElements: MediaElement[] = [];
    mediaOverlayContent[id]["mediaElements"].forEach(
      (mediaElementJson: string) => {
        let mediaElement: MediaElement = {
          type: mediaElementJson["type"],
          src: mediaElementJson["src"],
          source: mediaElementJson["source"],
          caption: mediaElementJson["caption"],
        };

        if (mediaElementJson["type"] === "MediaType.Image")
          mediaElement.type = MediaType.Image;
        if (mediaElementJson["type"] === "MediaType.Video")
          mediaElement.type = MediaType.Video;

        mediaElements.push(mediaElement);
      }
    );

    let mediaInfos: MediaInfo[] = [];
    mediaOverlayContent[id]["mediaInfos"].forEach(
      (mediaInfoJson: MediaInfo) => {
        let mediaInfo: MediaInfo = { header: "", description: [] };
        mediaInfo.header = mediaInfoJson["header"];
        mediaInfoJson["description"].forEach((descriptionJson: string) => {
          mediaInfo.description.push(descriptionJson);
        });
        mediaInfos.push(mediaInfoJson);
      }
    );

    MediaOverlay.getInstance().open(
      mediaElements,
      mediaInfos,
      HotspotOverlay.hotspotList[id].displayText
    );
  }

  public updateDepth(depth: number): void {
    HUD.getInstance().setDepth(depth);
  }

  public updatePressure(pressure: number): void {
    HUD.getInstance().setPressure(pressure);
  }

  public updateTemperature(temperature: number): void {
    HUD.getInstance().setTemperature(temperature);
  }

  public openInventory(): void {
    TrackingManager.getInstance().clickNavigation(
      WaypointManager.getInstance().isFirstWaypoint()
        ? PageTitle.STARTPAGE
        : PageTitle.CANVAS,
      PageTitle.INVENTORY,
      PageTitle.INVENTORY
    );
    Inventory.getInstance().open();
  }

  public openMenu(): void {
    Sidenav.getInstance().openNav();
  }

  public toggleMute(): void {
    AudioManager.getInstance().toggleMuteAudio();
  }

  public pauseGame(pause: boolean): void {
    GameManager.getInstance().pauseGame(pause);
  }
}
