import AudioManager from "./audio-manager";
import GuiManager from "./gui-manager";

export class Credits {
  private static instance: Credits;

  constructor() {
    if (Credits.instance) return null;

    let creditsContainer = document.createElement("div");
    creditsContainer.id = "creditsContainerOverlay";
    creditsContainer.className = "element-hidden";

    creditsContainer.innerHTML = `
            <div id='creditsCloseButtonContainer' class='close-button-container'>
                <img 
                    src='./assets/gui/svg/close.png'
                    alt='close button'
                    id='creditsCloseButton'
                    class='close-button'
                >
            </div>

            <div id='creditsInfobox'>
              <div class='credits-infobox'>
                  <div id='creditsInfoboxHeader' class='credits-infobox-header font-h-two'>
                      Abspann
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Idee:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Dinesh Chenchanna, Yvonne Gögelein 
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Konzept & Texte: 
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Yvonne Gögelein, Marie Wellershoff  
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      User Interface Design:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Laura Weiss   
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Umsetzung:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                  ZDF Digital Medienproduktion GmbH
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                  Yvonne Abbel, Jan-Philipp Döhring, Markus Ellner, Marcus Fass, Stefanie Jellen, Patrick Jeremic, Pascal Jester, Dominic Ladendorf, Tom Putsch, Christian Mohr, Tatyana Sachs, Marius Scheffel, Eva Witte, Matthew Zaczyk   
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Technische Betreuung ZDF:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Marc Hoffrichter
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Produktion ZDF:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Ann-Katrin Eckart, Kerstin Schönborn
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      In Kooperation mit:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Deutsche UNESCO-Kommission, Geomar, Stiftung Deutsches Meeresmuseum 
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Sprecherin:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Mirjam Radovic
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Redaktion ZDF:
                  </div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      Dinesh Chenchanna, Yvonne Gögelein 
                  </div>
                  <div class='credits-infobox-break'></div>
                  <div id='creditsInfoboxText' class='credits-infobox-text font-copy'>
                      © ZDF 2023
                  </div>
                  <div class='credits-infobox-break'></div>
              </div>
            </div>

        `;

    document
      .getElementById("guiContainerOverlay")
      .appendChild(creditsContainer);

    let closeButton = document.getElementById(
      "creditsCloseButton"
    ) as HTMLButtonElement;
    closeButton.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      this.close();
    });
  }

  public static getInstance(): Credits {
    if (!Credits.instance) Credits.instance = new Credits();

    return Credits.instance;
  }

  public open(): void {
    GuiManager.getInstance().pauseGame(true);

    let creditsContainer = document.getElementById("creditsContainerOverlay");
    creditsContainer.className = "credits-container";
    creditsContainer.style.flexDirection =
      GuiManager.getInstance().useMobileLayout() ? "column" : "row";

    let invModelInfo = document.getElementById("creditsInfobox");
    invModelInfo.className = GuiManager.getInstance().useMobileLayout()
      ? "credits-infobox-mobile"
      : "credits-infobox-desktop";
      
    if (!GuiManager.getInstance().useMobileLayout())
      invModelInfo.style.width = "50%";
    else invModelInfo.style.width = "unset";

    GuiManager.getInstance().useMobileLayout()
      ? document
          .getElementById("creditsCloseButtonContainer")
          .classList.add("close-button-container-mobile")
      : document
          .getElementById("creditsCloseButtonContainer")
          .classList.remove("close-button-container-mobile");

    document.getElementById("renderCanvas").style.backgroundColor =
      "rgba(0,0,0,0.4)";
    document.getElementById("guiContainerOverlay").style.backgroundColor =
      "rgba(0,0,0,0.4)";
  }

  public close(): void {
    document.getElementById("creditsContainerOverlay").className =
      "element-hidden";
    GuiManager.getInstance().pauseGame(false);

    document.getElementById("renderCanvas").style.backgroundColor =
      "rgba(0,0,0,0)";
    document.getElementById("guiContainerOverlay").style.backgroundColor =
      "rgba(0,0,0,0)";
  }
}
