import { Scene } from "@babylonjs/core";
import { Node } from "@babylonjs/core/node";

export default class GameManager extends Node {
  private static instance: GameManager;

  private _paused: boolean = false;
  private _scene2: Scene;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public static getInstance(): GameManager {
    if (!GameManager.instance) GameManager.instance = new GameManager();

    return GameManager.instance;
  }

  public pauseGame(paused: boolean): void {
    this._paused = paused;
  }

  public isPaused(): boolean {
    return this._paused;
  }

  private _cleanCache: boolean = false;
  public onUpdate(): void {
    if (GameManager.getInstance()._cleanCache) {
      // console.log("[GameManager] Clear texture buffer cache");
      this.getScene().cleanCachedTextureBuffer();
      GameManager.getInstance()._cleanCache = false;
    }
  }

  public clearCache(): void {
    this._cleanCache = true;
  }
}
