export default class RotationHint {
  private static instance: RotationHint;

  public static getInstance(): RotationHint {
    if (!RotationHint.instance) RotationHint.instance = new RotationHint();

    return RotationHint.instance;
  }

  constructor() {
    if (RotationHint.instance) return null;

    let rotationHintContainer = document.createElement("div");
    rotationHintContainer.id = "rotationHintContainer";
    rotationHintContainer.className = "element-hidden";

    rotationHintContainer.innerHTML = `
    <div id=rotationHintContainerSecondary class='rotation-hint-container-secondary'>
      <div id='rotationHintText' class='rotation-hint-text font-h-two'>
        Drehe dein Gerät, um<br>in die Tiefe einzutauchen   
      </div>
      <img 
        src='./assets/gui/svg/ROTATE.svg'
        id='rotationHintImage'
        class='rotation-hint-image'
      >
    </div>
    `;

    // The rotation hint needs to be attached to the body and not guiContainerOverlay because otherwise the sidenav would push it to the side when it's open
    document
      .getElementsByTagName("body")[0]
      .appendChild(rotationHintContainer);
  }

  public open(): void {
    document.getElementById("rotationHintContainer").className = "rotation-hint-container";
  }


  public close(): void {
    document.getElementById("rotationHintContainer").className = "element-hidden";
  }
}
