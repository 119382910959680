import {
  AbstractMesh,
  Axis,
  Mesh,
  MeshBuilder,
  Observer,
  Quaternion,
  Scene,
  SceneLoader,
  Space,
  Tools,
  TransformNode,
  Vector3,
} from "@babylonjs/core";
import { visibleInInspector } from "./decorators";
import { AddWP } from "./GameManager/narration";

export default class Fishmovement extends Mesh {
  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  @visibleInInspector("boolean", "Spawn Mesh", true)
  private _spawnMesh: boolean;

  @visibleInInspector("number", "Mesh Index", 0)
  private _meshIndex: number;

  @visibleInInspector("string", "Object Name")
  private _objectName: string;

  @visibleInInspector("string", "ModelPath")
  private _modelPath: string;

  @visibleInInspector("string", "ModelFile")
  private _modelFile: string;

  @visibleInInspector("Vector3", "RotationCorrection", new Vector3(0, 0, 0))
  private _modelRotationCorrection: Vector3;

  @visibleInInspector("Vector3", "ModelScale", new Vector3(1.0, 1.0, 1.0))
  private _modelScale: Vector3;

  @visibleInInspector("number", "Speed", 0.03)
  private _speed: number;

  @visibleInInspector("number", "RunningAwaySpeed", 0.06)
  private _runningAwaySpeed;

  @visibleInInspector("number", "RunningAwayTurnSpeedFactor", 10)
  private _runningAwayTurnSpeedFactor;

  @visibleInInspector("boolean", "DisposeOnPathDone", true)
  private _disposeOnPathDone;

  private _path: Vector3[] = [];
  private _currentWP: number;
  private _pathFinished: boolean;

  private _flightContainer: TransformNode;
  public flyer: AbstractMesh;
  public initDone: boolean;

  private _observer: Observer<Scene>;

  private _movementSpeed = 0.03;
  private _turnSpeed = 0.01;

  private _objectStartPosition: Vector3;
  private _objectStartRotation: Vector3;
  private _objectStartQuaternion: Quaternion;

  public start(path: string[]): void {
    if (!this.isEnabled()) return;

    this._movementSpeed = this._speed;
    this._turnSpeed = 0.01;

    this._path = [];
    path.forEach((wpName) => {
      let wp = this.getScene().getNodeByName(wpName) as Mesh;
      if (wp) this._path.push(wp.absolutePosition);
    });

    this._currentWP = 0;
    this._pathFinished = false;

    let flightContainer: TransformNode;
    let flyer: AbstractMesh;
    let loadingDone: boolean = false;
    if (this._spawnMesh) {
      flyer = MeshBuilder.CreateBox(
        this.name + "FlyerMesh",
        { width: 0.01, height: 0.01 },
        this.getScene()
      );

      flyer.position = this._path[0];
      flightContainer = new TransformNode(this.name + "ContainerBox");

      flyer.parent = flightContainer;
      flightContainer.parent = this;

      flightContainer.rotate(Axis.X, 0, Space.WORLD); // needed?

      let modelCorrection = this._modelRotationCorrection;
      let scale = this._modelScale;
      let meshIndex = this._meshIndex;
      SceneLoader.ImportMesh(
        "",
        this._modelPath,
        this._modelFile,
        this.getScene(),
        function (meshes) {
          // Hacky way to select the wanted mesh, but name filtering doesn't work
          flyer = meshes[meshIndex].clone("Mesh", flightContainer);
          flyer.rotate(Axis.X, Tools.ToRadians(modelCorrection.x));
          flyer.rotate(Axis.Y, Tools.ToRadians(modelCorrection.y));
          flyer.rotate(Axis.Z, Tools.ToRadians(modelCorrection.z));
          flightContainer.scaling = new Vector3(1, 1, 1);
          flyer.scaling = scale;

          loadingDone = true;
        }
      );

      SceneLoader.Load;
      flightContainer.setAbsolutePosition(this.absolutePosition);
      flightContainer.lookAt(this._path[0], null, null, null, Space.WORLD);
    } else {
      flightContainer = this.getScene().getNodeByName(
        this._objectName
      ) as TransformNode;
      if (!flightContainer) {
        console.error("Could not find object with name " + this._objectName);
        return;
      }

      this._objectStartPosition = flightContainer.absolutePosition.clone();
      if (!flightContainer.rotationQuaternion) {
        this._objectStartRotation = flightContainer.rotation.clone();

        flightContainer.rotationQuaternion =
          flightContainer.rotation.toQuaternion();
      } else {
        this._objectStartQuaternion =
          flightContainer.rotationQuaternion.clone();
      }

      loadingDone = true;
    }

    this._observer = this.getScene().onBeforeRenderObservable.add(() => {
      if (!loadingDone) {
        return;
      }

      if (!this.initDone) {
        this.flyer = flyer;
        this._flightContainer = flightContainer;
        this.initDone = true;
      }

      if (this._pathFinished) {
        if (this._disposeOnPathDone) this.stop();
        return;
      }

      this.waypointLogic(flightContainer);
      this.moveFlyer(flightContainer);
    });
  }

  public runningAway(addWp: AddWP): void {
    if (!this._flightContainer) {
      console.error("No flight container set for animation " + this.name);
      return;
    }
    let wp = this.getScene().getNodeByName(addWp.wpName) as Mesh;
    if (!wp) {
      console.error("Waypoint with name " + this.name + " not found");
      return;
    }

    this._movementSpeed = this._runningAwaySpeed;
    this._turnSpeed = this._turnSpeed * this._runningAwayTurnSpeedFactor;

    this._path = [this._flightContainer.absolutePosition, wp.absolutePosition];
    this._currentWP = 1;
    this._pathFinished = false;

    if (addWp.instantTurn)
      this._flightContainer.lookAt(
        wp.absolutePosition,
        null,
        null,
        null,
        Space.WORLD
      );
  }

  public stop(): void {
    this.getScene().onBeforeRenderObservable.remove(this._observer);

    if (!this._spawnMesh) {
      // if not spawned, reset to org position and rotation
      this._flightContainer.setAbsolutePosition(this._objectStartPosition);
      if (!this._objectStartQuaternion)
        this._flightContainer.rotation = this._objectStartRotation;
      else
        this._flightContainer.rotationQuaternion = this._objectStartQuaternion;
    }
    // else dispose the container
    else if (this._flightContainer) this._flightContainer.dispose();

    this.initDone = false;
  }

  public waypointLogic(flyerContainer: TransformNode) {
    let v1: Vector3 = this._path[this._currentWP];
    let distance: number = Vector3.Distance(
      v1,
      flyerContainer.absolutePosition
    );

    if (Math.abs(distance - this._movementSpeed) <= this._movementSpeed / 2) {
      if (this._path[this._currentWP + 1]) this._currentWP++;
      else this._pathFinished = true;
    }
  }

  public easeInOutQuart(x: number): number {
    return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
  }

  public moveFlyer(flyerContainer: TransformNode) {
    let targetPosition: Vector3 = this._path[this._currentWP];

    let diff = targetPosition.subtract(flyerContainer.absolutePosition);

    /*
    let fact = 1;
    if (this._objectStartPosition) {
      let maxDiff = this._objectStartPosition.subtract(targetPosition);
      fact = this.easeInOutQuart(diff.lengthSquared() / maxDiff.lengthSquared());
    }
    console.log(fact);
    */

    flyerContainer.translate(
      flyerContainer.forward,
      this._movementSpeed, // * fact,
      Space.WORLD
    );

    let myRotation = flyerContainer.rotationQuaternion.toEulerAngles();

    if (diff.length() - this._movementSpeed <= 0.01) return;

    let dir = diff.normalize();
    let rightDotDir = Vector3.Dot(flyerContainer.right, dir);

    if (Vector3.Dot(flyerContainer.forward, dir) < 0) {
      if (rightDotDir >= 0) {
        rightDotDir = 1;
      } else {
        rightDotDir = -1;
      }
    }

    if (Math.abs(rightDotDir) > 0.01) {
      if (rightDotDir > 0) {
        flyerContainer.rotate(Axis.Y, +this._turnSpeed, Space.LOCAL);
      } else {
        flyerContainer.rotate(Axis.Y, -this._turnSpeed, Space.LOCAL);
      }
    }

    let upDotDir = null;
    upDotDir = Vector3.Dot(flyerContainer.up, dir);
    if (Math.abs(upDotDir) > 0.01) {
      if (upDotDir > 0) {
        flyerContainer.rotate(Axis.X, -this._turnSpeed, Space.LOCAL);
      } else {
        flyerContainer.rotate(Axis.X, +this._turnSpeed, Space.LOCAL);
      }
    }

    let zR = 0;
    if (myRotation.z > 0.05 || myRotation.z < -0.05) {
      if (myRotation.z < 0) {
        zR = this._turnSpeed;
      } else {
        zR = -this._turnSpeed;
      }
      flyerContainer.rotate(Axis.Z, zR, Space.LOCAL);
    }
  }
}
