import {
  Path3D,
  PointerEventTypes,
  UniversalCamera,
  Vector3,
  IWheelEvent,
} from "@babylonjs/core";
import { Game } from "..";
import AudioManager from "./GameManager/audio-manager";
import { Footer } from "./GameManager/footer";
import GameManager from "./GameManager/game-manager";
import GuiManager from "./GameManager/gui-manager";
import { HotspotOverlay } from "./GameManager/hotspot-overlay";
import HUD from "./GameManager/hud";
import Narration, { NarrationInfo } from "./GameManager/narration";
import Quiz from "./GameManager/quiz";
import TrackingManager from "./GameManager/tracking-manager";
import WaypointManager from "./GameManager/waypoint-manager";
import { easeInOutCubic, lerp, sleep } from "./utilities";

export default class ScrollCamera extends UniversalCamera {
  private static instance: ScrollCamera;

  // ScrollCamera.waypoints has to be static, otherwise it's undefined in getClosestWaypoint()
  public static waypoints: Vector3[] = [];

  public movingCamera: UniversalCamera;
  public cameraPath: Path3D;
  private currentPointOnPath: number = 0;

  private smoothMoveInProgress: boolean = false;
  public cameraScrollingIsPaused = false;
  public static scrollNarrationInProgress: boolean = false;

  // Time in ms until next scroll/swipe is possible
  public static scrollDelay = 750;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public static getInstance(): ScrollCamera {
    if (!ScrollCamera.instance) {
      ScrollCamera.instance = new ScrollCamera();
    }
    return ScrollCamera.instance;
  }

  public onStart(): void {
    let waypointManager: WaypointManager = WaypointManager.getInstance();

    let scrollCamera: ScrollCamera = ScrollCamera.getInstance();
    scrollCamera.movingCamera = this;

    // If there are multiple cameras in the scene, the scrollable camera needs to be set as the active camera or scrolling won't work.
    this.getScene().activeCamera = scrollCamera.movingCamera;
    // console.log("Active camera in scene: " + this.getScene().activeCamera);

    scrollCamera.movingCamera.fov = 0.8;
    scrollCamera.movingCamera.minZ = 0.01;
    scrollCamera.movingCamera.maxZ = 15000; // Camera rendering distance
    scrollCamera.movingCamera.updateUpVectorFromRotation = true;
    scrollCamera.cameraPath = waypointManager.getPath();
    // Jumping to first waypoint. Default is 0 but for debugging it could be another one
    scrollCamera.movingCamera.position =
      waypointManager.getWaypoints()[
        waypointManager.getStartWaypointId()
      ].position;
    scrollCamera.currentPointOnPath =
      scrollCamera.cameraPath.getClosestPositionTo(
        scrollCamera.movingCamera.position
      );

    let touchStartY: number = -1;
    let touchEndY: number = -1;

    // Swiping on mobile
    if (GuiManager.getInstance().isMobileOrTablet()) {
      this.getScene().onPrePointerObservable.add(
        function () {
          if (
            !scrollCamera.smoothMoveInProgress &&
            scrollCamera.movingCamera.position !==
              waypointManager.getWaypoints()[0].position
          ) {
            // We need this to stop detecting swipes while other overlays are open
            let allowSwipe: boolean = true;

            // Mobile swipe detection with touchstart and touchend
            // Using the IWheelEvent like we do for desktop doesn't work on iOS because both movementY and deltaY are undefined
            document.addEventListener("touchstart", (e) => {
              if (touchStartY !== e.changedTouches[0].screenY && allowSwipe) {
                touchStartY = e.changedTouches[0].screenY;
              }
            });

            document.addEventListener("touchend", (e) => {
              if (
                touchStartY !== -1 &&
                touchEndY !== e.changedTouches[0].screenY &&
                allowSwipe
              ) {
                touchEndY = e.changedTouches[0].screenY;

                let delta: number = 0;

                // Swiping on mobile is the opposite direction of scrolling on desktop, so we need the opposite deltas
                // Swiping up = moving down
                if (touchEndY < touchStartY) {
                  delta = 1;
                }
                // Swiping down = moving up
                if (touchEndY > touchStartY) {
                  delta = -1;
                }

                if (delta != 0) {
                  // Scrolling along the camera path
                  if (!ScrollCamera.getInstance().cameraScrollingIsPaused) {
                    ScrollCamera.getInstance().moveCamera(delta);
                  }
                  // Scrolling through waypoint content
                  else if (
                    !ScrollCamera.scrollNarrationInProgress
                  ) {
                    if (document.getElementById("quizContainer")) {
                      if (
                        document.getElementById("quizBody").childElementCount !=
                        0
                      ) {
                        Quiz.getInstance().closeQuiz();
                      }
                    }
                    scrollCamera.scrollNarration(delta);
                  }
                }
              }
              allowSwipe = false;
            });
          }
        },
        PointerEventTypes.POINTERMOVE,
        false
      );
    }

    // Scrolling on desktop
    else {
      this.getScene().onPrePointerObservable.add(
        function (pointerInfo, eventState) {
          if (
            !scrollCamera.smoothMoveInProgress &&
            scrollCamera.movingCamera.position !==
              waypointManager.getWaypoints()[0].position
          ) {
            let event: IWheelEvent = pointerInfo.event as IWheelEvent;
            let delta: number = 0;
            // Negative deltaY = scrolling up
            // Positive deltaY = scrolling down
            if (event.deltaY) {
              // If VS Code marks deltaY as an error, it can be ignored. It works anyway.
              delta = event.deltaY;

              // Scrolling along the camera path
              if (!scrollCamera.cameraScrollingIsPaused) {
                scrollCamera.moveCamera(delta);
              }
              // Scrolling through waypoint content, currently only narration infos
              else if (!ScrollCamera.scrollNarrationInProgress) {
                if (document.getElementById("quizContainer")) {
                  if (
                    document.getElementById("quizBody").childElementCount != 0
                  ) {
                    Quiz.getInstance().closeQuiz();
                  }
                }
                scrollCamera.scrollNarration(delta);
              }
            }
          }
        },
        PointerEventTypes.POINTERWHEEL,
        false
      );
    }
  }

  public startScroll() {
    this.moveCamera(1); // 1 = moving down
    Narration.getInstance().hideNarrationOverlay();
  }

  public resetCamera() {
    this.currentPointOnPath = 0.2;
    this.moveCamera(-1);
    AudioManager.getInstance().resetAtmo();
  }

  public moveCamera(delta: number) {
    AudioManager.getInstance().playSound({
      fileName: "Schwarm_SFX_Underwater_Movement_light.m4a",
      loop: false,
      nonWPSound: true,
    });
    let waypointManager: WaypointManager = WaypointManager.getInstance();

    // Clear hotspots if we're moving to the next waypoint. This means we don't have to explicitly hide them when scrolling down and especially back up.
    HotspotOverlay.clearAllHotspots();

    let startPoint: number = this.currentPointOnPath;
    let endPoint: number;
    let movingDown: boolean;

    // The difference between mobile and desktop deltas was already made when calling moveCamera() in onStart().
    if (delta > 0 && this.currentPointOnPath < 1) {
      // If moving down
      movingDown = true;
    } else if (delta < 0 && this.currentPointOnPath > 0) {
      // If moving up
      movingDown = false;
    } else {
      // If scrolling up at the start or down at the end
      return;
    }

    endPoint = waypointManager.findNextStopPoint(
      this.currentPointOnPath,
      movingDown
    );

    // We shouldn't be able to scroll beyond 0 and 1 on the path.
    if (endPoint > 1) {
      endPoint = 1;
    } else if (endPoint < 0) {
      endPoint = 0;
    }

    this.smoothMove(startPoint, endPoint);
  }

  public moveCameraToPoint(
    delta: number,
    targetPointOnPath: number,
    targetWaypointId: number
  ) {
    AudioManager.getInstance().playSound({
      fileName: "Schwarm_SFX_Underwater_Movement_light.m4a",
      loop: false,
      nonWPSound: true,
    });

    // Clear anything that might be displayed
    HotspotOverlay.clearAllHotspots();
    Quiz.getInstance().closeQuiz();
    Narration.getInstance().hideNarrationOverlay();
    HUD.getInstance().togglePartners(false);
    AudioManager.getInstance().stopNarrationAudio();
    // Set new atmo
    AudioManager.getInstance().playAtmo(
      Narration.getInstance().getAtmoAtWaypoint(targetWaypointId)
    );

    let startPoint: number = this.currentPointOnPath;
    let endPoint: number;
    let movingDown: boolean;

    if (delta > 0 && this.currentPointOnPath < 1) {
      // If moving down
      movingDown = true;
    } else if (delta < 0 && this.currentPointOnPath > 0) {
      // If moving up
      movingDown = false;
    } else {
      // If scrolling up at the start or down at the end
      return;
    }

    endPoint = targetPointOnPath;

    // We shouldn't be able to scroll beyond 0 and 1 on the path.
    if (endPoint > 1) {
      endPoint = 1;
    } else if (endPoint < 0) {
      endPoint = 0;
    }

    this.smoothMove(startPoint, endPoint);
  }

  // Scroll direction (up or down) doesn't matter. If we're scrolling up, startPoint will be greater than endPoint and that's fine.
  private async smoothMove(startPoint: number, endPoint: number) {
    this.smoothMoveInProgress = true;

    let waypointManager: WaypointManager = WaypointManager.getInstance();

    let progress: number = 0;
    let progressEased: number = 0;
    let numberOfSteps: number = 100;

    while (progress < 1) {
      progress += 1 / numberOfSteps;
      progressEased = easeInOutCubic(progress);

      this.currentPointOnPath = lerp(startPoint, endPoint, progressEased);
      this.movingCamera.position = this.cameraPath.getPointAt(
        this.currentPointOnPath
      );

      // End point reached
      if (progress >= 1) {
        // To avoid lerp rounding errors that would put the camera at an incorrect position
        if (this.currentPointOnPath !== endPoint) {
          this.currentPointOnPath = endPoint;
        }

        WaypointManager.waypointTracker.currentWaypointId =
          waypointManager.findWaypointAtPointOnPath(this.currentPointOnPath);

        this.smoothMoveInProgress = false;
        return;
      }
      await sleep(10);
    }
  }

  // Scrolling through narration. What happens once the narration is finished is handled by lastPageFinishedListener
  private async scrollNarration(delta: number) {
    ScrollCamera.scrollNarrationInProgress = true;

    let narrationOverlay: Narration = Narration.getInstance();
    let waypointManager: WaypointManager = WaypointManager.getInstance();
    let narrationInfos: NarrationInfo[] =
      waypointManager.getCurrentNarrationInfos();

    // Is this necessary? Or is delta always != 0
    if (delta != 0) {
      AudioManager.getInstance().stopNarrationAudio();
      // Stop this when page is changed or only when WP is changed?
      // AudioManager.getInstance().stopSounds();
    }

    // Scrolling down
    if (delta > 0) {
      // If we've reached the last page and we're scrolling down, move camera down
      // Do not scroll at last waypoint though
      if (
        narrationOverlay.currentNarrationIndex === narrationInfos.length - 1 &&
        !WaypointManager.getInstance().isLastWaypoint()
      ) {
        // console.log("[scrollNarration] Scrolling down, last page finished");
        // Move camera down
        ScrollCamera.getInstance().cameraScrollingIsPaused = false;
        ScrollCamera.getInstance().moveCamera(1); // 1 = moving down
        narrationOverlay.hideNarrationOverlay();
      }
      // If we're scrolling down and there are no narration overlays for this waypoint
      else if (narrationInfos.length === 0) {
        // console.log("[scrollNarration] Scrolling down, no narration overlays");
        ScrollCamera.scrollNarrationInProgress = false;
      }
      // Going to next page
      else if (
        narrationOverlay.currentNarrationIndex <
        narrationInfos.length - 1
      ) {
        // console.log("[scrollNarration] Scrolling down, going to next page");
        narrationOverlay.currentNarrationIndex++;

        narrationOverlay.showNarrationOverlay(
          narrationInfos[narrationOverlay.currentNarrationIndex]
        );
      }
    }
    // Scrolling up
    else if (delta < 0) {
      // If we've reached the first page and are scrolling up but aren't at the first waypoint
      if (
        narrationOverlay.currentNarrationIndex === 0 &&
        !waypointManager.isFirstWaypoint()
      ) {
        // console.log(
        //   "[scrollNarration] Scrolling up from first page, not at first waypoint"
        // );
        this.cameraScrollingIsPaused = false;
        ScrollCamera.scrollNarrationInProgress = false;
        narrationOverlay.hideNarrationOverlay();
        this.moveCamera(delta);
      }
      // If we've reached the first page at the first waypoint, we don't want to hide it by scrolling farther
      else if (
        narrationOverlay.currentNarrationIndex === 0 &&
        waypointManager.isFirstWaypoint()
      ) {
        // console.log(
        //   "[scrollNarration] Scrolling up from first page at first waypoint"
        // );
        ScrollCamera.scrollNarrationInProgress = false;
      }
      // If we're scrolling up and there are no narration overlays for this waypoint
      else if (narrationInfos.length === 0) {
        // console.log("[scrollNarration] Scrolling up, no narration overlays");
        // NarrationOverlay.narrationTracker.lastPageFinished = false;
        // this.moveCamera(delta);
        ScrollCamera.scrollNarrationInProgress = false;
      }
      // Going to previous page
      else if (narrationOverlay.currentNarrationIndex > 0) {
        // console.log("[scrollNarration] Scrolling up, going to previous page");
        narrationOverlay.currentNarrationIndex--;

        narrationOverlay.showNarrationOverlay(
          narrationInfos[narrationOverlay.currentNarrationIndex]
        );
      }

      /*       if (waypointManager.isLastWaypoint())
        Footer.getInstance().toggleFooter(false); */
    }

    // console.log(
    //   "[scrollNarration] delta: " +
    //     delta +
    //     " | currentNarrationIndex: " +
    //     narrationOverlay.currentNarrationIndex +
    //     " | narrationInfos.length: " +
    //     narrationInfos.length
    // );

    // The scroll delay until scrollNarrationInProgress is set back to false is built into showNarrationOverlay().
    // If we wait for the scroll delay here, it won't be triggered after the first thing that gets displayed at a waypoint.
  }
}
