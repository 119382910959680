import "@babylonjs/loaders/glTF";
import { visibleInInspector } from "./decorators";
import {
    InstantiatedEntries,
    Mesh,
    SceneLoader,
    Vector3,
} from "@babylonjs/core";
import BoidsManager from "./boids-manager";

export default class Fishboid extends Mesh {
  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  @visibleInInspector("string", "ModelPath")
  private _modelPath: string;
  @visibleInInspector("string", "ModelFile")
  private _modelFile: string;

  @visibleInInspector("number", "Count", 10, { min: 0, step: 1 })
  private _count: number;
  @visibleInInspector("number", "Speed", 1)
  private _speed: number;
  @visibleInInspector("number", "Initial Radius", 25, { min: 0 })
  private _initialRadius: number;
  @visibleInInspector("number", "Cohesion", 0.1, {
    min: 0,
    max: 1.5,
    step: 0.01,
  })
  private _cohesion: number;
  @visibleInInspector("number", "Separation", 0.4, {
    min: 0,
    max: 4,
    step: 0.1,
  })
  private _separation: number;
  @visibleInInspector("number", "Alignment", 1.0, {
    min: 0,
    max: 4,
    step: 0.1,
  })
  private _alignment: number;
  @visibleInInspector("number", "Separation Min Distance", 3.0, {
    min: 0,
    max: 50,
    step: 0.1,
  })
  private _separationMinDistance: number;
  @visibleInInspector("number", "Bound Radius Scale", 50, {
    min: 0,
    max: 100,
    step: 1,
  })
  private _boundRadiusScale: number;
  @visibleInInspector("string", "Target Mesh Name")
  private _target: string;

  private _myFish: {
    models: InstantiatedEntries[];
    boidsManager: BoidsManager;
    update: (deltaTime: number) => void;
  };
  public start(): void {
    this._myFish = this.loadFishFlock(
      this._modelPath, //e.g. "assets/creatures/medaka/"
      this._modelFile, //e.g. "medaka.glb"
      this._count
    );

    // Register a render loop to repeatedly render the scene
    this.getEngine().runRenderLoop(this.updateBoid);
  }

  private updateBoid(): void {
    const timeDiff = this.getEngine().getDeltaTime() / 1000.0;

    // update boids
    this._myFish.update(timeDiff);

    this.getScene().render();
  }

  public stop(): void {
    this.getEngine().stopRenderLoop(this.updateBoid);
    this._myFish.models.forEach((model) => {
      model.rootNodes.forEach((node) => node.dispose());
      model.animationGroups.forEach((animGroup) => animGroup.dispose());
      model.skeletons.forEach((skeleton) => skeleton.dispose());
    });

    this._myFish = null;
  }

  private loadFishFlock(
    modelpath,
    modelfile,
    total
  ): {
    models: InstantiatedEntries[];
    boidsManager: BoidsManager;
    update: (deltaTime: any) => void;
  } {
    let targetMesh = this.getScene().getMeshByName(this._target);
    let direction = targetMesh.absolutePosition.subtract(this.absolutePosition);
    const boidsManager = new BoidsManager(
      total,
      this.absolutePosition,
      this._cohesion,
      this._separation,
      this._separationMinDistance,
      this._alignment,
      this._initialRadius,
      this._boundRadiusScale,
      direction.multiply(new Vector3(this._speed, this._speed, this._speed))
    );
    const models: InstantiatedEntries[] = [];
    SceneLoader.LoadAssetContainer(
      modelpath,
      modelfile,
      this.getScene(),
      (container) => {
        container.addAllToScene();

        container.meshes.forEach((mesh) => {
          mesh.scaling.set(this.scaling.x, this.scaling.y, this.scaling.z);
          if (mesh.material) {
            mesh.material.freeze();
          }
        });

        for (let i = 0; i < total; i++) {
          const entries: InstantiatedEntries =
            container.instantiateModelsToScene((p) => {
              return "fish" + p + i;
            });
          for (const node of entries.rootNodes) {
            let position = boidsManager.getBoids()[i].position;
            node.position.set(position.x, position.y, position.z);
          }
          entries.animationGroups[0].speedRatio =
            1.0 + 0.1 * (Math.random() - 0.5);
          entries.animationGroups[0].play(true);
          entries.animationGroups[0].goToFrame(Math.floor(Math.random() * (entries.animationGroups[0].to - 1)));
          models.push(entries);

          boidsManager.addForce(() => {
            return direction.multiply(
              new Vector3(this._speed, this._speed, this._speed)
            );
          });
        }

        container.meshes[1].setEnabled(false);
      }
    );

    return {
      models,
      boidsManager,
      update: ((_boids, _models) => {
        return (deltaTime) => {
          _boids.update(deltaTime);
          let boids = _boids.getBoids();
          _models.forEach((m, index) => {
            for (const node of m.rootNodes) {
              let boid = boids[index];
              node.position.copyFrom(boid.position);
              node.setDirection(
                new Vector3(
                  boid.orientation.x,
                  boid.orientation.y,
                  boid.orientation.z
                )
              );
            }
          });
        };
      })(boidsManager, models),
    };
  }
}
