import { AnimationGroup, Scene } from "@babylonjs/core";
import { Node } from "@babylonjs/core/node";
import { group } from "console";
import Fishboid from "../fishboid";
import Fishmovement from "../fishmovement";
import SpritesheetAnimation from "../spritesheet-manager";
import { AnimationGroupInfo, MovementInfo } from "./narration";

export default class AnimationManager extends Node {
  private static instance: AnimationManager;

  private static _scene: Scene;

  private _currentAnimationGroups: Map<string, AnimationGroup> = new Map<
    string,
    AnimationGroup
  >();
  private _currentFishMovements: Map<string, Fishmovement> = new Map<
    string,
    Fishmovement
  >();
  private _currentFishBoids: Map<string, Fishboid> = new Map<
    string,
    Fishboid
  >();
  private _currentSpriteSheetAnims: Map<string, SpritesheetAnimation> = new Map<
    string,
    SpritesheetAnimation
  >();

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public static getInstance(): AnimationManager {
    if (!AnimationManager.instance)
      AnimationManager.instance = new AnimationManager();

    return AnimationManager.instance;
  }

  public onInitialized(): void {
    AnimationManager._scene = this.getScene();
  }

  public playAnimationGroup(groupInfo: AnimationGroupInfo): void {
    if (this._currentAnimationGroups.has(groupInfo.name)) return;

    let animGroup = AnimationManager._scene.getAnimationGroupByName(
      groupInfo.name
    );
    if (animGroup)
      this._currentAnimationGroups.set(
        groupInfo.name,
        animGroup.play(groupInfo.loop)
      );
    else console.log("AnimationGroup " + groupInfo.name + " not found");
  }

  public playMovement(movementInfo: MovementInfo): void {
    if (movementInfo.path) {
      if (this._currentFishMovements.has(movementInfo.name)) return;

      let movement = AnimationManager._scene.getNodeByName(
        movementInfo.name
      ) as Fishmovement;
      if (movement) {
        movement.start(movementInfo.path);
        this._currentFishMovements.set(movementInfo.name, movement);
      } else {
        console.error("Fishmovement " + movementInfo.name + " not found");
      }
    } else if (movementInfo.addWP) {
      if (!this._currentFishMovements.has(movementInfo.name)) return;
      this._currentFishMovements
        .get(movementInfo.name)
        .runningAway(movementInfo.addWP);
    }
  }

  public playBoid(name: string): void {
    if (this._currentFishBoids.has(name)) return;

    let boid = AnimationManager._scene.getMeshByName(name) as Fishboid;
    boid.start();
    this._currentFishBoids.set(name, boid);
  }

  public playSpriteSheetAnimation(name: string): void {
    if (this._currentSpriteSheetAnims.has(name)) return;

    let spriteAnim = AnimationManager._scene.getNodeByName(
      name
    ) as SpritesheetAnimation;
    if (spriteAnim) {
      spriteAnim.playAnimation();
      this._currentSpriteSheetAnims.set(name, spriteAnim);
    } else {
      console.error("SpritesheetAnimation " + name + " not found");
    }
  }

  public stopCurrentAnimations(): void {
    this._currentAnimationGroups.forEach((animGroup) => {
      animGroup.stop();
      animGroup.reset();
    });
    this._currentAnimationGroups.clear();

    this._currentFishMovements.forEach((movement) => movement.stop());
    this._currentFishMovements.clear();

    this._currentFishBoids.forEach((boid) => boid.stop());
    this._currentFishBoids.clear();

    this._currentSpriteSheetAnims.forEach((spriteAnim) =>
      spriteAnim.stopAnimation()
    );
    this._currentSpriteSheetAnims.clear();
  }
}
