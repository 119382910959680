import GuiManager from "./gui-manager";
import HUD from "./hud";
import WaypointManager from "./waypoint-manager";

type FooterContent = {
  preTitle: string;
  title: string;
  text: string;
  imgUrl: string;
  linkUrl: string;
};

export class Footer {
  private static instance: Footer;

  private static footerHeight: string;

  public content: FooterContent[] = [];

  private addContent(preTitle, title, text, imgUrl, linkUrl) {
    let content: FooterContent = {
      preTitle: preTitle,
      title: title,
      text: text,
      imgUrl: imgUrl,
      linkUrl: linkUrl,
    };

    this.content[this.content.length] = content;
  }

  constructor() {
    if (Footer.instance) return null;

    let endFooterOverlay = document.createElement("div");
    endFooterOverlay.id = "endFooterOverlay";

    let imageUrlPrefix: string = "./assets/gui/images/image_";
    let imageUrlSuffix: string = GuiManager.getInstance().useMobileLayout()
      ? "-mobil.jpg"
      : ".jpg";

    this.addContent(
      "Der Schwarm",
      "Der Schwarm",
      "Was passiert, wenn die <br> Natur zurückschlägt?",
      imageUrlPrefix + "01" + imageUrlSuffix,
      "https://schwarm.zdf.de"
    );
    this.addContent(
      "Der Schwarm",
      "Terra X: Der Schwarm – die Doku  (1/2) <br>Wie gefährlich sind Wale?",
      "Wale, die  Menschen angreifen - Wie realistisch sind solche Szenarien?",
      imageUrlPrefix + "02" + imageUrlSuffix,
      "https://www.zdf.de/dokumentation/terra-x/der-schwarm-die-doku-teil-eins-wie-gefaehrlich-sind-wale-100.html"
    );
    this.addContent(
      "Der Schwarm",
      "Terra X: Der Schwarm – die Doku (2/2) <br>Welche Bedrohungen lauern im Meer?",
      "Der Ozean ist noch immer größtenteils unerforscht und voller rätselhafter Phänomene.",
      imageUrlPrefix + "03" + imageUrlSuffix,
      "https://www.zdf.de/dokumentation/terra-x/der-schwarm-die-doku-teil-zwei-welche-bedrohungen-lauern-im-meer-102.html"
    );
    this.addContent(
      "Der Schwarm",
      "Themenpaket Ozeane, Meere und ihre Bewohner",
      "Die Unterwasserwelt und ihre Bewohner mit lebendigen Erklärclips, Videos und Dokus erleben.",
      imageUrlPrefix + "04" + imageUrlSuffix,
      "https://www.zdf.de/dokumentation/terra-x/themenpaket-meere-und-ozeane-100.html"
    );

    let footerHtml = "";

    if (this.content.length > 0) {
      for (var i = 0; i < this.content.length; i++) {
        if (this.content[i].preTitle.length == 0) {
          this.content[i].preTitle = "<br>";
        }

        footerHtml +=
          `<a id="contentBlock` +
            i +
            `" class="footer-content-block-` +
            (GuiManager.getInstance().isMobile() ? "mobile" : "desktop") +
            `" href="` +
            this.content[i].linkUrl +
            `" target="_blank" 
          >
            <img class="footer-block-image-` +
              (GuiManager.getInstance().isMobile() ? "mobile" : "desktop") +
              `" src="` +
              this.content[i].imgUrl +
            `">
            <div class="footer-text-container` +
              (GuiManager.getInstance().isMobile() ? "-mobile" : "") +
            `">
              <div class="footer-info-top` +
                (GuiManager.getInstance().isMobile() ? "-mobile" : "") +
              `">` +
                this.content[i].preTitle +
              `</div>
              <div class="footer-info-header` +
                (GuiManager.getInstance().isMobile() ? "-mobile" : "") +
              `">` +
                this.content[i].title +
              `</div>
              <div class="` +
                (GuiManager.getInstance().isMobile()
                  ? "element-hidden"
                  : "footer-info-description") +
              `">` +
                this.content[i].text +
              `</div>
            </div>
          </a>`;
      }

      let endFooter =
        `<div id="endFooter" class="end-footer footer-hidden">
            <div class="footer-title` +
              (GuiManager.getInstance().isMobile() ? "-mobile" : "") +
            `">
              Noch mehr zu „Der Schwarm“
            </div>
            <div 
              id="contentContainer"
              class="footer-content-container-` +
              (GuiManager.getInstance().isMobile() ? "mobile" : "desktop") +
            `">` +
              footerHtml +
            `</div>
          </div>
        `;

      endFooterOverlay.innerHTML = endFooter;
    }

    document.getElementById("endFooterContainer").appendChild(endFooterOverlay);
  }

  public static getInstance(): Footer {
    if (!Footer.instance) Footer.instance = new Footer();

    return Footer.instance;
  }

  public static footerIsShown = false;

  public toggleFooter(showFooter: boolean) {
    // console.log("[Footer] Toggling footer " + showFooter);
    Footer.footerIsShown = showFooter;

    if (showFooter) {
      document.getElementById("endFooter").classList.remove("footer-hidden");
      HUD.getInstance().toggleGauge(false);
      HUD.getInstance().toggleHeaderbar(false);

      document.getElementById("endFooter").style.height = Footer.footerHeight;
      document.getElementById("renderCanvas").style.backgroundColor = "rgba(0,0,0,0.4)";
      document.getElementById("guiContainerOverlay").style.backgroundColor = "rgba(0,0,0,0.4)";
    }
    else {
      if (WaypointManager.waypointTracker.currentWaypointId !== 0) {
        HUD.getInstance().toggleGauge(true);        
      }

      HUD.getInstance().toggleHeaderbar(true);

      document.getElementById("endFooter").style.height = "0";
      document.getElementById("renderCanvas").style.backgroundColor = "rgba(0,0,0,0)";
      document.getElementById("guiContainerOverlay").style.backgroundColor = "rgba(0,0,0,0)";
    }
  }

  // Move the footer into the background by changing its z-index
  public hideFooterInBackground(hideFooter: boolean) {
    hideFooter
      ? document.getElementById("endFooter").classList.add("footer-hidden")
      : document.getElementById("endFooter").classList.remove("footer-hidden");
  }

  // For the animated footer transition we need the absolute height of the footer, not a relative setting.
  // To get an absolute height while preserving the responsive layout, we set the footer's height to fit-content while it's hidden in the background, read the height, and set it back to 0px.
  // This has to happen at least one waypoint earlier than where the footer will be shown. Without a break, the animation won't play.
  public getFooterHeightInBackground() {
    this.hideFooterInBackground(true);

    document.getElementById("endFooter").style.height = "fit-content";
    Footer.footerHeight = document.getElementById("endFooter").offsetHeight.toString() + "px";
    // console.log("[Footer] Defining footer height as " + Footer.footerHeight);
    document.getElementById("endFooter").style.height = "0px";

    this.hideFooterInBackground(false);
  }

  // Get the absolute height of the footer when it is already in view
  public getFooterHeightInForeground() {
    document.getElementById("endFooter").style.height = "fit-content";
    Footer.footerHeight = document.getElementById("endFooter").offsetHeight.toString() + "px";
    // console.log("[Footer] Defining footer height as " + Footer.footerHeight);
    document.getElementById("endFooter").style.height = Footer.footerHeight;
  }
}