import { AbstractMesh, Mesh } from "@babylonjs/core";
import { visibleInInspector } from "./decorators";

export default class CloseNet extends Mesh {
  @visibleInInspector("string", "Target Name")
  private _targetName: string;

  private _targetMesh: AbstractMesh;
  private _fullDistance: number;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public onInitialized(): void {
    this.morphTargetManager.getTarget(0).influence = 0;
    this._targetMesh = this.getScene().getMeshByName(this._targetName);
    this._fullDistance = this.absolutePosition
      .subtract(this._targetMesh.absolutePosition)
      .length();
  }

  public onUpdate(): void {
    if (!this._targetMesh) return;

    this.morphTargetManager.getTarget(0).influence =
      1 -
      this.absolutePosition
        .subtract(this._targetMesh.absolutePosition)
        .length() /
        this._fullDistance;
  }
}
