import { Mesh, NodeMaterial } from "@babylonjs/core";
import { visibleInInspector } from "./decorators";
import MaterialLoader from "./material-loader";

export default class MyScript extends Mesh {
  @visibleInInspector("string", "SnippetID", "")
  private _snippetId: string;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  private _loaded: boolean = false;
  public onUpdate(): void {
    let horDistance = Math.abs(
      this.absolutePosition._y - this._scene.activeCamera.position._y
    );

    if (horDistance <= MaterialLoader.WP_DELTA) {
      if (this._loaded) return;
      const rootUrl = "./assets/";
      NodeMaterial.ParseFromFileAsync(
        "EIIB5P",
        rootUrl + this._snippetId,
        this.getScene()
      ).then((mat) => (this.material = mat));
      this._loaded = true;
    } else {
      this.material?.dispose(true, true);
      this._loaded = false;
    }
  }
}
