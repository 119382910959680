import { findHtmlAncestors } from "../utilities";
import AnimationManager from "./animation-manager";
import AudioManager from "./audio-manager";
import GuiManager from "./gui-manager";
import { Inventory, Model } from "./inventory";
import ModelViewer from "./model-viewer";
import TrackingManager, {
  FunctionDetail,
  FunctionType,
  PageTitle,
} from "./tracking-manager";

export default class Quiz {
  private static instance: Quiz;

  public static getInstance(): Quiz {
    if (!Quiz.instance) Quiz.instance = new Quiz();

    return Quiz.instance;
  }

  constructor() {
    if (Quiz.instance) return null;

    let quizContainer = document.createElement("div");
    quizContainer.id = "quizContainer";
    quizContainer.className = "element-hidden";

    quizContainer.innerHTML = `
                <div id='quizMain' class='quiz-main fade-in'>
                    <div id='quizHeader' class='quiz-header font-copy'>
                        QUIZ 01
                        <hr id='quizHeaderLine' class='quiz-header-line'>
                    </div>
                    <div id='quizBody'>
                    </div>
                </div>
        `;

    document.getElementById("guiContainerOverlay").appendChild(quizContainer);
  }

  public startQuiz1(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 01 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir den Schweinswal in 3D an oder betrachte ihn per Augmented Reality in deiner Umgebung!";

    this.estimationQuestion({
      parent: quizBody,
      id: "quiz01_question01",
      pageTitle: PageTitle.QUIZ_01,
      functionDetail: FunctionDetail.QUIZ_01_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_01_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_01_NEXT_QUESTION,
      headerText: "WAS SCHÄTZT DU?",
      question: "Wie alt werden Schweinswale im Durchschnitt?",
      sliderValues: {
        fixed: false,
        values: {
          sliderMin: "8",
          sliderMax: "16",
          sliderStepsize: "2",
        },
      },
      sliderDefault: "10",
      sliderUnit: "Jahre",
      correctMin: 10,
      correctMax: 12,
      correctAnswerHeader: "Richtig!",
      correctAnswerText:
        "Schweinswale werden 10–12 Jahre alt. Durch die vielen Gefahren werden die Tiere aber oft nicht älter als 4–6 Jahre.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Leider nein ...",
      wrongAnswerText:
        "Schweinswale werden nur 10–12 Jahre alt. Durch die vielen Gefahren werden die Tiere oft nicht älter als 4–6 Jahre.",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.Porpoise,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.estimationQuestion({
          parent: quizBody,
          id: "quiz01_question02",
          pageTitle: PageTitle.QUIZ_01,
          functionDetail: FunctionDetail.QUIZ_01_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_01_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_01_NEXT_QUESTION,
          headerText: "WAS SCHÄTZT DU?",
          question:
            "Wie viele tote Schweinswale wurden 2016 an Deutschlands Küsten gefunden?",
          sliderValues: {
            fixed: false,
            values: {
              sliderMin: "0",
              sliderMax: "400",
              sliderStepsize: "100",
            },
          },
          sliderDefault: "200",
          sliderUnit: "",
          correctMin: 400,
          correctMax: 400,
          correctAnswerHeader: "Genau!",
          correctAnswerText:
            "2016 wurden 427 tote Schweinswale gefunden. 2000 waren es noch 67.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Leider nein ...",
          wrongAnswerText:
            "2016 wurden 427 tote Schweinswale gefunden. 2000 waren es noch 67.",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.Porpoise,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.estimationQuestion({
              parent: quizBody,
              id: "quiz01_question03",
              pageTitle: PageTitle.QUIZ_01,
              functionDetail: FunctionDetail.QUIZ_01_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_01_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_01_NEXT_QUESTION,
              headerText: "WAS SCHÄTZT DU?",
              question:
                "Wie viele Monate glaubst du, säugen Schweinswalmütter ihr Kalb?",
              sliderValues: {
                fixed: false,
                values: {
                  sliderMin: "4",
                  sliderMax: "12",
                  sliderStepsize: "2",
                },
              },
              sliderDefault: "8",
              sliderUnit: "Monate",
              correctMin: 8,
              correctMax: 12,
              correctAnswerHeader: "Richtig!",
              correctAnswerText:
                "Schweinswalmütter säugen ihr Kalb 8–12 Monate.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Leider nein ...",
              wrongAnswerText: "Schweinswalmütter säugen ihr Kalb 8–12 Monate.",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.Porpoise,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz1();
              },
            });
          },
        });
      },
    });
  }

  public startQuiz2(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 02 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir die Echte Karettschildkröte in 3D an oder betrachte sie per Augmented Reality in deiner Umgebung!";

    this.trueFalseQuestion({
      parent: quizBody,
      id: "quiz02_question01",
      pageTitle: PageTitle.QUIZ_02,
      functionDetail: FunctionDetail.QUIZ_02_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_02_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_02_NEXT_QUESTION,
      headerText: "RICHTIG ODER FALSCH?",
      question:
        'Die Echte Karettschildkröte hat eine "magnetische Landkarte" im Kopf. Mit deren Hilfe weiß sie immer genau, wo sie sich gerade befindet.',
      trueOrFalse: true,
      correctAnswerHeader: "So ist es!",
      correctAnswerText: "Eine Echte Karettschildkröte verirrt sich niemals.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Nein, die Aussage ist richtig.",
      wrongAnswerText: "Sie hat eine magnetische Landkarte im Kopf!",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.SeaTurtle,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.trueFalseQuestion({
          parent: quizBody,
          id: "quiz02_question02",
          pageTitle: PageTitle.QUIZ_02,
          functionDetail: FunctionDetail.QUIZ_02_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_02_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_02_NEXT_QUESTION,
          headerText: "RICHTIG ODER FALSCH?",
          question:
            "Eine Echte Karettschildkröte kann in einem Jahr 14.500 Kilometer schwimmen. Das ist weiter als die Strecke von Berlin nach New York und zurück.",
          trueOrFalse: true,
          correctAnswerHeader: "Genau!",
          correctAnswerText:
            "Die Echte Karettschildkröte ist eine ausdauernde Schwimmerin.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Leider nein.",
          wrongAnswerText:
            "Die Echte Karettschildkröte ist eine ausdauernde Schwimmerin.",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.SeaTurtle,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.trueFalseQuestion({
              parent: quizBody,
              id: "quiz02_question03",
              pageTitle: PageTitle.QUIZ_02,
              functionDetail: FunctionDetail.QUIZ_02_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_02_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_02_NEXT_QUESTION,
              headerText: "RICHTIG ODER FALSCH?",
              question:
                "Die Echte Karettschildkröte kehrt zur Eiablage immer an den Strand zurück, an dem sie geboren wurde.",
              trueOrFalse: true,
              correctAnswerHeader: "Korrekt!",
              correctAnswerText:
                "Sie legen alle 2–3 Jahre bis zu 177 Eier an dem Strand ab, an dem sie selbst geschlüpft sind.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Nein, die Aussage ist wahr!",
              wrongAnswerText:
                "Sie legen alle 2–3 Jahre bis zu 177 Eier an dem Strand ab, an dem sie selbst geschlüpft sind. ",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.SeaTurtle,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz2();
              },
            });
          },
        });
      },
    });
  }

  public startQuiz3(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 03 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir den Schwarm in 3D an oder betrachte ihn per Augmented Reality in deiner Umgebung!";

    this.estimationQuestion({
      parent: quizBody,
      id: "quiz03_question01",
      pageTitle: PageTitle.QUIZ_03,
      functionDetail: FunctionDetail.QUIZ_03_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_03_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_03_NEXT_QUESTION,
      headerText: "WAS SCHÄTZT DU?",
      question: "Wie viele Delfine und Wale enden jedes Jahr als Beifang?",
      sliderValues: {
        fixed: true,
        values: {
          sliderValues: ["50.000", "150.000", "300.000"],
        },
      },
      sliderDefault: "1",
      sliderUnit: "",
      correctMin: 2,
      correctMax: 2,
      correctAnswerHeader: "Genau!",
      correctAnswerText:
        "300.000 Wale und Delfine. Beifang wird meist nicht verwertet, sondern tot ins Meer geworfen.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Nicht ganz ...",
      wrongAnswerText: "300.000 Wale und Delfine wäre richtig gewesen.",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.Medaka,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.estimationQuestion({
          parent: quizBody,
          id: "quiz03_question02",
          pageTitle: PageTitle.QUIZ_03,
          functionDetail: FunctionDetail.QUIZ_03_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_03_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_03_NEXT_QUESTION,
          headerText: "WAS SCHÄTZT DU?",
          question:
            "Wie viele Tiere enden jährlich als Beifang in Fischernetzen?",
          sliderValues: {
            fixed: true,
            values: {
              sliderValues: ["1000", "1 Million", "30 Millionen"],
            },
          },
          sliderDefault: "1",
          sliderUnit: "",
          correctMin: 2,
          correctMax: 2,
          correctAnswerHeader: "So ist es!",
          correctAnswerText:
            "30 Millionen Tiere. Beifang wird meist nicht verwertet, sondern tot oder verletzt ins Meer zurückgeworfen.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Nicht ganz ...",
          wrongAnswerText: "30 Millionen Tiere wäre richtig gewesen.",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.Medaka,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.estimationQuestion({
              parent: quizBody,
              id: "quiz03_question03",
              pageTitle: PageTitle.QUIZ_03,
              functionDetail: FunctionDetail.QUIZ_03_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_03_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_03_NEXT_QUESTION,
              headerText: "WAS SCHÄTZT DU?",
              question: "Wie viel Prozent in der Shrimp-Fischerei ist Beifang?",
              sliderValues: {
                fixed: true,
                values: {
                  sliderValues: ["1%", "20%", "80%"],
                },
              },
              sliderDefault: "1",
              sliderUnit: "",
              correctMin: 2,
              correctMax: 2,
              correctAnswerHeader: "Genau!",
              correctAnswerText:
                "80% der gefangenen Tiere beim Shrimpfischen ist Beifang. Dieser wird meist tot ins Meer zurückgeworfen.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Nicht ganz ...",
              wrongAnswerText:
                "80% der gefangenen Tiere beim Shrimpfischen ist Beifang. Dieser wird meist tot ins Meer zurückgeworfen.",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.Medaka,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz3();
              },
            });
          },
        });
      },
    });
  }

  public startQuiz4(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 04 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir die Qualle in 3D an oder betrachte sie per Augmented Reality in deiner Umgebung!";

    this.trueFalseQuestion({
      parent: quizBody,
      id: "quiz04_question01",
      pageTitle: PageTitle.QUIZ_04,
      functionDetail: FunctionDetail.QUIZ_04_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_04_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_04_NEXT_QUESTION,
      headerText: "RICHTIG ODER FALSCH?",
      question: "Die Qualle Nanomia cara hat 80 Mägen.",
      trueOrFalse: true,
      correctAnswerHeader: "Korrekt!",
      correctAnswerText:
        "So absonderlich es sein mag, aber die Antwort ist wahr.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Nicht ganz ...",
      wrongAnswerText: "Unglaublich aber wahr - die Qualle hat 80 Mägen.",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.Jellyfish,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.trueFalseQuestion({
          parent: quizBody,
          id: "quiz04_question02",
          pageTitle: PageTitle.QUIZ_04,
          functionDetail: FunctionDetail.QUIZ_04_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_04_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_04_NEXT_QUESTION,
          headerText: "RICHTIG ODER FALSCH?",
          question: "Manche Quallen fressen sich gegenseitig.",
          trueOrFalse: true,
          correctAnswerHeader: "Richtig.",
          correctAnswerText:
            "Manche Quallen fressen tatsächlich andere Quallen.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Leider nein.",
          wrongAnswerText: "Manche Quallen fressen tatsächlich andere Quallen.",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.Jellyfish,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.trueFalseQuestion({
              parent: quizBody,
              id: "quiz04_question03",
              pageTitle: PageTitle.QUIZ_04,
              functionDetail: FunctionDetail.QUIZ_04_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_04_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_04_NEXT_QUESTION,
              headerText: "RICHTIG ODER FALSCH?",
              question: "Quallen bestehen zu 50% aus Wasser.",
              trueOrFalse: false,
              correctAnswerHeader: "Stimmt!",
              correctAnswerText:
                "Quallen bestehen nicht zu 50%, sondern zu 95% aus Wasser.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Leider nein.",
              wrongAnswerText:
                "Quallen bestehen fast komplett aus Wasser. Es sind 95%.",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.Jellyfish,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz4();
              },
            });
          },
        });
      },
    });
  }

  public startQuiz5(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 05 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir den Anglerfisch in 3D an oder betrachte ihn per Augmented Reality in deiner Umgebung!";

    this.multipleChoiceQuestion({
      parent: quizBody,
      id: "quiz05_question01",
      pageTitle: PageTitle.QUIZ_05,
      functionDetail: FunctionDetail.QUIZ_05_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_05_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_05_NEXT_QUESTION,
      headerText: "WAS SCHÄTZT DU?",
      question:
        "Tiefseefische wirken auf uns oft fremd. Wie groß schätzt du, sind die meisten?",
      choices: ["20 cm", "1 m", "3 m"],
      correctChoices: "0",
      correctAnswerHeader: "Stimmt!",
      correctAnswerText:
        "Viele Tiefseebewohner sind nicht größer als eine Dose.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Leider nein ...",
      wrongAnswerText: "Viele Tiefseebewohner sind nicht größer als eine Dose.",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.Anglerfish,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.multipleChoiceQuestion({
          parent: quizBody,
          id: "quiz05_question02",
          pageTitle: PageTitle.QUIZ_05,
          functionDetail: FunctionDetail.QUIZ_05_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_05_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_05_NEXT_QUESTION,
          headerText: "WAS SCHÄTZT DU?",
          question:
            "Wie groß kann die Beute sein, die dieser Tiefseefisch verschlingen kann?",
          choices: [
            "So groß wie er selbst",
            "Größer als er selbst",
            "Doppelt so groß wie er selbst",
          ],
          correctChoices: "2",
          correctAnswerHeader: "Stimmt!",
          correctAnswerText:
            "Viele Tiefseefische haben einen sehr dehnbaren Magen.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Leider nein ...",
          wrongAnswerText:
            "Viele Tiefseefische können Beute schlucken, die doppelt so groß ist wie sie selbst!",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.Anglerfish,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.multipleChoiceQuestion({
              parent: quizBody,
              id: "quiz05_question03",
              pageTitle: PageTitle.QUIZ_05,
              functionDetail: FunctionDetail.QUIZ_05_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_05_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_05_NEXT_QUESTION,
              headerText: "WAS SCHÄTZT DU?",
              question:
                "Tiefsee-Männchen sind oft kleiner als die Weibchen. Um wie viel % denkst du?",
              choices: ["Bis zu 80%", "Bis zu 20%", "Bis zu 10%"],
              correctChoices: "0",
              correctAnswerHeader: "So ist es!",
              correctAnswerText:
                "Die Männchen sind bis zu 80% kleiner als die Weibchen. Finden sie sich, verschmelzen sie zu einem Organismus.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Nicht ganz ...",
              wrongAnswerText:
                "Die Männchen sind bis zu 80% kleiner als die Weibchen. Finden sie sich, verschmelzen sie zu einem Organismus.",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.Anglerfish,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz5();
              },
            });
          },
        });
      },
    });
  }

  public startQuiz6(): void {
    this.openQuiz();

    document.getElementById("quizHeader").innerHTML =
      "QUIZ 06 <hr id='quizHeaderLine' class='quiz-header-line'>";
    let quizBody = document.getElementById("quizBody");
    let correctAnswerNote =
      "Schau dir den Gespensterfisch in 3D an oder betrachte ihn per Augmented Reality in deiner Umgebung!";

    this.multipleChoiceQuestion({
      parent: quizBody,
      id: "quiz06_question01",
      pageTitle: PageTitle.QUIZ_06,
      functionDetail: FunctionDetail.QUIZ_06_QUESTION_01,
      correctAnswerFunctionDetail: FunctionDetail.QUIZ_06_SEE_ANIMAL,
      wrongAnswerFunctionDetail: FunctionDetail.QUIZ_06_NEXT_QUESTION,
      headerText: "Wähle eine oder mehrere Antworten aus:",
      question: "Wie kann jeder von uns dem Meer helfen?",
      choices: [
        "Keine bedrohten Arten wie Thunfische, Kalmare oder Aale essen.",
        "Müll an Flüssen und Stränden sammeln.",
        "Müll trennen und Plastik vermeiden",
      ],
      correctChoices: ["0", "1", "2"],
      correctAnswerHeader: "Genau!",
      correctAnswerText:
        "Die drei Punkte mögen bescheiden wirken, aber sie helfen unserem Meer und seinen Bewohnern schon enorm.",
      correctAnswerNote: correctAnswerNote,
      wrongAnswerHeader: "Nicht ganz ...",
      wrongAnswerText: "Alle drei Punkte sind korrekt.",
      wrongAnswerButtonText: "NÄCHSTE FRAGE",
      model: Model.BarrelEye,
      wrongAnswerButtonCallback: () => {
        document.getElementById("wrongAnswerPanel").remove();
        this.multipleChoiceQuestion({
          parent: quizBody,
          id: "quiz06_question02",
          pageTitle: PageTitle.QUIZ_06,
          functionDetail: FunctionDetail.QUIZ_06_QUESTION_02,
          correctAnswerFunctionDetail: FunctionDetail.QUIZ_06_SEE_ANIMAL,
          wrongAnswerFunctionDetail: FunctionDetail.QUIZ_06_NEXT_QUESTION,
          headerText: "Wähle eine oder mehrere Antworten aus:",
          question: "Was kannst du selbst gegen Geisternetze tun?",
          choices: [
            "Auf ein Bio- und Umweltsiegel beim Fischkauf achten.",
            "Fisch nur in Restaurants essen.",
            "Statt Fisch nur noch Muscheln und Shrimps essen.",
          ],
          correctChoices: ["0"],
          correctAnswerHeader: "Richtig!",
          correctAnswerText:
            "Das Siegel zeigt, welcher Fisch nachhaltig gefangen wurde. Illegale Fischer entsorgen ihre Netze oft im Meer.",
          correctAnswerNote: correctAnswerNote,
          wrongAnswerHeader: "Nicht ganz ...",
          wrongAnswerText: "Nur der erste Punkt war richtig.",
          wrongAnswerButtonText: "NÄCHSTE FRAGE",
          model: Model.BarrelEye,
          wrongAnswerButtonCallback: () => {
            document.getElementById("wrongAnswerPanel").remove();
            this.multipleChoiceQuestion({
              parent: quizBody,
              id: "quiz06_question03",
              pageTitle: PageTitle.QUIZ_06,
              functionDetail: FunctionDetail.QUIZ_06_QUESTION_03,
              correctAnswerFunctionDetail: FunctionDetail.QUIZ_06_SEE_ANIMAL,
              wrongAnswerFunctionDetail: FunctionDetail.QUIZ_06_NEXT_QUESTION,
              headerText: "Wähle eine oder mehrere Antworten aus:",
              question: "Wie kann jeder von uns Plastikmüll reduzieren?",
              choices: [
                "Langlebige Produkte kaufen.",
                '„To go" macht Müll. Lieber Speisen vor Ort essen.',
                "Plastikfreie Verpackungen nutzen oder unverpackt einkaufen.",
              ],
              correctChoices: ["0", "1", "2"],
              correctAnswerHeader: "Genau!",
              correctAnswerText:
                "Mit allen drei Punkten vermeidest du unnötigen Müll.",
              correctAnswerNote: correctAnswerNote,
              wrongAnswerHeader: "Leider nein ...",
              wrongAnswerText: "Alle drei Punkte wären richtig gewesen.",
              wrongAnswerButtonText: "QUIZ NEU STARTEN",
              model: Model.BarrelEye,
              wrongAnswerButtonCallback: () => {
                document.getElementById("wrongAnswerPanel").remove();
                this.startQuiz6();
              },
            });
          },
        });
      },
    });
  }

  public closeQuiz(): void {
    document.getElementById("quizContainer").className = "element-hidden";

    if (document.getElementById("quizBody").childElementCount != 0) {
      let parent = document.getElementById("quizBody");
      let childElements = Object.values(parent.childNodes) as HTMLElement[];
      for (let childEl of childElements) {
        childEl.remove();
      }
    }
  }

  private openQuiz(): void {
    document.getElementById("quizContainer").className = "quiz-container";
    document.getElementById("quizBody").className = "quiz-body";
  }

  public applyDesktopOrMobileLayout(): void {
    GuiManager.getInstance().useMobileLayout()
      ? document.getElementById("quizMain").classList.add("quiz-main-mobile")
      : document
          .getElementById("quizMain")
          .classList.remove("quiz-main-mobile");

    GuiManager.getInstance().isTablet() &&
    GuiManager.getInstance().orientationIsPortrait()
      ? document.getElementById("quizMain").classList.add("quiz-main-tablet")
      : document
          .getElementById("quizMain")
          .classList.remove("quiz-main-tablet");
  }

  private estimationQuestion(params: EstimationQuestionParams): void {
    TrackingManager.getInstance().selfPromotionDisplay(
      PageTitle.CANVAS,
      params.functionDetail,
      FunctionType.QUIZ,
      params.functionDetail
    );

    let div = document.createElement("div");
    div.id = params.id;
    div.className = "quiz-question";
    div.innerHTML =
      `
            <div id='` +
      params.id +
      `Header' class='quiz-question-header font-copy'>
                ` +
      params.headerText +
      `
            </div>
            <div id='` +
      params.id +
      `Question' class='quiz-question-question font-copy-large'>
                ` +
      params.question +
      `
            </div>
            <div id='` +
      params.id +
      `SliderValue' class='quiz-question-slider-value font-copy-large'>
            </div>
            <div id='` +
      params.id +
      `SliderContainer' class='quiz-question-slider-container'>
                <input 
                    id='` +
      params.id +
      `Slider'
                    type='range'
                    
                    class='quiz-question-slider'
                >
                <div 
                    id='` +
      params.id +
      `SliderTics'
                    class='quiz-question-slider-tics'
                >
                </div>
                <div class='filled-bar-container'>
                    <div id='filledBar' class='filled-bar'>
                    </div>
                    <div id='sliderThumbContainer' class='slider-thumb-container'>
                        <img id='sliderThumb' class='slider-thumb' src='./assets/gui/svg/Regler.svg'>
                    </div>
                </div>
            </div> 
            <div id=` +
      params.id +
      `SubmitButtonContainer class='quiz-question-submit-button-container'>
                <button id='` +
      params.id +
      `SubmitButton' class='primary-button'>
                    BESTÄTIGEN
                </button>
            </div>
        `;

    params.parent.appendChild(div);

    let slider = document.getElementById(
      params.id + "Slider"
    ) as HTMLInputElement;
    let numTics;
    let tics = "";
    if (!params.sliderValues.fixed) {
      let sliderValues = params.sliderValues.values as LinearSliderValues;
      slider.min = sliderValues.sliderMin;
      slider.max = sliderValues.sliderMax;
      slider.step = "any"; //sliderValues.sliderStepsize;
      numTics =
        (parseFloat(slider.max) - parseFloat(slider.min)) /
          parseFloat(sliderValues.sliderStepsize) +
        1;
      for (let i = 0; i < numTics; i++) {
        tics +=
          `
                    <div class='quiz-question-slider-tic-and-dot'>
                        <div class='quiz-question-slider-tic'>
                            ` +
          (i * parseFloat(sliderValues.sliderStepsize) +
            parseFloat(slider.min)) +
          `
                        </div>
                        <div class='quiz-question-slider-dot'>
                        </div>
                    </div>`;
      }
    } else {
      let sliderValues = params.sliderValues.values as FixedSliderValues;
      slider.min = "0";
      slider.max = (sliderValues.sliderValues.length - 1).toString();
      slider.step = "any"; //"1";
      numTics = sliderValues.sliderValues.length;
      for (let i = 0; i < numTics; i++) {
        tics +=
          `
                    <div class='quiz-question-slider-tic-and-dot'>
                        <div class='quiz-question-slider-tic'>
                            ` +
          sliderValues.sliderValues[i] +
          `
                        </div>
                        <div class='quiz-question-slider-dot'>
                        </div>
                    </div>`;
      }
    }

    document.getElementById(params.id + "SliderTics").innerHTML = tics;

    slider.value = params.sliderDefault;
    this.updateSlider(params);

    slider.addEventListener("input", () => {
      this.updateSlider(params);
    });

    slider.addEventListener("touchend", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
    });

    slider.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
    });

    document
      .getElementById(params.id + "SubmitButton")
      .addEventListener("click", () => {
        TrackingManager.getInstance().selfPromotionClick(
          PageTitle.CANVAS,
          params.functionDetail,
          FunctionType.QUIZ,
          params.functionDetail
        );
        let slider = document.getElementById(
          params.id + "Slider"
        ) as HTMLInputElement;

        if (
          Math.round(Number(slider.value)) < params.correctMin ||
          Math.round(Number(slider.value)) > params.correctMax
        ) {
          AudioManager.getInstance().playSound({
            fileName: "Schwarm_SFX_FALSCH.m4a",
            loop: false,
            nonWPSound: true,
          });
          div.remove();
          this.wrongAnswer(
            params.parent,
            params.wrongAnswerHeader,
            params.wrongAnswerText,
            params.wrongAnswerButtonText,
            params.wrongAnswerButtonCallback,
            params.pageTitle,
            params.wrongAnswerFunctionDetail
          );
          return;
        }

        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_RICHTIG.m4a",
          loop: false,
          nonWPSound: true,
        });
        div.remove();
        this.correctAnswer(
          params.parent,
          params.model,
          params.correctAnswerHeader,
          params.correctAnswerText,
          params.correctAnswerNote,
          params.pageTitle,
          params.correctAnswerFunctionDetail
        );
      });

    this.applyDesktopOrMobileLayout();
  }

  private updateSlider(params: EstimationQuestionParams): void {
    let slider = document.getElementById(
      params.id + "Slider"
    ) as HTMLInputElement;

    if (params.sliderValues.fixed) {
      let values = params.sliderValues.values as FixedSliderValues;
      document.getElementById(params.id + "SliderValue").textContent =
        values.sliderValues[parseInt(slider.value)] + " " + params.sliderUnit;
    } else
      document.getElementById(params.id + "SliderValue").textContent =
        Math.round(Number(slider.value)) + " " + params.sliderUnit;

    slider.style.setProperty("--value", slider.value);

    let percentage =
      ((parseFloat(slider.value) - parseFloat(slider.min)) /
        (parseFloat(slider.max) - parseFloat(slider.min))) *
      100;
    document.getElementById("filledBar").style.width =
      "calc(" + percentage.toFixed() + "%" + " + 17px)";
    document.getElementById("sliderThumbContainer").style.left =
      percentage.toFixed() + "%";

    this.applyDesktopOrMobileLayout();
  }

  private trueFalseQuestion(params: TrueFalseQuestionParams): void {
    TrackingManager.getInstance().selfPromotionDisplay(
      PageTitle.CANVAS,
      params.functionDetail,
      FunctionType.QUIZ,
      params.functionDetail
    );

    let div = document.createElement("div");
    div.id = params.id;
    div.className = "quiz-question";
    div.innerHTML =
      `
            <div id='` +
      params.id +
      `Header' class='quiz-question-header font-copy'>
                ` +
      params.headerText +
      `
            </div>
            <div id='` +
      params.id +
      `Question' class='quiz-question-question font-copy-large'>
                ` +
      params.question +
      `
            </div>
            <div id='` +
      params.id +
      `TrueOrFalseButtonContainer' class='quiz-question-true-or-false-button-container'>
                <button id='` +
      params.id +
      `TrueButton' class='quiz-question-true-or-false-button font-button'>
                    Richtig
                </button>
                <button id='` +
      params.id +
      `FalseButton' class='quiz-question-true-or-false-button font-button'>
                    Falsch
                </button>
            </div>
        `;

    params.parent.appendChild(div);

    document
      .getElementById(params.id + "TrueButton")
      .addEventListener("click", () => {
        TrackingManager.getInstance().selfPromotionClick(
          PageTitle.CANVAS,
          params.functionDetail,
          FunctionType.QUIZ,
          params.functionDetail
        );
        if (params.trueOrFalse) {
          AudioManager.getInstance().playSound({
            fileName: "Schwarm_SFX_RICHTIG.m4a",
            loop: false,
            nonWPSound: true,
          });
          div.remove();
          this.correctAnswer(
            params.parent,
            params.model,
            params.correctAnswerHeader,
            params.correctAnswerText,
            params.correctAnswerNote,
            params.pageTitle,
            params.correctAnswerFunctionDetail
          );
          return;
        }

        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_FALSCH.m4a",
          loop: false,
          nonWPSound: true,
        });
        div.remove();
        this.wrongAnswer(
          params.parent,
          params.wrongAnswerHeader,
          params.wrongAnswerText,
          params.wrongAnswerButtonText,
          params.wrongAnswerButtonCallback,
          params.pageTitle,
          params.wrongAnswerFunctionDetail
        );
      });

    document
      .getElementById(params.id + "FalseButton")
      .addEventListener("click", () => {
        TrackingManager.getInstance().selfPromotionClick(
          PageTitle.CANVAS,
          params.functionDetail,
          FunctionType.QUIZ,
          params.functionDetail
        );
        if (params.trueOrFalse) {
          AudioManager.getInstance().playSound({
            fileName: "Schwarm_SFX_FALSCH.m4a",
            loop: false,
            nonWPSound: true,
          });
          div.remove();
          this.wrongAnswer(
            params.parent,
            params.wrongAnswerHeader,
            params.wrongAnswerText,
            params.wrongAnswerButtonText,
            params.wrongAnswerButtonCallback,
            params.pageTitle,
            params.wrongAnswerFunctionDetail
          );
          return;
        }

        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_RICHTIG.m4a",
          loop: false,
          nonWPSound: true,
        });
        div.remove();
        this.correctAnswer(
          params.parent,
          params.model,
          params.correctAnswerHeader,
          params.correctAnswerText,
          params.correctAnswerNote,
          params.pageTitle,
          params.correctAnswerFunctionDetail
        );
      });

    this.applyDesktopOrMobileLayout();
  }

  private multipleChoiceQuestion(params: MultipleChoiceQuestionParams): void {
    TrackingManager.getInstance().selfPromotionDisplay(
      PageTitle.CANVAS,
      params.functionDetail,
      FunctionType.QUIZ,
      params.functionDetail
    );

    let div = document.createElement("div");
    div.id = params.id;
    div.className = "quiz-question";

    let checkboxes = "";
    params.choices.forEach((choice, index) => {
      if (!Array.isArray(params.correctChoices)) {
        checkboxes =
          checkboxes +
          `
                <label class='quiz-question-checkbox-container font-copy'>
                    <input type='radio' name='` +
          params.id +
          `' value='` +
          index +
          `' id='` +
          params.id +
          `Checkbox` +
          index +
          `' class='quiz-question-checkbox'>
                    ` +
          choice +
          `
                </label>
            `;
      } else {
        checkboxes =
          checkboxes +
          `
                <label class='quiz-question-checkbox-container font-copy'>
                    <input type='checkbox' value='` +
          index +
          `' id='` +
          params.id +
          `Checkbox` +
          index +
          `' class='quiz-question-checkbox'>
                    ` +
          choice +
          `
                </label>
            `;
      }
    });

    div.innerHTML =
      `
            <div id='` +
      params.id +
      `Header' class='quiz-question-header font-copy'>
                ` +
      params.headerText +
      `
            </div>
            <div id='` +
      params.id +
      `Question' class='quiz-question-question font-copy-large'>
                ` +
      params.question +
      `
            </div>
            <div id='` +
      params.id +
      `CheckboxContainer' class='quiz-question-checkbox-group'>
                ` +
      checkboxes +
      `
            </div>
            <div id=` +
      params.id +
      `SubmitButtonContainer class='quiz-question-submit-button-container'>
                <button id='` +
      params.id +
      `SubmitButton' class='primary-button'>
                    BESTÄTIGEN
                </button>
            </div>
        `;

    params.parent.appendChild(div);

    for (let i = 0; i < params.choices.length; i++) {
      let checkbox = document.getElementById(
        params.id + "Checkbox" + i
      ) as HTMLInputElement;

      checkbox.onchange = () =>
        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_Menu_Click_1.m4a",
          loop: false,
          nonWPSound: true,
        });
    }

    document
      .getElementById(params.id + "SubmitButton")
      .addEventListener("click", () => {
        TrackingManager.getInstance().selfPromotionClick(
          PageTitle.CANVAS,
          params.functionDetail,
          FunctionType.QUIZ,
          params.functionDetail
        );
        if (Array.isArray(params.correctChoices)) {
          let checkedButtonIndices: string[] = [];
          for (let i = 0; i < params.choices.length; i++) {
            let checkbox = document.getElementById(
              params.id + "Checkbox" + i
            ) as HTMLInputElement;
            if (checkbox.checked) checkedButtonIndices.push(checkbox.value);
          }

          if (
            params.correctChoices.sort().toString() ==
            checkedButtonIndices.sort().toString()
          ) {
            AudioManager.getInstance().playSound({
              fileName: "Schwarm_SFX_RICHTIG.m4a",
              loop: false,
              nonWPSound: true,
            });
            div.remove();
            this.correctAnswer(
              params.parent,
              params.model,
              params.correctAnswerHeader,
              params.correctAnswerText,
              params.correctAnswerNote,
              params.pageTitle,
              params.correctAnswerFunctionDetail
            );
            return;
          }
        } else {
          let checkbox = document.getElementById(
            params.id + "Checkbox" + params.correctChoices
          ) as HTMLInputElement;
          if (checkbox.checked) {
            AudioManager.getInstance().playSound({
              fileName: "Schwarm_SFX_RICHTIG.m4a",
              loop: false,
              nonWPSound: true,
            });
            div.remove();
            this.correctAnswer(
              params.parent,
              params.model,
              params.correctAnswerHeader,
              params.correctAnswerText,
              params.correctAnswerNote,
              params.pageTitle,
              params.correctAnswerFunctionDetail
            );
            return;
          }
        }

        div.remove();
        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_FALSCH.m4a",
          loop: false,
          nonWPSound: true,
        });
        this.wrongAnswer(
          params.parent,
          params.wrongAnswerHeader,
          params.wrongAnswerText,
          params.wrongAnswerButtonText,
          params.wrongAnswerButtonCallback,
          params.pageTitle,
          params.wrongAnswerFunctionDetail
        );
      });

    this.applyDesktopOrMobileLayout();
  }

  private correctAnswer(
    parent: HTMLElement,
    model: Model,
    header: string,
    correctAnswer: string,
    note: string,
    pageTitle: PageTitle, // for tracking
    functionDetail: FunctionDetail // for tracking
  ) {
    Inventory.getInstance().unlockModel(model);

    let modelInfo = Inventory.getInstance().getModel(model);

    parent.innerHTML =
      `
            <div id='correctAnswerPanel' class='correct-answer-panel'>
            <img id='correctAnswerImage' class='correct-answer-image-` +
      (GuiManager.getInstance().useMobileLayout() ? "mobile" : "desktop") +
      `' src='` +
      modelInfo.previewUrlDesktop +
      `'>
                <div class='correct-answer-header font-copy-large'>` +
      header +
      `</div>
                <div class='correct-answer-answer font-quiz'>` +
      correctAnswer +
      `</div>
                <div class='correct-answer-note font-quiz'>` +
      note +
      `</div>
                <div class='secondary-button-container quiz-secondary-button-container correct-button-container'>
                    <button id='correctAnswerButton' class='secondary-button'> TIER ERLEBEN </button>
                    <!-- <div class='secondary-button-background'></div> -->
                </div>
            </div> 
            
        `;

    document
      .getElementById("correctAnswerButton")
      .addEventListener("click", () => {
        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_Menu_Click_2.m4a",
          loop: false,
          nonWPSound: true,
        });
        // Opening the model that was just unlocked
        TrackingManager.getInstance().clickNavigation(
          PageTitle.CANVAS,
          "Tier erleben",
          Inventory.animalNames[model]
        );

        AnimationManager.getInstance().stopCurrentAnimations();
        new ModelViewer().openModelViewer(model);
        this.closeCorrectAnswerPanel();
        this.closeQuiz();
        Inventory.getInstance().open(model);
      });

    this.applyDesktopOrMobileLayout();
  }

  private closeCorrectAnswerPanel(): void {
    document.getElementById("correctAnswerImage").remove();
    document.getElementById("correctAnswerPanel").remove();
  }

  private wrongAnswer(
    parent: HTMLElement,
    wrongAnswerHeader: string,
    correctAnswer: string,
    wrongAnswerButtonText: string,
    buttonCallback: Function,
    pageTitle: PageTitle,
    functionDetail: FunctionDetail
  ) {
    parent.innerHTML =
      `
            <div id='wrongAnswerPanel' class='wrong-answer-panel'>
                <div class='wrong-answer-header font-copy-large'>` +
      wrongAnswerHeader +
      `</div>
                <div class='wrong-answer-answer font-quiz'>` +
      correctAnswer +
      `</div>
                <div class='wrong-answer-button-container'>
                    <button id='wrongAnswerButton' class='primary-button'>` +
      wrongAnswerButtonText +
      `</button>
                </div>
            </div>
        `;

    document
      .getElementById("wrongAnswerButton")
      .addEventListener("click", () => {
        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_Menu_Click_2.m4a",
          loop: false,
          nonWPSound: true,
        });
        // TrackingManager.getInstance().selfPromotionClick(
        //   PageTitle.CANVAS,
        //   functionDetail,
        //   FunctionType.QUIZ,
        //   functionDetail
        // );
        buttonCallback();
      });

    this.applyDesktopOrMobileLayout();
  }
}

type EstimationQuestionParams = {
  parent: HTMLElement;
  id: string;
  pageTitle: PageTitle; // for tracking
  functionDetail: FunctionDetail; // for tracking
  correctAnswerFunctionDetail: FunctionDetail; // for tracking
  wrongAnswerFunctionDetail: FunctionDetail; // for tracking
  headerText: string;
  question: string;
  sliderValues: SliderValues;
  sliderDefault: string;
  sliderUnit: string;
  correctMin: number;
  correctMax: number;
  correctAnswerHeader: string;
  correctAnswerText: string;
  correctAnswerNote: string;
  wrongAnswerHeader: string;
  wrongAnswerText: string;
  wrongAnswerButtonText: string;
  model: Model;
  wrongAnswerButtonCallback: Function;
};

type SliderValues = {
  fixed: boolean;
  values: LinearSliderValues | FixedSliderValues;
};
type LinearSliderValues = {
  sliderMin: string;
  sliderMax: string;
  sliderStepsize: string;
};

type FixedSliderValues = {
  sliderValues: string[];
};

type TrueFalseQuestionParams = {
  parent: HTMLElement;
  id: string;
  pageTitle: PageTitle; // for tracking
  functionDetail: FunctionDetail; // for tracking
  correctAnswerFunctionDetail: FunctionDetail; // for tracking
  wrongAnswerFunctionDetail: FunctionDetail; // for tracking
  headerText: string;
  question: string;
  trueOrFalse: boolean;
  correctAnswerHeader: string;
  correctAnswerText: string;
  correctAnswerNote: string;
  wrongAnswerHeader: string;
  wrongAnswerText: string;
  wrongAnswerButtonText: string;
  model: Model;
  wrongAnswerButtonCallback: Function;
};

type MultipleChoiceQuestionParams = {
  parent: HTMLElement;
  id: string;
  pageTitle: PageTitle; // for tracking
  functionDetail: FunctionDetail; // for tracking
  correctAnswerFunctionDetail: FunctionDetail; // for tracking
  wrongAnswerFunctionDetail: FunctionDetail; // for tracking
  headerText: string;
  question: string;
  choices: string[];
  // single choice if string, multiple choice if array
  correctChoices: string | string[];
  correctAnswerHeader: string;
  correctAnswerText: string;
  correctAnswerNote: string;
  wrongAnswerHeader: string;
  wrongAnswerText: string;
  wrongAnswerButtonText: string;
  model: Model;
  wrongAnswerButtonCallback: Function;
};
