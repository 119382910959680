import AudioManager from "./audio-manager";
import GuiManager from "./gui-manager";
import Player from "./player";
import TrackingManager, { PageTitle } from "./tracking-manager";

export class MediaOverlay {
  private static instance: MediaOverlay;

  private _elements: MediaElement[] = [];
  private _currentIdx: number = 0;
  private _xDown: number;

  constructor() {
    if (MediaOverlay.instance) return null;

    let overlayContainer = document.createElement("div");
    overlayContainer.id = "mediaOverlayContainer";
    overlayContainer.className = "element-hidden";

    overlayContainer.innerHTML = `
            <div id='mediaOverlay'>
                <div id='mediaOverlayMediaContainer' class='media-overlay-media-container'>
                    <div id='mediaOverlayMedia'>
                        <div id='mediaOverlaySource' class='media-overlay-media-source font-copy-small'>
                        </div>
                    </div>
                    <div id='mediaOverlayCloseButtonContainer' class='close-button-container'>
                        <img 
                            src='./assets/gui/svg/close.png'
                            alt='close button'
                            id='mediaOverlayCloseButton'
                            class='close-button'
                        >
                    </div>
                    <div id='mediaOverlayNextButtonContainer' class='media-overlay-next-button-container'>
                        <img
                            src='./assets/gui/svg/small_arrow.svg'
                            style='transform:rotate(90deg);'
                            id='mediaOverlayNextButton'
                            class='media-overlay-next-button'
                        > 
                    </div>
                    <div id='mediaOverlayPrevButtonContainer' class='media-overlay-prev-button-container'>
                        <img
                            src='./assets/gui/svg/small_arrow.svg'
                            style='transform:rotate(-90deg);'
                            id='mediaOverlayPrevButton'
                            class='media-overlay-prev-button'
                        > 
                    </div>
                </div>
                <div id='mediaOverlayPagination' class='media-overlay-pagination'>
                </div>
                <div id='mediaOverlayTextContainer' class='media-overlay-text-container'>
                    <div id='mediaOverlayCaptionContainer'>
                        <div id='mediaOverlayCaption' class='media-overlay-caption font-copy-small'>
                        </div>
                    </div>
                </div>
            </div>
        `;

    document
      .getElementById("guiContainerOverlay")
      .appendChild(overlayContainer);

    let closeButton = document.getElementById(
      "mediaOverlayCloseButton"
    ) as HTMLButtonElement;
    closeButton.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_2.m4a",
        loop: false,
        nonWPSound: true,
      });
      this.close();
    });

    let prevButton = document.getElementById(
      "mediaOverlayPrevButton"
    ) as HTMLButtonElement;
    prevButton.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      this.previous();
    });

    let nexButton = document.getElementById(
      "mediaOverlayNextButton"
    ) as HTMLButtonElement;
    nexButton.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      this.next();
    });
  }

  public static getInstance(): MediaOverlay {
    if (!MediaOverlay.instance) MediaOverlay.instance = new MediaOverlay();

    return MediaOverlay.instance;
  }

  private _pageTitle: string;
  public open(
    elements: MediaElement[],
    infos: MediaInfo[],
    pageTitle: string
  ): void {
    this._pageTitle = pageTitle;
    GuiManager.getInstance().pauseGame(true);
    document.getElementById("mediaOverlayContainer").className =
      "media-overlay-container";

    if (GuiManager.getInstance().isTablet()) {
      document.getElementById("mediaOverlay").className =
        "media-overlay-tablet";
    } else if (GuiManager.getInstance().isMobile()) {
      document.getElementById("mediaOverlay").className =
        "media-overlay-mobile";
    } else {
      document.getElementById("mediaOverlay").className =
        "media-overlay-desktop";
    }

    document.getElementById("mediaOverlayNextButtonContainer").className =
      GuiManager.getInstance().useMobileLayout()
        ? "element-hidden"
        : "media-overlay-next-button-container";
    document.getElementById("mediaOverlayPrevButtonContainer").className =
      GuiManager.getInstance().useMobileLayout()
        ? "element-hidden"
        : "media-overlay-prev-button-container";

    GuiManager.getInstance().useMobileLayout()
      ? document
          .getElementById("mediaOverlayCloseButtonContainer")
          .classList.add("close-button-container-mobile")
      : document
          .getElementById("mediaOverlayCloseButtonContainer")
          .classList.remove("close-button-container-mobile");

    this._elements = elements;
    this._currentIdx = 0;
    if (this._elements.length > 1) {
      let pagination = document.getElementById("mediaOverlayPagination");
      pagination.classList.remove("element-hidden");
      let paginationInnerHtml = "";
      this._elements.forEach((_, idx) => {
        paginationInnerHtml =
          paginationInnerHtml +
          `
                    <div class='media-overlay-pagination-element-container'>
                        <input 
                            type='radio'
                            id='mediaOverlayPaginationElement` +
          idx +
          `'
                            class='media-overlay-pagination-element'
                            name='mediaOverlayPaginationElements'
                        >
                    </div>
                `;
        pagination.innerHTML = paginationInnerHtml;
      });
    } else {
      document.getElementById("mediaOverlayNextButtonContainer").className =
        "element-hidden";
      document.getElementById("mediaOverlayPrevButtonContainer").className =
        "element-hidden";
      document
        .getElementById("mediaOverlayPagination")
        .classList.add("element-hidden");
    }

    // Remove all children from overlay text container except the div that holds the caption. Otherwise new children will be appended with every hotspot the user clicks.
    let overlayTextContainer: HTMLElement = document.getElementById(
      "mediaOverlayTextContainer"
    );
    while (overlayTextContainer.children.length > 1) {
      overlayTextContainer.removeChild(overlayTextContainer.lastChild);
    }
    overlayTextContainer.scrollTop = 0;

    // There can be one or multiple headers with a corresponding description per overlay so we have to add these divs dynamically
    infos.forEach((mediaInfo) => {
      let overlayTextHeader = document.createElement("div");
      overlayTextHeader.textContent = mediaInfo.header;
      overlayTextHeader.className = "media-overlay-text-header font-h-two";
      overlayTextContainer.appendChild(overlayTextHeader);

      let overlayText = document.createElement("div");
      overlayText.className = "media-overlay-text font-copy";
      let text = "";
      mediaInfo.description.forEach((descriptionElement) => {
        text =
          text +
          `
                    <div class='media-overlay-text-section'>
                        ` +
          descriptionElement +
          `
                    </div>
                `;
      });
      overlayText.innerHTML = text;
      overlayTextContainer.appendChild(overlayText);
    });

    this.openElement();
  }

  public close(): void {
    TrackingManager.getInstance().clickNavigation(
      this._pageTitle,
      PageTitle.CANVAS,
      PageTitle.CANVAS
    );
    this._pageTitle = null;
    this.closeElement();
    this._elements = [];
    document.getElementById("mediaOverlayContainer").className =
      "element-hidden";
    GuiManager.getInstance().pauseGame(false);
  }

  private openElement(): void {
    let parent = document.getElementById("mediaOverlayMedia");

    if (this._elements[this._currentIdx].type == MediaType.Image) {
      parent.className = "media-overlay-media-image";
      let image = document.createElement("img");
      image.id = "mediaOverlayImage";
      image.className = "media-overlay-image";
      image.src = this._elements[this._currentIdx].src;

      parent.appendChild(image);

      this.addSwipeEventListeners(image);

      if (image.complete) {
        let rgb = this.getAverageBackgroundColor(image);
        image.style.backgroundColor =
          "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";
      }

      image.addEventListener("load", () => {
        let rgb = this.getAverageBackgroundColor(image);
        image.style.backgroundColor =
          "rgb(" + rgb.r + "," + rgb.g + "," + rgb.b + ")";
      });

      document.getElementById("mediaOverlayCaptionContainer").className =
        "media-overlay-caption-container";
      document.getElementById("mediaOverlayCaption").textContent =
        this._elements[this._currentIdx].caption;
    } else if (this._elements[this._currentIdx].type == MediaType.Video) {
      parent.className = "media-overlay-media-video";
      Player.openPlayer(this._elements[this._currentIdx].src, parent);
      // parent.onclose = () => {
      //   console.log("player parent closed");
      // };
      this.addSwipeEventListeners(document.getElementById("playerContainer"));

      document.getElementById("mediaOverlayCaptionContainer").className =
        "element-hidden";
    }

    document.getElementById("mediaOverlaySource").textContent =
      this._elements[this._currentIdx].source;

    if (this._elements.length > 1) {
      let radioButton = document.getElementById(
        "mediaOverlayPaginationElement" + this._currentIdx
      ) as HTMLInputElement;
      radioButton.checked = true;

      // Move overlay text down to make space for the pagination
      document
        .getElementById("mediaOverlayTextContainer")
        .classList.remove("media-overlay-text-container-no-pagination");
      document
        .getElementById("mediaOverlayCaptionContainer")
        .classList.remove("media-overlay-caption-container-no-pagination");
    } else {
      // Move overlay text up so the space where the pagination would have been isn't left empty
      document
        .getElementById("mediaOverlayTextContainer")
        .classList.add("media-overlay-text-container-no-pagination");
      document
        .getElementById("mediaOverlayCaptionContainer")
        .classList.add("media-overlay-caption-container-no-pagination");
    }
  }

  private addSwipeEventListeners(target: HTMLElement): void {
    target.addEventListener("touchstart", (evt) => {
      this._xDown = evt.touches[0].clientX;
    });

    target.addEventListener("touchmove", (evt) => {
      if (!this._xDown) return;

      this.swipe(
        (this._xDown - evt.touches[0].clientX) / window.outerWidth,
        target
      );
    });

    target.addEventListener("touchend", async () => {
      if (!this._xDown) return;

      this._xDown = null;
      let sleep = (ms) => new Promise((r) => setTimeout(r, ms));

      while (Math.abs(parseFloat(target.style.left)) > 5) {
        if (parseFloat(target.style.left) > 5)
          target.style.left =
            (parseFloat(target.style.left) - 5).toString() + "%";
        else
          target.style.left =
            (parseFloat(target.style.left) + 5).toString() + "%";

        await sleep(10);
      }

      target.style.left = "0";
    });
  }

  private closeElement(): void {
    if (this._elements[this._currentIdx].type == MediaType.Image)
      document.getElementById("mediaOverlayImage")?.remove();
    else if (this._elements[this._currentIdx].type == MediaType.Video)
      Player.closePlayer();

    document.getElementById("mediaOverlayCaption").textContent = "";
  }

  private next(): void {
    this.closeElement();
    if (this._currentIdx < this._elements.length - 1) this._currentIdx++;
    else this._currentIdx = 0;

    this.openElement();
  }

  private previous(): void {
    this.closeElement();
    if (this._currentIdx > 0) this._currentIdx--;
    else this._currentIdx = this._elements.length - 1;

    this.openElement();
  }

  private async swipe(relXDiff: number, target: HTMLElement) {
    if (this._currentIdx == 0 && relXDiff < -0.2) return;
    if (this._currentIdx == this._elements.length - 1 && relXDiff > 0.2) return;

    if (Math.abs(relXDiff) > 0.4) {
      this._xDown = null;

      let sleep = (ms) => new Promise((r) => setTimeout(r, ms));

      while (Math.abs(parseFloat(target.style.left)) < 100) {
        if (parseFloat(target.style.left) > 0)
          target.style.left =
            (parseFloat(target.style.left) + 10).toString() + "%";
        else
          target.style.left =
            (parseFloat(target.style.left) - 10).toString() + "%";

        await sleep(10);
      }

      if (relXDiff < -0.4) this.previous();
      else this.next();
      return;
    }

    target.style.left = (-100 * relXDiff).toString() + "%";
  }

  private getAverageBackgroundColor(imgEl: HTMLImageElement) {
    let stepSize = 5,
      defaultRGB = { r: 0, g: 0, b: 0 },
      canvas = document.createElement("canvas"),
      context = canvas.getContext && canvas.getContext("2d"),
      data,
      width,
      height,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;

    if (!context) {
      return defaultRGB;
    }

    height = canvas.height =
      imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width =
      imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
      data = context.getImageData(0, 0, width, height);
    } catch (e) {
      // security error, img on diff domain
      alert(e);
      return defaultRGB;
    }

    let i = -4;
    while ((i += stepSize * 4) < data.data.length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
    }

    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;
  }
}

export type MediaElement = {
  type: MediaType; // Video or Image
  source: string; // Source/Copyright  of the media
  src: string; // url for image, sophora ID for video
  caption?: string; // image caption, will be ignored for video
};

export enum MediaType {
  Video,
  Image,
}

export type MediaInfo = {
  header: string;
  description: string[];
};
