import { AbstractMesh, TransformNode } from "@babylonjs/core";
import { visibleInInspector } from "./decorators";
import Fishmovement from "./fishmovement";
import { AddWP } from "./GameManager/narration";

export default class NetMovement extends Fishmovement {
  @visibleInInspector("string", "Target Name")
  private _targetName: string;

  private _targetMesh: AbstractMesh;
  private _fullDistance: number;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  public start(path: string[]): void {
    super.start(path);
  }

  public waypointLogic(flyerContainer: TransformNode) {
    return super.waypointLogic(flyerContainer);
  }

  public moveFlyer(flyerContainer: TransformNode) {
    return super.moveFlyer(flyerContainer);
  }

  public stop(): void {
    return super.stop();
  }

  public runningAway(addWp: AddWP): void {
    return super.runningAway(addWp);
  }

  public onUpdate(): void {
    if (!this.initDone) return;

    if (!this._targetMesh) {
      this._targetMesh = this.getScene().getMeshByName(this._targetName);
      this._fullDistance = this.absolutePosition
        .subtract(this._targetMesh.absolutePosition)
        .length();
    }

    if (this.flyer.morphTargetManager) {
      this.flyer.morphTargetManager.getTarget(0).influence =
        1 -
        this.flyer.absolutePosition
          .subtract(this._targetMesh.absolutePosition)
          .length() /
          this._fullDistance;
    }
  }
}
