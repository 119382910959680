import { Node } from "@babylonjs/core/node";
import ScrollCamera from "../scroll";
import GuiManager from "./gui-manager";

export default class DepthMeter extends Node {
  private _scrollCamera: ScrollCamera;
  private _guiManager: GuiManager;

  private _lastPositionY: number;
  private _lastUpdateY: number;

  private _depthInfos: DepthInfo[] = [
    {
      positionY: 11.2, // Water level
      textCue: "Beginne mit deiner Terra X Tiefseetauchfahrt!",
      nearestWaypointId: "1",
      depth: 0,
      pressure: 0,
      temperature: 16,
    },
    {
      positionY: -150,
      textCue: "Die Dämmerschicht beginnt ab 150 Metern ...",
      nearestWaypointId: "9",
      depth: 150,
      pressure: 15,
    },
    {
      positionY: -195.735648193359,
      textCue: "Ab 200 Metern gibt es kein pflanzliches Leben mehr ...",
      nearestWaypointId: "12",
      depth: 200,
      pressure: 20,
      temperature: 13,
    },
    {
      positionY: -200.58551025390625,
      textCue:
        "Der Gespensterfisch rotiert seine Linsen in alle Richtungen und kann so auch über sich Nahrung erspähen.",
      nearestWaypointId: "13",
      depth: 400,
      pressure: 40,
      temperature: 8,
    },
    {
      positionY: -213.85,
      textCue:
        "Eines der ältesten und absonderlichsten Tiere der Erdgeschichte ist die Qualle.",
      nearestWaypointId: "14",
      depth: 600,
      pressure: 60,
      temperature: 6,
    },
    {
      positionY: -279.9540100097656,
      textCue: "Ab 1000 Metern herrscht tiefe Finsternis ...",
      nearestWaypointId: "15",
      depth: 1000,
      pressure: 100,
    },
    {
      positionY: -305.050048828125,
      textCue: "Wir nähern uns dem Meeresboden, einem kaum erforschten Gebiet.",
      nearestWaypointId: "17",
      depth: 1950,
      pressure: 195,
      temperature: 3,
    },
    {
      positionY: -327.01,
      textCue: "Große Teile des Meeresbodens wimmeln vor Leben.",
      nearestWaypointId: "18",
      depth: 2000,
      pressure: 200,
      temperature: 3,
    },
    {
      positionY: -350,
      textCue: "Und auch Plastikmüll sinkt bis hier unten hinab.",
      nearestWaypointId: "22",
      depth: 2002,
      pressure: 200.2,
      temperature: 3,
    }
  ];

  private _depthMultiplier: number[] = [];
  private _pressureMultiplier: number[] = [];
  private _temperatureMultiplier: number[] = [];

  /**
   * Override constructor.
   * @warn do not fill.
   */
  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  /**
   * Called on the node has been fully initialized and is ready.
   */
  public onInitialized(): void {
    // Fill in gaps where no temperature is specified for a segment
    for (let i = 0; i < this._depthInfos.length - 1; i++) {
      if (typeof this._depthInfos[i].temperature === "undefined") {
        // newTemp = prevTemp - (prevTemp - nextTemp) * prevYDiff / wholeYDiff
        this._depthInfos[i].temperature =
          this._depthInfos[i - 1].temperature -
          (Math.abs(
            this._depthInfos[i - 1].temperature -
              this._depthInfos[i + 1].temperature
          ) *
            Math.abs(
              this._depthInfos[i - 1].positionY - this._depthInfos[i].positionY
            )) /
            Math.abs(
              this._depthInfos[i - 1].positionY -
                this._depthInfos[i + 1].positionY
            );
      }
    }

    // Calculating how much the displayed depth, pressure and temperature should change if we go +/-1 in Y direction. This value is not the same across the scene so we have to determine it for each section.
    for (let i = 0; i < this._depthInfos.length - 1; i++) {
      // console.log(
      //   "Segment Y " +
      //     this._depthInfos[i].positionY +
      //     " to " +
      //     this._depthInfos[i + 1].positionY +
      //     ":"
      // );

      let yDiff: number = Math.abs(
        this._depthInfos[i].positionY - this._depthInfos[i + 1].positionY
      );
      let depthDiff: number = Math.abs(
        this._depthInfos[i].depth - this._depthInfos[i + 1].depth
      );
      let pressureDiff: number = Math.abs(
        this._depthInfos[i].pressure - this._depthInfos[i + 1].pressure
      );
      let temperatureDiff: number = Math.abs(
        this._depthInfos[i].temperature - this._depthInfos[i + 1].temperature
      );

      this._depthMultiplier.push(depthDiff / yDiff);
      this._pressureMultiplier.push(pressureDiff / yDiff);
      this._temperatureMultiplier.push(temperatureDiff / yDiff);

      // console.log(
      //   "Depth Multiplier: " +
      //     this._depthMultiplier[i] +
      //     " | Pressure Multiplier: " +
      //     this._pressureMultiplier[i] +
      //     " | Temperature Multiplier: " +
      //     this._temperatureMultiplier[i]
      // );
    }
  }

  /**
   * Called on the scene starts.
   */
  public onStart(): void {
    this._scrollCamera = ScrollCamera.getInstance();
    this._guiManager = GuiManager.getInstance();

    this._lastPositionY,
      (this._lastUpdateY = this._scrollCamera.movingCamera.position._y);

    this._guiManager.updateDepth(this._depthInfos[0].depth);
    this._guiManager.updatePressure(this._depthInfos[0].pressure);
    this._guiManager.updateTemperature(this._depthInfos[0].temperature);
  }

  /**
   * Called each frame.
   */
  public onUpdate(): void {
    if (typeof this._scrollCamera === "undefined") {
      return;
    }

    // Only update if we're moving
    if (this._lastPositionY === this._scrollCamera.movingCamera.position._y) {
      return;
    }

    // console.log("[DepthMeter] Current position Y: " + this._scrollCamera.movingCamera.position._y);

    // The camera starts above water level so we have to check whether we've reached the first position Y in depthInfos.
    if (
      this._scrollCamera.movingCamera.position._y >
      this._depthInfos[0].positionY
    ) {
      this._guiManager.updateDepth(this._depthInfos[0].depth);
      this._guiManager.updatePressure(this._depthInfos[0].pressure);
      this._guiManager.updateTemperature(this._depthInfos[0].temperature);
      this._lastUpdateY = this._scrollCamera.movingCamera.position._y;
      this._lastPositionY = this._scrollCamera.movingCamera.position._y;
      return;
    }

    // Stop updating at 2002m
    if (
      this._scrollCamera.movingCamera.position._y <
      this._depthInfos[this._depthInfos.length - 1].positionY
    ) {
      this._guiManager.updateDepth(
        this._depthInfos[this._depthInfos.length - 1].depth
      );
      this._guiManager.updatePressure(
        this._depthInfos[this._depthInfos.length - 1].pressure
      );
      this._guiManager.updateTemperature(
        this._depthInfos[this._depthInfos.length - 1].temperature
      );
      this._lastUpdateY = this._scrollCamera.movingCamera.position._y;
      this._lastPositionY = this._scrollCamera.movingCamera.position._y;
      return;
    }

    // Determine which segment of the story we're in so we know how fast the depth, pressure, and temperature should change here
    let currentDepthSegment: number = 0;

    while (currentDepthSegment < this._depthInfos.length - 1) {
      if (
        this._depthInfos[currentDepthSegment + 1].positionY <
        this._scrollCamera.movingCamera.position._y
      ) {
        break;
      } else {
        // Check next segment
        currentDepthSegment++;
      }
    }

    //console.log("Current depth segment: " + currentDepthSegment);

    // Updating displayed depth, pressure, temperature every time we've moved +/-1 units in Y direction
    if (
      Math.abs(
        this._scrollCamera.movingCamera.position._y - this._lastUpdateY
      ) > 1
    ) {
      let newDepth: number =
        this._depthInfos[currentDepthSegment].depth +
        Math.abs(
          this._scrollCamera.movingCamera.position._y -
            this._depthInfos[currentDepthSegment].positionY
        ) *
          this._depthMultiplier[currentDepthSegment];

      let newPressure: number =
        this._depthInfos[currentDepthSegment].pressure +
        Math.abs(
          this._scrollCamera.movingCamera.position._y -
            this._depthInfos[currentDepthSegment].positionY
        ) *
          this._pressureMultiplier[currentDepthSegment];

      let newTemperature: number =
        this._depthInfos[currentDepthSegment].temperature -
        Math.abs(
          this._scrollCamera.movingCamera.position._y -
            this._depthInfos[currentDepthSegment].positionY
        ) *
          this._temperatureMultiplier[currentDepthSegment];

      this._guiManager.updateDepth(Math.round(newDepth));
      // Round pressure to 1 decimal place
      this._guiManager.updatePressure(Math.round(newPressure * 10) / 10);
      this._guiManager.updateTemperature(Math.round(newTemperature));

      this._lastUpdateY = this._scrollCamera.movingCamera.position._y;
    }

    this._lastPositionY = this._scrollCamera.movingCamera.position._y;
  }
}

export type DepthInfo = {
  positionY: number;
  // The textCue and nearestWaypointId are not used anywhere, it just makes it easier for us as developers to know where each point is
  textCue: string;
  nearestWaypointId: string;
  depth: number;
  pressure: number;
  temperature?: number; // Temperature is not specified every time the other values are mentioned in the storyboard
};
