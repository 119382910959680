import AudioManager from "./audio-manager";
import { Credits } from "./credits";
import GuiManager from "./gui-manager";

export class Sidenav {
  private static instance: Sidenav;

  constructor() {
    if (Sidenav.instance) return null;

    let sidenavContainer = document.createElement("div");
    sidenavContainer.id = "sidenavContainerOverlay";

    sidenavContainer.innerHTML = `
        <div id="mySidenav" class="sidenav font-copy-large">
            <img src="./assets/gui/svg/close.png" id="sidenavCloseButton">
            <div id="shareText">Teilen:</div>
            <div id="shareContainer">
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://tiefseetauchfahrt.zdf.de" target="_blank" class="share-button-link">
                  <img src="./assets/gui/svg/Share_Fb.svg" class="share-button" onmouseover="this.src = './assets/gui/svg/Share_Fb_hover.svg'" onmouseout="this.src = './assets/gui/svg/Share_Fb.svg'">
                </a>
                <a href="https://twitter.com/share?url=https%3A%2F%2Ftiefseetauchfahrt.zdf.de&text=Tauche%20ab%20mit%20der%20Terra%20X%20Tiefseetauchfahrt%21" target="_blank" class="share-button-link">
                  <img src="./assets/gui/svg/Share_Twitter.svg" class="share-button" onmouseover="this.src = './assets/gui/svg/Share_Twitter_hover.svg'" onmouseout="this.src = './assets/gui/svg/Share_Twitter.svg'">
                </a>
                <a href="https://api.whatsapp.com/send?text=Tauche%20ab%20mit%20der%20Terra%20X%20Tiefseetauchfahrt%21%20https%3A%2F%2Ftiefseetauchfahrt.zdf.de" target="_blank" class="share-button-link">
                  <img src="./assets/gui/svg/Share_Whatsapp.svg" class="share-button" onmouseover="this.src = './assets/gui/svg/Share_Whatsapp_hover.svg'" onmouseout="this.src = './assets/gui/svg/Share_Whatsapp.svg'">
                </a>
                <a href="mailto:?subject=Tauche ab mit der Terra X Tiefseetauchfahrt!&amp;body=Leuchtende Wesen, die größte Tierwanderung des Planeten und Geisternetze - die Ozeane sind voller Geheimnisse. Teste dein Wissen und sammle Meeresbewohner ein! https://tiefseetauchfahrt.zdf.de" class="share-button-link">
                  <img src="./assets/gui/svg/Share_Mail.svg" class="share-button" onmouseover="this.src = './assets/gui/svg/Share_Mail_hover.svg'" onmouseout="this.src = './assets/gui/svg/Share_Mail.svg'">
                </a>
            </div>

            <div id="sidenavFooter">
                <a href="https://schwarm.zdf.de" class="sidenav-text mainLink" target="_blank">Mehr zu „Der Schwarm“ in der Mediathek</a>
                <a href="https://www.zdf.de/regeln-und-nutzungsbedingungen-100.html" class="sidenav-text" target="_blank">Nutzungsbedingungen</a>
                <a href="https://www.zdf.de/dsgvo" class="sidenav-text" target="_blank">Datenschutzerklärung</a>
                <a href="https://www.zdf.de/mein-zdf#nutzungsdaten" class="sidenav-text" target="_blank">Datenschutz-Einst.</a>
                <a href="https://www.zdf.de/service-und-hilfe/zuschauerservice/impressum-zdf-100.html" class="sidenav-text" target="_blank">Impressum</a>
                <div id="creditsLink" class="sidenav-text">Abspann</div>
            </div>
        </div>
            `;

    document.getElementById("sidenavContainer").appendChild(sidenavContainer);

    let closeButton = document.getElementById(
      "sidenavCloseButton"
    ) as HTMLButtonElement;
    closeButton.addEventListener("click", () => {
      this.closeNav();
    });

    let creditsLink = document.getElementById(
      "creditsLink"
    ) as HTMLButtonElement;
    creditsLink.addEventListener("click", () => {
      this.closeNav();
      Credits.getInstance().open();
    });
  }

  public static getInstance(): Sidenav {
    if (!Sidenav.instance) Sidenav.instance = new Sidenav();

    return Sidenav.instance;
  }

  public openNav(): void {
    let guiManager: GuiManager = GuiManager.getInstance();

    if (guiManager.useMobileLayout()) {
      document.getElementById("mySidenav").classList.add("sidenav-mobile");
    }
    else {
      document.getElementById("mySidenav").classList.remove("sidenav-mobile");
    }

    // Opening sidenav pushes other elements to the side
    let sidenavWidth: string = "280px";
    document.getElementById("mySidenav").style.width = sidenavWidth;
    document.getElementById("renderCanvas").style.marginLeft = sidenavWidth;
    document.getElementById("guiContainerOverlay").style.marginLeft = sidenavWidth;

    document.getElementById("renderCanvas").style.backgroundColor = "rgba(0,0,0,0.4)";
    document.getElementById("guiContainerOverlay").style.backgroundColor = "rgba(0,0,0,0.4)";

    let closePlane = document.getElementById("renderCanvas");
    closePlane.addEventListener("click", () => {
      this.closeNav();
    });
  }

  public closeNav(): void {
    AudioManager.getInstance().playSound({
      fileName: "Schwarm_SFX_Menu_Click_1.m4a",
      loop: false,
      nonWPSound: true,
    });

    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("renderCanvas").style.marginLeft = "0";
    document.getElementById("guiContainerOverlay").style.marginLeft = "0";

    document.getElementById("renderCanvas").style.backgroundColor = "rgba(0,0,0,0)";
    document.getElementById("guiContainerOverlay").style.backgroundColor = "rgba(0,0,0,0)";

    let closePlane = document.getElementById("renderCanvas");
    closePlane.removeEventListener("click", () => {
      this.closeNav();
    });
  }
}
