import {
  Material,
  Mesh,
  PBRMaterial,
  StandardMaterial,
  Vector3,
} from "@babylonjs/core";
import { visibleInInspector } from "./decorators";

export default class MaterialLoader extends Mesh {
  public static readonly WP_DELTA: number = 25;

  @visibleInInspector("string", "File Name")
  private _fileName: string;

  @visibleInInspector("string", "File Path")
  private _filePath: string;

  @visibleInInspector("boolean", "Use Alternative anchor", false)
  private _useAltAnchor: boolean;

  @visibleInInspector("Vector3", "Alternative anchor")
  private _altAnchor: Vector3;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  private _anchor: Vector3;
  public onInitialized(): void {
    this._anchor = this._useAltAnchor ? this._altAnchor : this.absolutePosition;
  }

  private _loaded: boolean = false;
  public onUpdate(): void {
    let horDistance = Math.abs(
      this._anchor._y - this._scene.activeCamera.position._y
    );

    if (horDistance <= MaterialLoader.WP_DELTA) {
      if (this._loaded) return;
      let scene = this.getScene();
      fetch(this._filePath + this._fileName)
        .then((response) => response.json())
        .then((json) => {
          let material = PBRMaterial.Parse(json, scene, "./assets/").clone(
            this.name
          ) as PBRMaterial;
          this.material = material;
        });
      this._loaded = true;
    } else {
      if (!this.material) return;
      this.material.dispose();
      this._loaded = false;
    }
  }
}
