import { Mesh, Space, Sprite, SpriteManager, Vector3 } from "@babylonjs/core";
import { visibleInInspector } from "./decorators";
import MaterialLoader from "./material-loader";

export default class SpritesheetAnimation extends Mesh {
  @visibleInInspector("string", "Sprite Name")
  private _spriteName: string;

  @visibleInInspector("string", "ImgUrl")
  private _imgUrl: string;

  @visibleInInspector("number", "Capacity", 3)
  private _capacity: number;

  @visibleInInspector("number", "Cell Size", 256)
  private _cellSize: number;

  @visibleInInspector("number", "Sprite Size", 1)
  private _spriteSize: number;

  @visibleInInspector("number", "Sprite Angle", 0)
  private _spriteAngle: number;

  @visibleInInspector("number", "Start Frame", 0)
  private _startFrame: number;

  @visibleInInspector("number", "End Frame", 250)
  private _endFrame: number;

  @visibleInInspector("boolean", "Loop", true)
  private _loop: boolean;

  @visibleInInspector("number", "Delay", 100)
  private _delay: number;

  @visibleInInspector("boolean", "Move", true)
  private _move: boolean;

  @visibleInInspector("number", "Max Move Distance", 0.1)
  private _maxMoveDistance: number;

  @visibleInInspector("number", "Move Speed", 0.01)
  private _moveSpeed: number;

  @visibleInInspector("boolean", "Play On Init", true)
  private _playOnInit: boolean;

  private _startPosition: Vector3;
  private _moveDirUp: boolean = true;
  private _sprite: Sprite;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  private initialize(): void {
    let spriteManager = new SpriteManager(
      this._spriteName + "Manager",
      this._imgUrl,
      this._capacity,
      this._cellSize,
      this.getScene()
    );

    spriteManager.fogEnabled = true;

    this._sprite = new Sprite(this._spriteName, spriteManager);
    this._sprite.size = this._spriteSize;
    this._sprite.angle = this._sprite.angle + this._spriteAngle;

    this._sprite.isVisible = false;
    if (this._playOnInit) {
      this.playAnimation();
    }

    // Randomize start frame
    this._sprite.cellIndex = Math.floor(Math.random() * this._endFrame);
    this._sprite.position = this.absolutePosition;
    if (this._move) {
      this._startPosition = this.absolutePosition.clone();
      // Randomize start position
      let offset = this._maxMoveDistance * (2 * Math.random() - 1);
      this.translate(this.up, offset, Space.WORLD);
    }
    this._initialized = true;
  }

  private _initialized: boolean = false;
  public onUpdate(): void {
    let horDistance = Math.abs(
      this.absolutePosition._y - this._scene.activeCamera.position._y
    );

    if (horDistance <= MaterialLoader.WP_DELTA) {
      if (this._initialized) {
        if (this._move) {
          this.translate(
            this.up,
            this._moveDirUp ? this._moveSpeed : -this._moveSpeed,
            Space.WORLD
          );
          if (
            this._startPosition.subtract(this.absolutePosition).length() >
            this._maxMoveDistance
          )
            this._moveDirUp = !this._moveDirUp;
        } else return; // no movement
      } else {
        // init
        this.initialize();
      }
    } else {
      // dispose
      this._sprite?.dispose();
      this._initialized = false;
    }
  }

  public playAnimation(): void {
    this._sprite.isVisible = true;
    this._sprite.playAnimation(
      this._startFrame,
      this._endFrame,
      this._loop,
      this._delay
    );
  }

  public stopAnimation(): void {
    if (!this._sprite) return;
    this._sprite.isVisible = false;
    this._sprite.stopAnimation();
  }
}
