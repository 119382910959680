import AudioManager from "./audio-manager";
import GuiManager from "./gui-manager";

export default class HUD {
  private static instance: HUD;

  constructor() {
    if (HUD.instance) return null;

    let hudContainer = document.createElement("div");
    hudContainer.id = "hudContainer";
    hudContainer.className = "hud-container";

    hudContainer.innerHTML = `
                <div id='hudHeaderBar' class='hud-header-bar'>
                    <div id='hudMenuButton' class='hud-menu-button'>
                        <img
                            id='hudMenuButtonImage'
                            src='./assets/gui/svg/Burger 20px.svg'
                            class='hud-menu-button-icon'    
                        >
                    </div>
                    <a href="https://www.zdf.de" target="_blank" class="hud-logo-link">
                        <img
                            src='./assets/gui/svg/zdf-logo_orange.svg'
                            alt='logo'
                            id='logo'
                            class='hud-logo'
                        >
                    </a>
                    <div id='hudRightButtons' class='hud-right-buttons'>
                      <div id='hudSoundButton' class='hud-sound-button-desktop'>
                        <img
                            id='hudSoundButtonImage'
                            src='./assets/gui/svg/Ton.svg' 
                            alt='sound button'
                            class='hud-sound-button-image'
                        >
                        <div id='hudSoundButtonText'>
                          Ton an
                        </div>
                      </div>
                      <div id="hudModelViewButtonContainer">
                        <img
                            src='./assets/gui/svg/3D_AR-ICON.svg'
                            alt='model view button'
                            id='hudModelViewButton'
                            class='hud-model-view-button'
                        >
                        <img
                            src='./assets/gui/svg/AR-ICON-hover.svg'
                            alt='model view button'
                            id='hudModelViewButtonHover'
                            class='hud-model-view-button-hover'
                        >
                      </div>
                    </div>
                </div>
                <div id='hudSideBar'>
                    <div id='hudGaugesContainer'>
                    <div id='hudDepthGauge' class='hud-gauge-container'>
                        <img
                            src='./assets/gui/svg/Hoehen-Anzeiger.svg'
                            alt='depth icon'
                            class='hud-gauge-icon'
                        >
                        <label id='depthValue' class='font-gauge-text'></label>
                        <label id='depthLabel' class='gauge-label font-gauge-text'>TIEFE</label>
                    </div>
                    <div id='hudPressureGauge' class='hud-gauge-container'>
                        <img
                            src='./assets/gui/svg/Tiefendruck.svg'
                            alt='pressure icon'
                            class='hud-gauge-icon'
                        >
                        <label id='pressureValue' class='font-gauge-text'></label>
                        <label id='pressureLabel' class='gauge-label font-gauge-text'>TIEFENDRUCK</label>
                    </div>
                    <div id='hudTemperatureGauge' class='hud-gauge-container'>
                        <img
                            src='./assets/gui/svg/Temperatur.svg'
                            alt='temperature icon'
                            class='hud-gauge-icon'
                        >
                        <label id='temperatureValue' class='font-gauge-text'></label>
                        <label id='temperatureLabel' class='gauge-label font-gauge-text'>TEMPERATUR</label>
                    </div>
                    </div>
                </div>
                <div id="hudFooter">
                    <div id="partnerContainer">
                    <label id='partnerLabel' class='partner-label'>In Kooperation mit</label>
                    <img
                        src='./assets/gui/svg/partner.svg'
                        alt='partner'
                        id='partner'
                    >
                    </div>
                </div>
               
        `;

    document.getElementById("guiContainerOverlay").appendChild(hudContainer);

    document.getElementById("hudMenuButton").addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      GuiManager.getInstance().openMenu();
    });

    document.getElementById("hudSoundButton").addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      GuiManager.getInstance().toggleMute();
      let soundButtonImage = document.getElementById(
        "hudSoundButtonImage"
      ) as HTMLImageElement;
      soundButtonImage.src = AudioManager.getInstance().isMuted()
        ? "./assets/gui/svg/Mute.svg"
        : "./assets/gui/svg/Ton.svg";
      document.getElementById("hudSoundButtonText").textContent =
        AudioManager.getInstance().isMuted() ? "Ton aus" : "Ton an";
    });

    document
      .getElementById("hudModelViewButton")
      .addEventListener("click", () => {
        AudioManager.getInstance().playSound({
          fileName: "Schwarm_SFX_Menu_Click_1.m4a",
          loop: false,
          nonWPSound: true,
        });
        GuiManager.getInstance().openInventory();
      });
  }

  public static getInstance(): HUD {
    if (!HUD.instance) HUD.instance = new HUD();

    return HUD.instance;
  }

  public setMobile(): void {
    document.getElementById("hudContainer").classList.add("hud-container-mobile");

    document.getElementById("hudHeaderBar").className = "hud-header-bar-mobile";
    document.getElementById("hudSideBar").className = "hud-sidebar-mobile";
    document.getElementById("hudGaugesContainer").className = "hud-all-gauges-mobile";
    document.getElementById("hudSoundButton").className = "hud-sound-button-mobile";
    document.getElementById("hudRightButtons").className = "hud-right-buttons-mobile";
    document.getElementById("hudSoundButtonText").className = "element-hidden";
    document.getElementById("logo").className = "hud-logo-mobile";
    document.getElementById("partner").className = "partner-mobile";
    document.getElementById("hudModelViewButtonHover").style.display = "none";
    document.getElementById("hudMenuButtonImage").classList.add("hud-menu-button-icon-mobile");

    // Change font in gauges
    document.getElementById("depthValue").classList.add("font-gauge-text-mobile");
    document.getElementById("depthLabel").classList.add("font-gauge-text-mobile");
    document.getElementById("pressureValue").classList.add("font-gauge-text-mobile");
    document.getElementById("pressureLabel").classList.add("font-gauge-text-mobile");
    document.getElementById("temperatureValue").classList.add("font-gauge-text-mobile");
    document.getElementById("temperatureLabel").classList.add("font-gauge-text-mobile");

    let gaugeIcons = document.getElementsByClassName("hud-gauge-icon");
    for (let i = 0; i < gaugeIcons.length; i++) {
      gaugeIcons[i].classList.add("hud-gauge-icon-mobile");
    }
  }

  public setDesktop(): void {
    document.getElementById("hudContainer").classList.remove("hud-container-mobile");

    document.getElementById("hudHeaderBar").className = "hud-header-bar";
    document.getElementById("hudMenuButtonImage").className = "hud-menu-button-icon";
    document.getElementById("hudSideBar").className = "hud-sidebar-desktop";
    document.getElementById("hudGaugesContainer").className = "hud-all-gauges-desktop";
    document.getElementById("hudSoundButton").className = "hud-sound-button-desktop";
    document.getElementById("hudSoundButtonText").className = "hud-sound-button-text font-copy";
    document.getElementById("hudRightButtons").className = "hud-right-buttons";
    document.getElementById("logo").className = "hud-logo-desktop";
    document.getElementById("partner").className = "partner-desktop";
    document.getElementById("hudMenuButtonImage").classList.remove("hud-menu-button-icon-mobile");

    let gaugeIcons = document.getElementsByClassName("hud-gauge-icon");
    for (let i = 0; i < gaugeIcons.length; i++) {
      gaugeIcons[i].classList.remove("hud-gauge-icon-mobile");
    }

    // Change font in gauges
    document
      .getElementById("depthValue")
      .classList.remove("font-gauge-text-mobile");
    document
      .getElementById("depthLabel")
      .classList.remove("font-gauge-text-mobile");
    document
      .getElementById("pressureValue")
      .classList.remove("font-gauge-text-mobile");
    document
      .getElementById("pressureLabel")
      .classList.remove("font-gauge-text-mobile");
    document
      .getElementById("temperatureValue")
      .classList.remove("font-gauge-text-mobile");
    document
      .getElementById("temperatureLabel")
      .classList.remove("font-gauge-text-mobile");
  }

  // Tablets use the desktop layout with small modifications
  public setTablet(): void {
    this.setDesktop();

    document.getElementById("hudSideBar").className = "hud-sidebar-tablet";
    document.getElementById("hudGaugesContainer").className = "hud-all-gauges-tablet";
    document.getElementById("hudRightButtons").className = "hud-right-buttons-tablet";
    document.getElementById("hudMenuButtonImage").className = "hud-menu-button-icon-tablet";
  }

  public setPressure(pressure: number): void {
    document.getElementById("pressureValue").textContent =
      pressure.toLocaleString("de-DE") + " bar";
  }

  public setDepth(depth: number): void {
    document.getElementById("depthValue").textContent = depth.toString() + " m";
  }

  public setTemperature(temperature: number): void {
    document.getElementById("temperatureValue").textContent =
      temperature.toString() + "°C";
  }

  public toggleGaugeLabels(showGaugeLabels?: boolean): void {
    // console.log("[HUD] Showing gauge labels: " + showGaugeLabels);
    let gaugeLabels = document.getElementsByClassName("gauge-label");

    for (let i = 0; i < gaugeLabels.length; i++) {
      // If no parameter was passed, switch gauge label visibility to the opposite state.
      if (showGaugeLabels === undefined) {
        if (gaugeLabels[i].classList.contains("element-hidden")) {
          gaugeLabels[i].classList.remove("element-hidden");
        } else {
          gaugeLabels[i].classList.add("element-hidden");
        }
      }
      // If a specific value for showGaugeLabels was passed, apply it.
      else {
        if (showGaugeLabels === true) {
          gaugeLabels[i].classList.remove("element-hidden");
        } else {
          gaugeLabels[i].classList.add("element-hidden");
        }
      }
    }
  }

  public toggleGauge(showGauge: boolean): void {
    let gauge = document.getElementById("hudGaugesContainer");

    if (showGauge) {
      gauge.classList.remove("element-hidden");
    } else {
      gauge.classList.add("element-hidden");
    }
  }

  public toggleLogo(showLogo: boolean): void {
    // TODO: This is just a temporary fix. After the "Reise beginnen" button has been clicked once, you can scroll past it without clicking it and then the partner logos won't be hidden by startClick() in narration.ts
    this.togglePartners(showLogo);

    if (document.getElementsByClassName("hud-logo-mobile").item(0)) {
      let logo = document.getElementsByClassName("hud-logo-mobile").item(0);

      if (showLogo) {
        logo.classList.remove("element-hidden");
      } else {
        logo.classList.add("element-hidden");
      }
    } else {
      return;
    }
  }

  public toggleHeaderbar(showHeaderbar: boolean): void {
    let header = document.getElementById("hudHeaderBar");

    if (showHeaderbar) {
      header.classList.remove("element-hidden");
    } else {
      header.classList.add("element-hidden");
    }
  }

  public togglePartners(showPartners: boolean): void {
    let partner = document.getElementById("hudFooter");
    if (showPartners) {
      partner.classList.remove("element-hidden");
    } else {
      partner.classList.add("element-hidden");
    }
  }
}
