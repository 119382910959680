import {
  Color3,
  Color4,
  DefaultRenderingPipeline,
  ImageProcessingConfiguration,
} from "@babylonjs/core";
import { Node } from "@babylonjs/core/node";
import ScrollCamera from "../scroll";
import { lerp } from "../utilities";
import WaypointManager from "./waypoint-manager";

export default class FogManager extends Node {
  private static instance: FogManager;

  private _scrollCamera: ScrollCamera;
  private _lastPointOnPath: number;

  // Waypoint ID and corresponding fog value ID
  private static waypointToFogMap: { [waypointId: number]: number } = {
    "0": 0,
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 1,
    "7": 1,
    "8": 1,
    "9": 2,
    "10": 3,
    "11": 3, // 10a
    "12": 4,
    "13": 4,
    "14": 5,
    "15": 6,
    "16": 7,
    "17": 7,
    "18": 8,
    "19": 9,
    "20": 8,
    "21": 10,
    "22": 10,
    "23": 10,
    "24": 10,
  };

  private static fogValues: Fog[] = [
    {
      // 0 -- Scene 1-3: Waypoints 00 - 05
      fogColor: new Color3(0.41, 0.68, 0.57),
      fogStart: 1.25,
      fogEnd: 100,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.08, 0.04, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0,
    },
    {
      // 1 -- Scene 4: Waypoints 06 - 08
      fogColor: new Color3(0.09, 0.58, 0.59),
      fogStart: 1,
      fogEnd: 175,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0,
    },
    {
      // 2 -- Scene 5: Waypoint 09
      fogColor: new Color3(0.06, 0.48, 0.52),
      fogStart: 1,
      fogEnd: 150,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0,
    },
    {
      // 3 -- Scene 6: Waypoint 10
      fogColor: new Color3(0.06, 0.48, 0.52),
      fogStart: 1,
      fogEnd: 80,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0,
    },
    {
      // 4 -- Scene 7: Waypoints 11 - 12
      fogColor: new Color3(0.05, 0.3, 0.34),
      fogStart: -2,
      fogEnd: 10,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 5 -- Scene 8: Waypoint 13
      fogColor: new Color3(0.05, 0.3, 0.34),
      fogStart: -2,
      fogEnd: 14,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 6 -- Scene 9: Waypoint 14
      fogColor: new Color3(0.04, 0.11, 0.14),
      fogStart: -20,
      fogEnd: 20,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 7 -- Scene 10: Waypoints 15 - 16
      fogColor: new Color3(0.04, 0.11, 0.14),
      fogStart: 1,
      fogEnd: 1.3,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 8 -- Scene 11: Waypoints 17, 19
      fogColor: new Color3(0.04, 0.11, 0.14),
      fogStart: -5,
      fogEnd: 50,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 9 -- Scene 11: Waypoint 18
      fogColor: new Color3(0.04, 0.11, 0.14),
      fogStart: -5,
      fogEnd: 20,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 127,
      bloomScale: 0.31,
    },
    {
      // 10 -- Scene 12-13: Waypoints 20 - 22
      fogColor: new Color3(0.04, 0.11, 0.14),
      fogStart: 1,
      fogEnd: 100,
      fogDensity: 1,
      vignetteColor: new Color4(0, 0.06, 0.11, 1),
      vignetteWeight: 2,
      bloomKernel: 200,
      bloomScale: 0.31,
    },
  ];

  private static defaultPipeline: DefaultRenderingPipeline;

  public static getInstance(): FogManager {
    if (!FogManager.instance) {
      FogManager.instance = new FogManager("FogManager");
    }
    return FogManager.instance;
  }

  public onStart(): void {
    this._scrollCamera = ScrollCamera.getInstance();

    this._lastPointOnPath = this._scrollCamera.cameraPath.getClosestPositionTo(
      this._scrollCamera.movingCamera.position
    );
  }

  public onUpdate(): void {
    if (typeof this._scrollCamera === "undefined") {
      return;
    }

    let currentPointOnPath: number =
      this._scrollCamera.cameraPath.getClosestPositionTo(
        this._scrollCamera.movingCamera.position
      );

    // Only update if we're moving
    if (this._lastPointOnPath === currentPointOnPath) {
      return;
    }

    let waypointManager: WaypointManager = WaypointManager.getInstance();
    let nextWaypointId: number = 0;
    // Movement direction doesn't matter, it only matters between which waypoints we are
    for (const waypointId in FogManager.waypointToFogMap) {
      let waypointOnPath: number =
        waypointManager.getWaypoints()[waypointId].pointOnPath;
      if (waypointOnPath < currentPointOnPath) {
        nextWaypointId++;
      }
    }

    let lastWaypointId: number;
    if (nextWaypointId > 0) {
      lastWaypointId = nextWaypointId - 1;
    } else {
      lastWaypointId = 0;
    }

    // Determine the lerp amount by taking pointOnPath of the last waypoint as 0 and pointOnPath of the next waypoint as 1
    let distanceBetweenWaypoints: number =
      waypointManager.getDistanceBetweenWaypoints(
        waypointManager.getWaypoints()[lastWaypointId],
        waypointManager.getWaypoints()[nextWaypointId]
      );
    let distanceFromLastWaypoint: number =
      waypointManager.getDistanceBetweenPointsOnPath(
        currentPointOnPath,
        waypointManager.getWaypoints()[lastWaypointId].pointOnPath
      );
    let lerpAmount: number =
      distanceFromLastWaypoint / distanceBetweenWaypoints;

    if (lerpAmount >= 0 && lerpAmount <= 1) {
      let interpolatedFog: Fog = {
        fogColor: Color3.Lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .fogColor,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .fogColor,
          lerpAmount
        ),
        fogStart: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .fogStart,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .fogStart,
          lerpAmount
        ),
        fogEnd: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .fogEnd,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .fogEnd,
          lerpAmount
        ),
        fogDensity: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .fogDensity,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .fogDensity,
          lerpAmount
        ),
        vignetteColor: Color4.Lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .vignetteColor,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .vignetteColor,
          lerpAmount
        ),
        vignetteWeight: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .vignetteWeight,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .vignetteWeight,
          lerpAmount
        ),
        bloomKernel: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .bloomKernel,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .bloomKernel,
          lerpAmount
        ),
        bloomScale: lerp(
          FogManager.fogValues[FogManager.waypointToFogMap[lastWaypointId]]
            .bloomScale,
          FogManager.fogValues[FogManager.waypointToFogMap[nextWaypointId]]
            .bloomScale,
          lerpAmount
        ),
      };

      this.setFog(interpolatedFog);
    }
    this._lastPointOnPath = currentPointOnPath;
  }

  private initializeFog() {
    // console.log("[Fog] Initializing fog");

    // RenderingPipeline for vignette and bloom
    FogManager.defaultPipeline = new DefaultRenderingPipeline(
      "default",
      false,
      this._scene
    );

    // initial fog
    /*     this._scene.fogColor = FogManager.fogValues[0].fogColor;
    this._scene.fogStart = FogManager.fogValues[0].fogStart;
    this._scene.fogEnd = FogManager.fogValues[0].fogEnd;
    this._scene.fogDensity = FogManager.fogValues[0].fogDensity; */

    // initial vignette
    FogManager.defaultPipeline.imageProcessingEnabled = true;
    FogManager.defaultPipeline.imageProcessing.vignetteEnabled = true;
    FogManager.defaultPipeline.imageProcessing.vignetteBlendMode =
      ImageProcessingConfiguration.VIGNETTEMODE_MULTIPLY;
    /*     FogManager.defaultPipeline.imageProcessing.vignetteColor =
      FogManager.fogValues[0].vignetteColor;
    FogManager.defaultPipeline.imageProcessing.vignetteWeight =
      FogManager.fogValues[0].vignetteWeight; */

    // initial bloom
    FogManager.defaultPipeline.bloomEnabled = true;
    /*     FogManager.defaultPipeline.bloomKernel = FogManager.fogValues[0].bloomKernel;
    FogManager.defaultPipeline.bloomScale = FogManager.fogValues[0].bloomScale; */
  }

  public setFogAtWaypoint(waypointId: number) {
    if (waypointId >= 0) {
      // console.log(
      //   "[Fog] Setting fog. Waypoint ID: " +
      //     waypointId +
      //     ", Fog ID: " +
      //     FogManager.waypointToFogMap[waypointId]
      // );
      this.setFog(
        FogManager.fogValues[FogManager.waypointToFogMap[waypointId]]
      );
    }
  }

  public setFog(fog: Fog) {
    if (typeof FogManager.defaultPipeline === "undefined") {
      this.initializeFog();
    }
    this._scene.fogColor = fog.fogColor;
    this._scene.fogStart = fog.fogStart;
    this._scene.fogEnd = fog.fogEnd;
    this._scene.fogDensity = fog.fogDensity;
    FogManager.defaultPipeline.imageProcessing.vignetteColor =
      fog.vignetteColor;
    FogManager.defaultPipeline.imageProcessing.vignetteWeight =
      fog.vignetteWeight;
    FogManager.defaultPipeline.bloomKernel = fog.bloomKernel;
    FogManager.defaultPipeline.bloomScale = fog.bloomScale;
  }
}

export type Fog = {
  fogColor: Color3;
  fogStart: number;
  fogEnd: number;
  fogDensity: number;
  vignetteColor: Color4;
  vignetteWeight: number;
  bloomKernel: number;
  bloomScale: number;
};
