import AnimationManager from "./animation-manager";
import AudioManager from "./audio-manager";
import GuiManager from "./gui-manager";
import ModelViewer from "./model-viewer";
import Narration from "./narration";
import TrackingManager, { PageTitle } from "./tracking-manager";
import WaypointManager from "./waypoint-manager";

export class Inventory {
  private static instance: Inventory;

  public static readonly animalNames: string[] = [
    "Schweinswal",
    "Karettschildkröte",
    "Fischschwarm",
    "Qualle",
    "Anglerfisch",
    "Gespensterfisch",
  ];

  private _items: InventoryItem[];
  private _currentIdx: number = 0;

  constructor() {
    if (Inventory.instance) return null;

    let inventoryContainer = document.createElement("div");
    inventoryContainer.id = "invContainerOverlay";
    inventoryContainer.className = "element-hidden";

    inventoryContainer.innerHTML = `
            <div id='invCloseButtonContainer' class='close-button-container'>
                <img 
                    src='./assets/gui/svg/close.png'
                    alt='close button'
                    id='invCloseButton'
                    class='close-button'
                >
            </div>
            <div id='invModelInfo'>
                <div class='inv-infobox'>
                    <div id='invInfoboxHeader' class='inv-infobox-header font-h-two'>
                        Meine Meerestiere
                    </div>
                    <div id='invInfoboxText' class='inv-infobox-text font-copy'>
                        Du hast noch kein Tier gewonnen. Lies weiter, beantworte Quizze und schalte sie so frei!
                    </div>
                </div>
            
                <div id='invModelGrid'>
                </div>
            </div>
            <div id='invModelpreviewContainer'>
                <img
                    id='invModelPreview'
                    alt='model preview'
                >
            </div>
        `;

    document
      .getElementById("guiContainerOverlay")
      .appendChild(inventoryContainer);

    let closeButton = document.getElementById(
      "invCloseButton"
    ) as HTMLButtonElement;
    closeButton.addEventListener("click", () => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Menu_Click_1.m4a",
        loop: false,
        nonWPSound: true,
      });
      this.close();
    });
  }

  public static getInstance(): Inventory {
    if (!Inventory.instance) Inventory.instance = new Inventory();

    return Inventory.instance;
  }

  public setItems(items: InventoryItem[]): void {
    this._items = items;
    this.loadInventory();
  }

  public open(model?: Model): void {
    TrackingManager.getInstance().pageDisplay(PageTitle.INVENTORY);
    GuiManager.getInstance().pauseGame(true);
    // Only for debugging
    this.loadInventory();

    document.getElementById("guiContainerOverlay").style.backgroundColor =
      "rgba(0,0,0,0.4)";

    /////////////////////////////////////////
    // Unlock all models. Only for debugging
    /*     this.unlockModel(0);
    this.unlockModel(1);
    this.unlockModel(2);
    this.unlockModel(3);
    this.unlockModel(4);
    this.unlockModel(5); */
    /////////////////////////////////////////

    let invContainer = document.getElementById("invContainerOverlay");
    invContainer.className = "inv-container";
    invContainer.style.flexDirection =
      GuiManager.getInstance().useMobileLayout() ? "column" : "row";

    GuiManager.getInstance().useMobileLayout()
      ? document
          .getElementById("invCloseButtonContainer")
          .classList.add("close-button-container-mobile")
      : document
          .getElementById("invCloseButtonContainer")
          .classList.remove("close-button-container-mobile");

    let invModelInfo = document.getElementById("invModelInfo");
    invModelInfo.className = GuiManager.getInstance().useMobileLayout()
      ? "inv-model-info-mobile"
      : "inv-model-info-desktop";
    if (GuiManager.getInstance().isTablet())
      invModelInfo.style.width = this.isFullyLocked() ? "50%" : "41%";
    else if (!GuiManager.getInstance().useMobileLayout())
      invModelInfo.style.width = this.isFullyLocked() ? "50%" : "35%";
    else invModelInfo.style.width = "unset";

    let previewContainer = document.getElementById("invModelpreviewContainer");
    if (this.isFullyLocked()) previewContainer.className = "element-hidden";
    else
      previewContainer.className = GuiManager.getInstance().useMobileLayout()
        ? "inv-modelpreview-container-mobile"
        : "inv-modelpreview-container-desktop";

    this.setModels();

    if (!this.isFullyLocked()) {
      let buttonDiv = document.createElement("div");
      buttonDiv.id = "invOpenModelButtonContainer";
      buttonDiv.innerHTML = `
                <button id='invOpenModelButton' class='primary-button'>
                    TIER ERLEBEN
                </button>
            `;

      document
        .getElementById(
          GuiManager.getInstance().useMobileLayout()
            ? "invModelpreviewContainer"
            : "invModelInfo"
        )
        .appendChild(buttonDiv);

      document
        .getElementById("invOpenModelButton")
        .addEventListener("click", () => {
          AnimationManager.getInstance().stopCurrentAnimations();
          TrackingManager.getInstance().clickAction(
            PageTitle.INVENTORY,
            "Tier erleben",
            Inventory.animalNames[this._currentIdx]
          );
          AudioManager.getInstance().playSound({
            fileName: "Schwarm_SFX_Menu_Click_1.m4a",
            loop: false,
            nonWPSound: true,
          });
          // Open selected model in model-viewer
          new ModelViewer().openModelViewer(this._currentIdx);
        });

      // Adding button to jump to quiz if the animal is not unlocked yet
      if (!document.getElementById("invJumpToQuizButtonContainer")) {
        let buttonToQuizDiv = document.createElement("div");
        buttonToQuizDiv.id = "invJumpToQuizButtonContainer";
        buttonToQuizDiv.innerHTML = `
            <button id='invJumpToQuizButton' class='primary-button'>
              ZUM QUIZ
            </button>
          `;

        document
          .getElementById(
            GuiManager.getInstance().useMobileLayout()
              ? "invModelpreviewContainer"
              : "invModelInfo"
          )
          .appendChild(buttonToQuizDiv);

        document
          .getElementById("invJumpToQuizButton")
          .addEventListener("click", () => {
            TrackingManager.getInstance().clickNavigation(
              PageTitle.INVENTORY,
              "Quiz-0" + (this._currentIdx + 1) + "-Frage-01",
              "Quiz-0" + (this._currentIdx + 1) + "-Frage-01"
            );
            AudioManager.getInstance().playSound({
              fileName: "Schwarm_SFX_Menu_Click_1.m4a",
              loop: false,
              nonWPSound: true,
            });

            // For some reason our quizIds start at 1 and not 0. In order not to accidentally break something by changing the ids, we'll just add +1 here
            WaypointManager.getInstance().jumpToWaypoint(
              Narration.getInstance().getWaypointIdOfQuiz(this._currentIdx + 1)
            );
            this.close();
          });
      }

      this.selectModel(model ? model : 0);
    }
  }

  public close(): void {
    document.getElementById("invModelGrid").innerHTML = "";
    document.getElementById("invContainerOverlay").className = "element-hidden";
    if (!this.isFullyLocked()) {
      document.getElementById("invOpenModelButtonContainer").remove();
    }

    GuiManager.getInstance().pauseGame(false);

    document.getElementById("guiContainerOverlay").style.backgroundColor =
      "rgba(0,0,0,0)";
  }

  private setInfoboxText(): void {
    let numUnlocked = this._items.filter((item) => item.unlocked).length;

    let infoBoxText = document.getElementById("invInfoboxText");
    if (numUnlocked == 0)
      infoBoxText.textContent =
        "Du hast noch kein Tier gewonnen. Lies weiter, beantworte Quizze und schalte sie so frei!";
    else if (this._items[this._currentIdx].unlocked)
      infoBoxText.textContent =
        "Schau dir die freigeschalteten Tiere in 3D an oder betrachte sie per Augmented Reality in deiner Umgebung!";
    else
      infoBoxText.textContent =
        "Dieses Tier ist noch nicht freigeschaltet. Beantworte das Quiz dazu und vervollständige deine Sammlung!";
  }

  private setModels(): void {
    let modelGrid = document.getElementById("invModelGrid");
    modelGrid.className =
      GuiManager.getInstance().isMobileOrTablet() && this.isFullyLocked()
        ? "inv-model-grid-two-rows"
        : "inv-model-grid-one-row";

    this._items.forEach((item, idx) => {
      let div = document.createElement("div");
      div.id = "invModel" + idx;
      div.className =
        "inv-model-container-" + (this.isFullyLocked() ? "locked" : "unlocked");

      div.innerHTML =
        `
                <label>
                    <input
                        id='invModelSelector` +
        idx +
        `'
                        type='radio'
                        name='invModelSelector'
                        class='inv-model-selector'
                    >
                    <div class='inv-model-image-clip'>
                        <img
                            id='invModelImage` +
        idx +
        `'
                            class='inv-model-image'
                        >
                    </div>
                </label>
            `;
      modelGrid.appendChild(div);

      let image = document.getElementById(
        "invModelImage" + idx
      ) as HTMLImageElement;
      if (this.isFullyLocked()) {
        div.style.pointerEvents = "none";
        image.src = item.previewLockedUrl;
      } else {
        div.addEventListener("click", () => {
          AudioManager.getInstance().playSound({
            fileName: "Schwarm_SFX_Menu_Click_1.m4a",
            loop: false,
            nonWPSound: true,
          });
          this.selectModel(parseInt(div.id.replace("invModel", "")));
        });
        if (item.unlocked) {
          image.src = GuiManager.getInstance().useMobileLayout()
            ? item.previewUrlMobile
            : item.previewUrlDesktop;
          image.classList.remove("inv-model-image-locked");
          if (GuiManager.getInstance().useMobileLayout())
            image.classList.remove("inv-model-image-locked-mobile");
        } else {
          image.src = "./assets/gui/svg/Bubble_Question-Mark-Only.svg";
          image.classList.add("inv-model-image-locked");
          if (GuiManager.getInstance().useMobileLayout())
            image.classList.add("inv-model-image-locked-mobile");
        }
      }
    });
  }

  private selectModel(idx: number): void {
    this._currentIdx = idx;
    let currenModel = document.getElementById(
      "invModelSelector" + this._currentIdx
    ) as HTMLInputElement;
    currenModel.checked = true;

    let modelPreview = document.getElementById(
      "invModelPreview"
    ) as HTMLImageElement;
    if (this._items[this._currentIdx].unlocked) {
      modelPreview.src = GuiManager.getInstance().useMobileLayout()
        ? this._items[this._currentIdx].previewUrlMobile
        : this._items[this._currentIdx].previewUrlDesktop;
      document.getElementById("invOpenModelButtonContainer").className =
        "inv-open-model-button-container-" +
        (GuiManager.getInstance().useMobileLayout() ? "mobile" : "desktop");
      modelPreview.className = "inv-model-preview";

      document.getElementById("invJumpToQuizButtonContainer").className =
        "element-hidden";
    } else {
      document.getElementById("invOpenModelButtonContainer").className =
        "element-hidden";
      modelPreview.className = "element-hidden";

      document.getElementById("invJumpToQuizButtonContainer").className =
        "inv-open-model-button-container-" +
        (GuiManager.getInstance().useMobileLayout() ? "mobile" : "desktop");
    }

    this.setInfoboxText();
  }

  private isFullyLocked(): boolean {
    let locked = true;
    this._items.forEach((item) => {
      if (item.unlocked) locked = false;
    });

    return locked;
  }

  public saveInventory(): void {
    localStorage.setItem("thedeepinventory", JSON.stringify(this._items));
  }

  public loadInventory(): void {
    const inventory = localStorage.getItem("thedeepinventory");
    if (inventory) {
      this._items = JSON.parse(inventory);
    } else {
      this.saveInventory();
    }
  }

  public unlockModel(model: Model): void {
    this._items[model].unlocked = true;
    this.saveInventory();
  }

  public getModel(model: Model): InventoryItem {
    return this._items[model];
  }
}

// TODO: Currently, there is no differentiation between desktop and mobile preview images. Will there ever be or should this be removed?
export type InventoryItem = {
  unlocked: boolean;
  previewUrlMobile: string;
  previewUrlDesktop: string;
  previewLockedUrl: string;
};

export enum Model {
  Porpoise = 0,
  SeaTurtle = 1,
  Medaka = 2,
  Jellyfish = 3,
  Anglerfish = 4,
  BarrelEye = 5,
}
