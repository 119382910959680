import AudioManager from "./audio-manager";
import TrackingManager from "./tracking-manager";

export default class Player {
  private static _audioMuted: boolean;

  public static openPlayer(content: string, parent: HTMLElement): void {
    let playerContainer = document.createElement("div");
    playerContainer.id = "playerContainer";
    playerContainer.className = "player-container";
    Player._audioMuted = AudioManager.getInstance().isMuted().valueOf();
    if (!Player._audioMuted) AudioManager.getInstance().toggleMuteAudio();
    playerContainer.innerHTML =
      `
            <figure
                class="b-zdfplayer zdfplayer_ zdfplayer_core/Player"
                zdfplayer
                data-jsb='{
                    "config":"https://` +
      (process.env.NODE_ENV == "production" ? "" : "int-") +
      `ngp.zdf.de/configs/zdf/tiefseetauchfahrt/configuration.json",
                    "content":"` +
      content +
      `", "noTracking": ` +
      !TrackingManager.getInstance().getTrackingConsent() +
      `
                }'
            >
            </figure>
        `;

    parent.appendChild(playerContainer);

    if ((<any>window).zdfplayer) {
      (<any>window).zdfplayer.applyBehaviour(window.document);
    }
  }

  public static closePlayer(): void {
    document.getElementById("playerContainer").remove();
    if (!Player._audioMuted && AudioManager.getInstance().isMuted())
      AudioManager.getInstance().toggleMuteAudio();
  }
}
