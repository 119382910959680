import { IParticleSystem, Mesh, ParticleHelper } from "@babylonjs/core";
import { visibleInInspector } from "./decorators";

export default class ParticleSystemLoader extends Mesh {
  private static readonly CAMERA_Y_DELTA: number = 25;

  @visibleInInspector("string", "PS Path", "")
  private _psPath: string;

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() {}

  private _particleSystem: IParticleSystem;
  private _loaded: boolean = false;
  public onUpdate(): void {
    let horDistance = Math.abs(
      this.absolutePosition._y - this._scene.activeCamera.position._y
    );
    if (horDistance <= ParticleSystemLoader.CAMERA_Y_DELTA) {
      if (this._loaded) return;
      ParticleHelper.ParseFromFileAsync(
        this.name,
        this._psPath,
        this._scene
      ).then((ps) => {
        this._particleSystem = ps;
        this._particleSystem.emitter = this;
        this._particleSystem.start();
        // console.log(
        //   "[PS] Start ps",
        //   this.name,
        //   this._particleSystem.emitter.name
        // );
      });

      this._loaded = true;
    } else {
      if (!this._loaded) return;
      // console.log("[PS] stop ps " + this.name);
      this._particleSystem.stop();
      this._particleSystem.reset();
      this._particleSystem.dispose();
      this._loaded = false;
    }
  }
}
