import { ILoadingScreen } from "@babylonjs/core/Loading/loadingScreen";
import GuiManager from "./scenes/GameManager/gui-manager";

export class CustomLoadingScreen implements ILoadingScreen {
    loadingUIBackgroundColor: string;
    loadingUIText: string;
    circle: SVGCircleElement;
    circumference: number;

    constructor(private loadingCircle: SVGCircleElement,
        private percentLoaded: HTMLElement,
        private loader: HTMLElement) {

        document.getElementById("circle").setAttribute("style", "transform-origin: 50% 50%");


        this.circle = loadingCircle;
        let radius = this.circle.r.baseVal.value;
        this.circumference = radius * 2 * Math.PI;

        this.circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
        this.circle.style.strokeDashoffset = `${this.circumference}`;

    }

    displayLoadingUI(): void {
        this.percentLoaded.innerText = "0%";
    }

    hideLoadingUI(): void {
        setTimeout(() => {
            this.loader.id = "loaded";
        }, 12000);
    }

    updateLoadStatus(status: string): void {
        const offset = this.circumference - Number(status) / 100 * this.circumference;
        this.circle.style.strokeDashoffset = offset.toString();

        this.percentLoaded.innerText = `${status}%`;

        if (this.percentLoaded.innerText == "100%") {
            this.hideLoadingUI();
        }
    }
}