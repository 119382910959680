import { Node } from "@babylonjs/core/node";
import { Sound, Engine } from "@babylonjs/core";
import { SoundInfo } from "./narration";

export default class AudioManager extends Node {
  private static instance: AudioManager;

  private static readonly MASTER_VOLUME: number = 0.7;
  private static readonly ATMO_FADE_DURATION: number = 5; // seconds
  private static readonly FADE_DURATION: number = 0.5; // seconds

  // @ts-ignore ignoring the super call as we don't want to re-init
  protected constructor() { }

  public static getInstance(): AudioManager {
    if (!AudioManager.instance) {
      AudioManager.instance = new AudioManager();
      // Initiates the audio engine, so audio can be muted before
      // the first audio is played
      this._currentAudio = new Sound("", "");
      Engine.audioEngine.useCustomUnlockedButton = true;
      Engine.audioEngine.setGlobalVolume(
        AudioManager._isMuted ? 0.0 : AudioManager.MASTER_VOLUME
      );
    }

    return AudioManager.instance;
  }

  private static _currentAudio: Sound;

  public unlockEngine(): void {
    Engine.audioEngine.unlock();
  }

  // Plays narration audio after the current one has finished fading out.
  public playNarrationAudio(file: string): void {
    let audioFile: string = "./assets/audio/narration/" + file;

    let audioPlaying: boolean = AudioManager.getInstance().stopNarrationAudio();
    let audio: Sound = new Sound("NarrationSound", audioFile, null, () => {
      if (audio) {
        AudioManager._currentAudio = audio;
        AudioManager._currentAudio.play(
          // If audio is playing it will be faded out
          // so wait until we start the next
          audioPlaying ? AudioManager.FADE_DURATION : 0
        );
      } else {
        console.error(
          "[AudioManager] Could not load narration audio file " + audioFile
        );
      }
    });
  }

  // Stops narration audio by gradually fading it out.
  // Returns true if narration audio is currently playing, false if not.
  public stopNarrationAudio(): boolean {
    if (AudioManager._currentAudio.isPlaying) {
      AudioManager._currentAudio.setVolume(0, AudioManager.FADE_DURATION);
      AudioManager._currentAudio.stop(AudioManager.FADE_DURATION);
      return true;
    }
    return false;
  }

  // Stores atmo sounds that are currently playing, two at most.
  private static _atmoSounds: Sound[] = [];
  private static _atmoIndex: number = 0;

  public playAtmo(file: string): void {
    let currentAtmo: Sound = AudioManager._atmoSounds[AudioManager._atmoIndex];
    let newIndex: number = AudioManager._atmoIndex;
    if (currentAtmo && currentAtmo.isPlaying)
      newIndex = AudioManager._atmoIndex === 0 ? 1 : 0;

    let newAtmo: Sound = new Sound(
      "AtmoSound" + newIndex,
      "./assets/audio/atmo/" + file,
      null,
      () => {
        if (newAtmo) {
          AudioManager._atmoSounds[newIndex] = newAtmo;
          AudioManager._atmoSounds[newIndex].play();
          AudioManager._atmoSounds[newIndex].setVolume(
            1,
            AudioManager.ATMO_FADE_DURATION
          );

          // Is it possible to accidentally reach this before comparing the two indices?
          AudioManager._atmoIndex = newIndex;
        } else {
          console.error("[AudioManager] Could not load atmo sound " + file);
        }
      },
      {
        loop: true,
        volume: 0,
      }
    );
    // If there is only one atmo sound playing, no fading is required.
    // This happens before _atmoIndex is set to newIndex.
    if (newIndex === AudioManager._atmoIndex) return;

    // Fade out previous atmo sound.
    let oldAtmo: Sound = AudioManager._atmoSounds[AudioManager._atmoIndex];
    if (oldAtmo && oldAtmo.isPlaying) {
      oldAtmo.setVolume(0, AudioManager.ATMO_FADE_DURATION);
      oldAtmo.stop(AudioManager.ATMO_FADE_DURATION);
    }
  }

  public resetAtmo(): void {
    // Fade out previous atmo sound.
    let oldAtmo: Sound = AudioManager._atmoSounds[AudioManager._atmoIndex];
    if (oldAtmo && oldAtmo.isPlaying) {
      oldAtmo.setVolume(0, 0.5);
      oldAtmo.stop(0.5);
    }
    AudioManager._atmoIndex = 0;
  }

  private static _isMuted: boolean = false;

  public toggleMuteAudio(): void {
    AudioManager._isMuted = !AudioManager._isMuted;
    Engine.audioEngine.setGlobalVolume(
      AudioManager._isMuted ? 0.0 : AudioManager.MASTER_VOLUME
    );
    this.unlockEngine();
  }

  public isMuted(): boolean {
    return AudioManager._isMuted;
  }

  private static _currentSounds: Sound[] = [];
  public playSound(soundInfo: SoundInfo): void {
    let audioFile: string = "./assets/audio/sounds/" + soundInfo.fileName;

    let audio: Sound = new Sound(
      "Sound",
      audioFile,
      null,
      () => {
        if (audio) {
          if (!soundInfo.nonWPSound) AudioManager._currentSounds.push(audio);
          audio.play();
        } else {
          console.error(
            "[AudioManager] Could not load audio file " + audioFile
          );
        }
      },
      {
        loop: soundInfo.loop,
      }
    );
  }

  public stopSounds(): void {
    AudioManager._currentSounds.forEach((sound) => {
      if (sound.isPlaying) {
        sound.stop(AudioManager.FADE_DURATION);
      }
    });
    AudioManager._currentSounds = [];
  }
}
