import {
  Camera,
  Color3,
  Curve3,
  MeshBuilder,
  Path3D,
  Vector3,
} from "@babylonjs/core";
import { Node } from "@babylonjs/core/node";
import { visibleInInspector } from "../decorators";
import GuiManager from "./gui-manager";
import Narration, { NarrationInfo } from "./narration";
import ScrollCamera from "../scroll";
import FogManager from "./fog-manager";
import { readlink } from "fs";
import AnimationManager from "./animation-manager";
import HUD from "./hud";
import AudioManager from "./audio-manager";
import { GUI3DManager } from "@babylonjs/gui";
import TrackingManager from "./tracking-manager";
import { Footer } from "./footer";
import GameManager from "./game-manager";

export type Waypoint = {
  position: Vector3;
  pointOnPath?: number;
  narrationInfos?: NarrationInfo[];
  // TODO: Add quiz
  executeAtWaypoint?(): void;
};

export default class WaypointManager extends Node {
  private static instance: WaypointManager;

  private static waypointsMobile: Waypoint[] = [];
  private static waypointsDesktop: Waypoint[] = [];

  private static stopPointsMobile: number[] = [];
  private static stopPointsDesktop: number[] = [];

  private static pathMobile: Path3D;
  private static pathDesktop: Path3D;

  @visibleInInspector(
    "string",
    "Waypoint Parent Node Mobile",
    "WaypointCams_Mobile"
  )
  private _waypointParentNameMobile = "WaypointCams_Mobile";

  @visibleInInspector(
    "string",
    "Waypoint Parent Node Desktop",
    "WaypointCams_Desktop"
  )
  private _waypointParentNameDesktop = "WaypointCams_Desktop";

  @visibleInInspector("number", "Start Waypoint ID", 0)
  private _startWaypointId = 0;
  private static startWaypointId = 0;

  // Tracking current waypoint
  public static waypointTracker = {
    _currentWaypointId: -1,
    set currentWaypointId(val: number) {
      let oldVal: number = this._currentWaypointId;
      this._currentWaypointId = val;
      this.currentWaypointIdListener(oldVal, val);
    },
    get currentWaypointId() {
      return this._currentWaypointId;
    },
    currentWaypointIdListener: function (oldVal: number, newVal: number) {
      if (oldVal !== newVal && newVal !== -1) {
        GameManager.getInstance().clearCache();
        TrackingManager.getInstance().scroll();
        // console.log("Current waypoint was changed to index " + newVal);
        ScrollCamera.getInstance().cameraScrollingIsPaused = true;

        AnimationManager.getInstance().stopCurrentAnimations();
        AudioManager.getInstance().stopSounds();

        // Do something when new waypoint has been reached.
        WaypointManager.getInstance()
          .getWaypoints()
          [newVal].executeAtWaypoint();
      }
      // if (newVal === -1) {
      //   console.log("Currently not at a waypoint.");
      // }
    },
  };

  public static getInstance(): WaypointManager {
    if (!WaypointManager.instance) {
      WaypointManager.instance = new WaypointManager("WaypointManager");
    }
    return WaypointManager.instance;
  }

  public getWaypoints(): Waypoint[] {
    return GuiManager.getInstance().isMobileOrTablet()
      ? WaypointManager.waypointsMobile
      : WaypointManager.waypointsDesktop;
  }

  public getPath(): Path3D {
    return GuiManager.getInstance().isMobileOrTablet()
      ? WaypointManager.pathMobile
      : WaypointManager.pathDesktop;
  }

  // Returns narrationInfos of the current waypoint
  public getCurrentNarrationInfos(): NarrationInfo[] {
    if (WaypointManager.waypointTracker.currentWaypointId >= 0) {
      return this.getWaypoints()[
        WaypointManager.waypointTracker.currentWaypointId
      ].narrationInfos;
    }
  }

  public onInitialized(): void {
    // Save both the mobile and desktop waypoints
    this.getWaypointsFromScene(true);
    this.getWaypointsFromScene(false);

    // Generate camera paths for mobile and desktop
    this.generatePathFromWaypoints(true);
    this.generatePathFromWaypoints(false);

    // Calculate where each waypoint is on the path
    this.convertWaypointsToPointsOnPath(
      WaypointManager.waypointsMobile,
      WaypointManager.pathMobile
    );
    this.convertWaypointsToPointsOnPath(
      WaypointManager.waypointsDesktop,
      WaypointManager.pathDesktop
    );

    // Calculcate stops on the path between waypoints
    WaypointManager.stopPointsMobile = this.generateIntermediatePoints(true);
    WaypointManager.stopPointsDesktop = this.generateIntermediatePoints(false);

    // Add NarrationInfo to waypoints
    this.addNarrationInfosToWaypoints(true);
    this.addNarrationInfosToWaypoints(false);

    // Define what should be executed when a waypoint is reached
    this.setExecuteAtWayoints(true);
    this.setExecuteAtWayoints(false);

    // Assigning inspector value for startWaypointId to static variable because static variables cannot be visible in inspector
    if (
      this._startWaypointId >= 0 &&
      this._startWaypointId < this.getWaypoints().length &&
      this._startWaypointId % 1 === 0
    ) {
      WaypointManager.startWaypointId = this._startWaypointId;
    } else {
      // If an invalid waypoint ID was provided, start at the first waypoint
      // console.log(
      //   "[WaypointManager] Invalid startWaypointId. Starting at waypoint 0"
      // );
      WaypointManager.startWaypointId = 0;
    }
    // Set first waypoint to trigger currentWaypointIdListener and execute whatever happens at this waypoint
    WaypointManager.waypointTracker.currentWaypointId =
      this.getStartWaypointId();
  }

  private getWaypointsFromScene(useMobileWaypoints: boolean) {
    let waypointCamsParent: Node = useMobileWaypoints
      ? this.getScene().getNodeByName(this._waypointParentNameMobile)
      : this.getScene().getNodeByName(this._waypointParentNameDesktop);

    // console.log("Waypoint Parent Node: " + waypointCamsParent.name);

    if (waypointCamsParent) {
      let waypointCams: Camera[] = waypointCamsParent.getChildren() as Camera[];
      // Putting waypoint cams in alphabetical order because Babylon's hierarchy seems to be random
      waypointCams.sort();

      waypointCams.forEach((waypointCam) => {
        // console.log(
        //   "Waypoint: " +
        //     waypointCam.name +
        //     ", Position: " +
        //     waypointCam.position
        // );
        useMobileWaypoints
          ? WaypointManager.waypointsMobile.push({
              position: waypointCam.position,
              narrationInfos: [],
            })
          : WaypointManager.waypointsDesktop.push({
              position: waypointCam.position,
              narrationInfos: [],
            });
      });
    } else {
      // console.log(
      //   'Could not find node "' + useMobileWaypoints
      //     ? this._waypointParentNameMobile
      //     : this._waypointParentNameDesktop +
      //         '". Please make sure it has not been renamed.'
      // );
    }
  }

  // Add every NarrationInfo to an array in the waypoint with the corresponding index
  private addNarrationInfosToWaypoints(useMobileWaypoints: boolean) {
    let waypoints: Waypoint[] = useMobileWaypoints
      ? WaypointManager.waypointsMobile
      : WaypointManager.waypointsDesktop;

    let narrationInfos =
      Narration.getInstance().getNarrationInfos(useMobileWaypoints);

    waypoints.forEach((waypoint, waypointIndex) => {
      waypoint.narrationInfos = [];
      narrationInfos.forEach((narrationInfo) => {
        if (narrationInfo.waypointId === waypointIndex) {
          waypoint.narrationInfos.push(narrationInfo);
        }
      });
    });
  }

  private setExecuteAtWayoints(useMobileWaypoints: boolean) {
    let waypoints: Waypoint[] = useMobileWaypoints
      ? WaypointManager.waypointsMobile
      : WaypointManager.waypointsDesktop;

    // TODO: Should this vary for each waypoint?
    waypoints.forEach((waypoint, waypointIndex) => {
      waypoint.executeAtWaypoint = function (): void {
        // Reset currentNarrationIndex and lastPageFinished at new waypoint
        Narration.getInstance().currentNarrationIndex = 0;

        if (waypoint.narrationInfos.length > 0)
          Narration.getInstance().showNarrationOverlay(
            waypoint.narrationInfos[0]
          );

        FogManager.getInstance().setFogAtWaypoint(
          WaypointManager.waypointTracker.currentWaypointId
        );

        let guiManager: GuiManager = GuiManager.getInstance();

        // Hide footer at every waypoint except for the last two
        if (
          waypointIndex !==
            WaypointManager.getInstance().getWaypoints().length - 2 &&
          waypointIndex !==
            WaypointManager.getInstance().getWaypoints().length - 1
        ) {
          Footer.getInstance().toggleFooter(false);
        }

        // Hide gauge labels on mobile and on tablets in portrait mode after the second waypoint
        if (
          waypointIndex >= 2 &&
          (guiManager.isMobile() ||
            (guiManager.isTablet() && guiManager.orientationIsPortrait()))
        ) {
          HUD.getInstance().toggleGaugeLabels(false);
        } else {
          HUD.getInstance().toggleGaugeLabels(true);
        }

        // At the second last waypoint, read the absolute height of the footer so we can use it for the footer transition animation
        if (
          waypointIndex ==
          WaypointManager.getInstance().getWaypoints().length - 2
        ) {
          Footer.getInstance().getFooterHeightInBackground();
        }
      };
    });
  }

  private generatePathFromWaypoints(useMobileWaypoints: boolean) {
    // Creating spline from waypoints so we don't have any hard turns in the camera movement
    let splineFromWaypoints: Curve3;
    let waypointPositions: Vector3[] = [];

    if (useMobileWaypoints) {
      WaypointManager.waypointsMobile.forEach((waypoint) => {
        waypointPositions.push(waypoint.position);
      });
    } else {
      WaypointManager.waypointsDesktop.forEach((waypoint) => {
        waypointPositions.push(waypoint.position);
      });
    }

    splineFromWaypoints = Curve3.CreateCatmullRomSpline(
      waypointPositions,
      50,
      false
    );

    let firstPosition: Vector3 = useMobileWaypoints
      ? WaypointManager.waypointsMobile[0].position
      : WaypointManager.waypointsDesktop[0].position;

    // Creating path from spline
    let path: Path3D = new Path3D(
      splineFromWaypoints.getPoints(),
      firstPosition,
      false,
      true
    );

    // Setting direction of all normals with the first waypoint as reference vector
    path.update(path.getCurve(), firstPosition);

    useMobileWaypoints
      ? (WaypointManager.pathMobile = path)
      : (WaypointManager.pathDesktop = path);
  }

  private convertWaypointsToPointsOnPath(waypoints: Waypoint[], path: Path3D) {
    waypoints.forEach((waypoint) => {
      waypoint.pointOnPath = path.getClosestPositionTo(waypoint.position);
    });
  }

  // Add intermediate points on the path between the waypoints. They don't hold any info like waypoints do, they just serve as stops while scrolling
  private generateIntermediatePoints(useMobileWaypoints: boolean): number[] {
    let waypoints: Waypoint[] = useMobileWaypoints
      ? WaypointManager.waypointsMobile
      : WaypointManager.waypointsDesktop;

    let stopPoints: number[] = useMobileWaypoints
      ? WaypointManager.stopPointsMobile
      : WaypointManager.stopPointsDesktop;

    waypoints.forEach((waypoint, index) => {
      stopPoints.push(waypoint.pointOnPath);

      // TODO: This is an ugly quick fix but we don't want any intermediate points anymore.
      return;

      // Do not add any intermediate points after the first or last waypoint
      if (index > 0 && index < waypoints.length - 1) {
        let distance: number = this.getDistanceBetweenPointsOnPath(
          waypoint.pointOnPath,
          waypoints[index + 1].pointOnPath
        );
        // console.log(
        //   "Distance between waypoints " +
        //     index +
        //     " and " +
        //     (index + 1) +
        //     ": " +
        //     distance
        // );
        // Number of sections between waypoints. n sections means n-1 intermediate points
        // TODO: Vary the number of intermediate points across the scene? 2 stops can look odd if the distance is not far.
        let numberOfSections: number = 1;
        if (distance >= 0.05) {
          numberOfSections = 2;
        }
        if (distance >= 0.075) {
          numberOfSections = 3;
        }
        if (distance >= 0.1) {
          numberOfSections = 4;
        }

        // console.log(
        //   "Adding " + (numberOfSections - 1) + " intermediate stop points."
        // );

        for (let i: number = 1; i < numberOfSections; i++) {
          stopPoints.push(
            waypoint.pointOnPath + (distance / numberOfSections) * i
          );
        }
      }
    });
    // console.log("Stop points: " + stopPoints);
    return stopPoints;
  }

  // For debugging purposes, we can skip to a specified waypoint and start there instead of waypoint 0
  public getStartWaypointId(): number {
    // console.log("Start Waypoint ID: " + WaypointManager.startWaypointId);
    return WaypointManager.startWaypointId;
  }

  // Returns a value between 0 and 1 as distance between two waypoints on the path
  public getDistanceBetweenWaypoints(
    firstWaypoint: Waypoint,
    secondWaypoint: Waypoint
  ): number {
    // If the waypoint positions haven't already been converted to points on the path, do it now
    if (!firstWaypoint.pointOnPath || !secondWaypoint.pointOnPath) {
      let path = GuiManager.getInstance().isMobileOrTablet()
        ? WaypointManager.pathMobile
        : WaypointManager.pathDesktop;

      firstWaypoint.pointOnPath = path.getClosestPositionTo(
        firstWaypoint.position
      );
      secondWaypoint.pointOnPath = path.getClosestPositionTo(
        secondWaypoint.position
      );
    }

    return this.getDistanceBetweenPointsOnPath(
      firstWaypoint.pointOnPath,
      secondWaypoint.pointOnPath
    );
  }

  // Returns a value between 0 and 1 as distance between two virtual points on the path
  public getDistanceBetweenPointsOnPath(
    firstPoint: number,
    secondPoint: number
  ): number {
    return Math.abs(firstPoint - secondPoint);
  }

  // Check if there is a waypoint at the given point on the path. Returns -1 if the position does not correspond with any waypoint.
  // Comparing the Vector3 position of the camera with a waypoint doesn't work because the camera position is usually a tiny bit off.
  public findWaypointAtPointOnPath(pointOnPath: number): number {
    let waypointIndex: number = -1;
    this.getWaypoints().forEach((waypoint, index) => {
      if (pointOnPath === waypoint.pointOnPath) {
        waypointIndex = index;
      }
    });

    return waypointIndex;
  }

  // Find next stop point on the path, be it a virtual point or a waypoint
  public findNextStopPoint(pointOnPath: number, movingDown: boolean): number {
    let stopPoints: number[] = GuiManager.getInstance().isMobileOrTablet()
      ? WaypointManager.stopPointsMobile
      : WaypointManager.stopPointsDesktop;

    let nextStopPoint: number = -1;
    stopPoints.forEach((stopPoint, index) => {
      if (stopPoint === pointOnPath) {
        nextStopPoint = movingDown
          ? stopPoints[index + 1]
          : stopPoints[index - 1];
      }
    });

    return nextStopPoint;
  }

  public isLastWaypoint(): boolean {
    return (
      WaypointManager.waypointTracker.currentWaypointId ===
      this.getWaypoints().length - 1
    );
  }

  public isFirstWaypoint(): boolean {
    return WaypointManager.waypointTracker.currentWaypointId === 0;
  }

  // Jump to another waypoint in the scene
  public jumpToWaypoint(targetWaypointId: number) {
    if (targetWaypointId === -1) {
      console.warn("[WaypointManager] Cannot jump to waypoint. ID is invalid.");
      return;
    }

    // We're already at the right waypoint
    if (
      targetWaypointId === WaypointManager.waypointTracker.currentWaypointId
    ) {
      return;
    }

    let targetPointOnPath = this.getWaypoints()[targetWaypointId].pointOnPath;

    WaypointManager.waypointTracker.currentWaypointId < targetWaypointId
      ? ScrollCamera.getInstance().moveCameraToPoint(
          1,
          targetPointOnPath,
          targetWaypointId
        )
      : ScrollCamera.getInstance().moveCameraToPoint(
          -1,
          targetPointOnPath,
          targetWaypointId
        );
  }
}
