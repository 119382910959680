import {
  HardwareScalingOptimization,
  LensFlaresOptimization,
  MergeMeshesOptimization,
  ParticlesOptimization,
  PostProcessesOptimization,
  RenderTargetsOptimization,
  SceneOptimizer,
  SceneOptimizerOptions,
  ShadowsOptimization,
  TextureOptimization,
} from "@babylonjs/core";
import { Engine } from "@babylonjs/core/Engines/engine";
import { BabylonFileLoaderConfiguration } from "@babylonjs/core/Loading/Plugins/babylonFileLoader";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Scene, SceneOptions } from "@babylonjs/core/scene";
import { GUI3DManager } from "@babylonjs/gui";
import "@babylonjs/materials";

import * as CANNON from "cannon";
import { CustomLoadingScreen } from "./CustomLoadingScreen";
import GameManager from "./scenes/GameManager/game-manager";
import GuiManager from "./scenes/GameManager/gui-manager";

import { runScene } from "./scenes/tools";

export class Game {
  /**
   * Defines the engine used to draw the game using Babylon.JS and WebGL.
   */
  public engine: Engine;
  /**
   * Defines the scene used to store and draw elements in the canvas.
   */
  public scene: Scene;

  public loadingScreen: CustomLoadingScreen;

  public optimizer: SceneOptimizer;

  public paused: boolean = false;

  public localBuild: boolean = false;

  /**
   * Constructor.
   */
  public constructor(
    private loadingCircle: SVGCircleElement,
    private percentLoaded: HTMLElement,
    private loader: HTMLElement
  ) {
    // console.log("[index.ts] mode is " + process.env.NODE_ENV);

    // Adjust styling of cookie consent banner because for some reason it's cut off on the right side on mobile
    setTimeout(() => {
      if (
        document.getElementById("onetrust-banner-sdk") !== null &&
        GuiManager.getInstance().isMobile()
      ) {
        document.getElementById("onetrust-banner-sdk").style.width =
          "calc(100% - 16px - 16px)";
      }
    }, 500);

    // Layout for tablet in portrait
    if (
      GuiManager.getInstance().isTablet() &&
      GuiManager.getInstance().orientationIsPortrait()
    ) {
      document
        .getElementById("loaderBlock")
        .classList.add("story-block-tablet");
      document.getElementById("loaderBlock").classList.remove("story-block");
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-mobile");
      document.getElementById("startNotice").classList.remove("font-copy");
    }
    // Layout for smartphones in portrait
    else if (
      GuiManager.getInstance().isMobile() &&
      GuiManager.getInstance().orientationIsPortrait()
    ) {
      document
        .getElementById("loaderBlock")
        .classList.add("story-block-mobile");
      document.getElementById("loaderBlock").classList.remove("story-block");
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-tablet");
      document.getElementById("startNotice").classList.add("font-copy");
    }
    // Layout for smartphones in landscape
    else if (
      GuiManager.getInstance().isMobile() &&
      !GuiManager.getInstance().orientationIsPortrait()
    ) {
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-mobile");
      document.getElementById("loaderBlock").classList.add("story-block");
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-tablet");
      document.getElementById("startNotice").classList.add("font-copy");
    }
    // Layout for desktop and tablets in landscape
    else {
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-mobile");
      document.getElementById("loaderBlock").classList.add("story-block");
      document
        .getElementById("loaderBlock")
        .classList.remove("story-block-tablet");
      document.getElementById("startNotice").classList.remove("font-copy");
    }

    document
      .getElementById("loadExperience")
      .addEventListener("click", (e: Event) => this._loadEngine());

    // Set different layouts if the device is rotated
    window.addEventListener("resize", async () => {
      // Layout for tablet in portrait
      if (
        GuiManager.getInstance().isTablet() &&
        GuiManager.getInstance().orientationIsPortrait()
      ) {
        document
          .getElementById("loaderBlock")
          .classList.add("story-block-tablet");
        document.getElementById("loaderBlock").classList.remove("story-block");
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-mobile");
        document.getElementById("startNotice").classList.remove("font-copy");
      }
      // Layout for smartphones in portrait
      else if (
        GuiManager.getInstance().isMobile() &&
        GuiManager.getInstance().orientationIsPortrait()
      ) {
        document
          .getElementById("loaderBlock")
          .classList.add("story-block-mobile");
        document.getElementById("loaderBlock").classList.remove("story-block");
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-tablet");
        document.getElementById("startNotice").classList.add("font-copy");
      }
      // Layout for smartphones in landscape
      else if (
        GuiManager.getInstance().isMobile() &&
        !GuiManager.getInstance().orientationIsPortrait()
      ) {
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-mobile");
        document.getElementById("loaderBlock").classList.add("story-block");
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-tablet");
        document.getElementById("startNotice").classList.add("font-copy");
      }
      // Layout for desktop and tablets in landscape
      else {
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-mobile");
        document.getElementById("loaderBlock").classList.add("story-block");
        document
          .getElementById("loaderBlock")
          .classList.remove("story-block-tablet");
        document.getElementById("startNotice").classList.remove("font-copy");
      }

      // Adjust styling of cookie consent banner because for some reason it's cut off on the right side on mobile
      if (
        document.getElementById("onetrust-banner-sdk") !== null &&
        GuiManager.getInstance().isMobile()
      ) {
        document.getElementById("onetrust-banner-sdk").style.width =
          "calc(100% - 16px - 16px)";
      }
    });
  }

  private _loadEngine(): void {
    document.getElementById("circleLoader").style.display = "flex";
    document.getElementById("loadExperienceDiv").style.display = "none";
    this.engine = new Engine(
      document.getElementById("renderCanvas") as HTMLCanvasElement,
      true,
      { doNotHandleContextLost: true }
    );
    this.engine.enableOfflineSupport = false;

    this.loadingCircle = document.querySelector("circle");
    this.percentLoaded = document.getElementById("percentLoaded");
    this.loader = document.getElementById("loader");

    this.loadingScreen = new CustomLoadingScreen(
      this.loadingCircle,
      this.percentLoaded,
      this.loader
    );
    this.engine.loadingScreen = this.loadingScreen;

    let sceneOptions = {
      useGeometryUniqueIdsMap: true,
      useMaterialMeshMap: false,
      useClonedMeshMap: false,
      virtual: false,
    };
    this.scene = new Scene(this.engine, sceneOptions);

    this._bindEvents();
    this._loadScene();
  }

  /**
   * Loads the first scene.
   */
  private async _loadScene(): Promise<void> {
    this.engine.displayLoadingUI();
    const rootUrl = "./assets/";
    BabylonFileLoaderConfiguration.LoaderInjectedPhysicsEngine = null; // CANNON;

    // For local testing
    if (this.localBuild) {
      await SceneLoader.AppendAsync(
        rootUrl,
        "../scenes/scene/scene.babylon",
        this.scene,
        (evt) => {
          const loadStatus = ((evt.loaded * 100) / evt.total).toFixed();
          this.loadingScreen.updateLoadStatus(loadStatus);
        },
        ".babylon"
      );
    } else {
      await SceneLoader.AppendAsync(
        rootUrl,
        "../scenes/scene/scene.incremental.babylon",
        this.scene,
        (evt) => {
          const loadStatus = ((evt.loaded * 100) / evt.total).toFixed();
          this.loadingScreen.updateLoadStatus(loadStatus);
        },
        ".babylon"
      );
    }

    // apply optimizations
    // this.scene.performancePriority = ScenePerformancePriority.Aggressive;
    this.optimizer = new SceneOptimizer(
      this.scene,
      this._optimizationOptions(30),
      false
    );
    this.optimizer.start();

    // remove physics engine (if available)
    this.scene.disablePhysicsEngine();

    // Attach camera.
    if (!this.scene.activeCamera) {
      throw new Error(
        "No camera defined in the scene. Please add at least one camera in the project or create one yourself in the code."
      );
    }

    this.scene.activeCamera.attachControl(
      this.engine.getRenderingCanvas(),
      false
    );

    // Render.
    this.engine.runRenderLoop(() => {
      if (!GameManager.getInstance().isPaused()) {
        this.scene.render();
      }
    });

    return new Promise<void>((resolve) => {
      this.scene.executeWhenReady(() => {
        runScene(this.scene, rootUrl);
        resolve();
      });
    });
  }

  /**
   * Binds the required events for a full experience.
   */
  private _bindEvents(): void {
    window.addEventListener("resize", () => this.engine.resize());
  }

  /**
   * Creates the default SceneOptimizerOptions to be used with this project
   *
   * @param targetFrameRate — defines the target frame rate to reach (60 by default)
   *
   * @returns SceneOptimizerOptions with sane defaults
   */
  private _optimizationOptions(
    targetFrameRate?: number
  ): SceneOptimizerOptions {
    var result = new SceneOptimizerOptions(targetFrameRate, 2000);

    let priority = 0;

    // basic scaling-> degrades 3D scene too much
    // result.addOptimization(new HardwareScalingOptimization(priority, 1, 0.75));

    priority++;
    // aggressive texture scaling -> brakes the light
    // result.addOptimization(new TextureOptimization(priority, 128));

    // more optimizations
    priority++;
    result.addOptimization(new MergeMeshesOptimization(priority));
    result.addOptimization(new ShadowsOptimization(priority));
    result.addOptimization(new LensFlaresOptimization(priority));

    priority++;
    result.addOptimization(new PostProcessesOptimization(priority));
    result.addOptimization(new ParticlesOptimization(priority));

    priority++;
    result.addOptimization(new RenderTargetsOptimization(priority));

    // more aggressive scaling -> degrades 3D scene too much
    priority++;
    // result.addOptimization(new HardwareScalingOptimization(priority, 2, 1));

    return result;
  }
}
