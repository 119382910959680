import WaypointManager from "./waypoint-manager";

declare const ATInternet: any;
declare const iom: any;

export default class TrackingManager {
  private static instance: TrackingManager;

  private static readonly PAGE_SECTION: string = "10_Serien";
  private static readonly PAGE_SUBSECTION: string = "TerraX Tiefsee-Tauchfahrt";
  private static readonly PAGE_TYPE: string = "Microsite";
  private static readonly PAGE_URL: string = "https://tiefseetauchfahrt.zdf.de";

  private static readonly ONSITEAD_TYPE: string = "Self promotion";
  private static readonly ONSITEAD_ADVERTISER: string = "ZDF";

  public static getInstance(): TrackingManager {
    if (!TrackingManager.instance)
      TrackingManager.instance = new TrackingManager();

    return TrackingManager.instance;
  }

  protected constructor() {}

  private static _trackingConsent: boolean = false;
  private static _atiSite: number;

  public getTrackingConsent(): boolean {
    return TrackingManager._trackingConsent;
  }

  private sendSZM(data: SZMData, retries: number) {
    try {
      // console.log("[TrackingManager] Trying to send szm tracking stub", data);
      iom.h(data, 1);
    } catch (e) {
      if (retries > 0) {
        setTimeout(() => {
          this.sendSZM(data, retries - 1);
        }, 250);
      }
    }
  }

  public onInitialized(): void {
    TrackingManager._trackingConsent = (
      window as any
    ).zdfcmp.hasTrackingConsent();

    TrackingManager._atiSite =
      process.env.NODE_ENV == "production" ? 569006 : 575802;

    if (TrackingManager._trackingConsent) {
      // We start on the start screen but due to race conditions it's easier to track this here
      TrackingManager.getInstance().pageDisplay(PageTitle.STARTPAGE);

      // SZM Tracking
      let data: SZMData = {
        st: "zdf",
        cp: "Doku/Terra_X/",
        co: "Microsite/Tiefseetauchfahrt/",
        sv: "ke",
        cn: "de",
        sc: "yes",
      };
      this.sendSZM(data, 5);
    }

    (window as any).zdfcmp.addConsentChangedCallback(() => {
      // console.log("[TrackingManager] Consent changed");
      TrackingManager._trackingConsent = (
        window as any
      ).zdfcmp.hasTrackingConsent();

      // console.log(
      //   "[TrackingManager] Tracking consent changed to " +
      //     TrackingManager._trackingConsent
      // );
    });
  }

  private initTag(): any {
    return new ATInternet.Tracker.Tag({
      site: TrackingManager._atiSite,
      collectDomain: "https://logc407.xiti.com",
    });
  }

  public clickAction(
    pageTitle: string,
    click: string,
    targetTitle: string
  ): void {
    if (!TrackingManager._trackingConsent) return;

    // console.log("[TrackingManager] click.action");

    let tag = this.initTag();
    tag.event.send("click.action", {
      target_title: targetTitle,
      click: targetTitle,
      function: click,
      page_pos: TrackingManager.getInstance().getRelativeWPPos(),

      page: pageTitle,
      page_title: pageTitle,
      page_url: TrackingManager.PAGE_URL,
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,
    });
  }

  public clickNavigation(
    pageTitle: string,
    click: string,
    targetTitle: string
  ): void {
    if (!TrackingManager._trackingConsent) return;

    // console.log(
    //   "[TrackingManager] click.navigation " +
    //     pageTitle +
    //     " " +
    //     click +
    //     " " +
    //     targetTitle
    // );

    let tag = this.initTag();
    tag.event.send("click.navigation", {
      target_title: targetTitle,
      click: targetTitle,
      function: click,
      page_pos: TrackingManager.getInstance().getRelativeWPPos(),

      page: pageTitle,
      page_title: pageTitle,
      page_url: TrackingManager.PAGE_URL,
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,
    });
  }

  public pageDisplay(pageTitle: string, pageUrl?: string): void {
    if (!TrackingManager._trackingConsent) {
      // console.log("[TrackingManager] Consent not yet there " + pageTitle);
      return;
    }

    // console.log(
    //   "[TrackingManager] pageDisplay " + pageTitle + (pageUrl ? pageUrl : "")
    // );

    let tag = this.initTag();
    tag.event.send("page.display", {
      page: pageTitle,
      page_title: pageTitle,
      page_url: TrackingManager.PAGE_URL + (pageUrl ? pageUrl : ""),
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,
    });
  }

  public scroll(): void {
    if (!TrackingManager._trackingConsent) return;

    // console.log(
    //   "[TrackingManager] scroll " +
    //     TrackingManager.getInstance().getRelativeWPPos()
    // );

    let tag = this.initTag();
    tag.event.send("page.scroll", {
      page: PageTitle.CANVAS,
      page_title: PageTitle.CANVAS,
      page_url: TrackingManager.PAGE_URL,
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,

      page_pos: TrackingManager.getInstance().getRelativeWPPos(),
    });
  }

  public selfPromotionDisplay(
    pageTitle: PageTitle,
    targetTitle: string,
    functionType: FunctionType,
    functionDetail: string
  ): void {
    if (!TrackingManager._trackingConsent) return;

    // console.log(
    //   "[TrackingManager] onsiteAd display " +
    //     pageTitle +
    //     " " +
    //     functionType +
    //     " " +
    //     functionDetail
    // );

    let tag = this.initTag();
    tag.events.send("self_promotion.display", {
      page: pageTitle,
      page_title: pageTitle,
      target_title: targetTitle,
      function: functionType,
      function_detail: functionDetail,

      onsitead_type: TrackingManager.ONSITEAD_TYPE,
      onsitead_advertiser: TrackingManager.ONSITEAD_ADVERTISER,
      page_pos: TrackingManager.getInstance().getRelativeWPPos(),
      page_url: TrackingManager.PAGE_URL,
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,
    });
  }

  public selfPromotionClick(
    pageTitle: PageTitle,
    targetTitle: string,
    functionType: FunctionType,
    functionDetail: string
  ): void {
    if (!TrackingManager._trackingConsent) return;

    // console.log(
    //   "[TrackingManager] onsiteAd click " +
    //     pageTitle +
    //     " " +
    //     functionType +
    //     " " +
    //     functionDetail
    // );

    let tag = this.initTag();
    tag.events.send("self_promotion.click", {
      page: pageTitle,
      page_title: pageTitle,
      target_title: targetTitle,
      function: functionType,
      function_detail: functionDetail,

      onsitead_type: TrackingManager.ONSITEAD_TYPE,
      onsitead_advertiser: TrackingManager.ONSITEAD_ADVERTISER,
      page_pos: TrackingManager.getInstance().getRelativeWPPos(),
      page_url: TrackingManager.PAGE_URL,
      page_section: TrackingManager.PAGE_SECTION,
      site_level2: TrackingManager.PAGE_SECTION,
      page_subsection: TrackingManager.PAGE_SUBSECTION,
      page_chapter1: TrackingManager.PAGE_SUBSECTION,
      page_type: TrackingManager.PAGE_TYPE,
      page_chapter2: TrackingManager.PAGE_TYPE,
    });
  }

  private getRelativeWPPos(): number {
    return (
      (WaypointManager.waypointTracker.currentWaypointId + 1) /
      WaypointManager.getInstance().getWaypoints().length
    );
  }
}

export enum PageTitle {
  STARTPAGE = "Startseite",
  CANVAS = "Spielseite",
  INVENTORY = "Meine Meerestiere",

  WHALE_01 = "Wiesen-unter-Wasser",
  WHALE_02 = "Der-einzige-einheimische-Wal",

  ANTIPOLLUTION_01 = "Parasiten",
  ANTIPOLLUTION_02 = "Ozeane-als-Klimaschützer",
  ANTIPOLLUTION_03 = "Putzerfische",
  ANTIPOLLUTION_04 = "Karettschildkröte",

  PLANKTON_01 = "Plankton",
  PLANKTON_02 = "Planktonwanderung",

  HABITAT_OCEAN_01 = "Geisternetze",
  HABITAT_OCEAN_02 = "Meeresschnee",
  HABITAT_OCEAN_03 = "Extremlebensraum-Tiefsee", //

  SPECIAL_ANIMALS_01 = "Quallen-wahre-Wunderwesen",
  SPECIAL_ANIMALS_02 = "Langschwänziger-Laternenfisch",
  SPECIAL_ANIMALS_03 = "Leuchtende-Tiere",

  SEA_BOTTOM_01 = "Leben-auf-dem-Meeresboden", //
  SEA_BOTTOM_02 = "Seegurken-Tiere-kein-Meeres-Gemüse",
  SEA_BOTTOM_03 = "Die-Riesenassel",

  TRASH_IN_THE_OCEAN_01 = "Überall-Plastik",
  TRASH_IN_THE_OCEAN_02 = "Müll-im-Meer",
  TRASH_IN_THE_OCEAN_03 = "Lärm-im-Meer",

  QUIZ_01 = "Quiz_01",
  QUIZ_02 = "Quiz_02",
  QUIZ_03 = "Quiz_03",
  QUIZ_04 = "Quiz_04",
  QUIZ_05 = "Quiz_05",
  QUIZ_06 = "Quiz_06",
}

export enum FunctionDetail {
  WHALE_01 = "Wiesen-unter-Wasser",
  WHALE_02 = "Der-einzige-einheimische-Wal",

  ANTIPOLLUTION_01 = "Parasiten",
  ANTIPOLLUTION_02 = "Ozeane-als-Klimaschützer",
  ANTIPOLLUTION_03 = "Putzerfische",
  ANTIPOLLUTION_04 = "Karettschildkröte",

  PLANKTON_01 = "Plankton",
  PLANKTON_02 = "Planktonwanderung",

  HABITAT_OCEAN_01 = "Geisternetze",
  HABITAT_OCEAN_02 = "Meeresschnee",
  HABITAT_OCEAN_03 = "Extremlebensraum-Tiefsee", //

  SPECIAL_ANIMALS_01 = "Quallen-wahre-Wunderwesen",
  SPECIAL_ANIMALS_02 = "Langschwänziger-Laternenfisch",
  SPECIAL_ANIMALS_03 = "Leuchtende-Tiere",

  SEA_BOTTOM_01 = "Leben-auf-dem-Meeresboden", //
  SEA_BOTTOM_02 = "Seegurken-Tiere-kein-Meeres-Gemüse",
  SEA_BOTTOM_03 = "Die-Riesenassel",

  TRASH_IN_THE_OCEAN_01 = "Überall-Plastik",
  TRASH_IN_THE_OCEAN_02 = "Müll-im-Meer",
  TRASH_IN_THE_OCEAN_03 = "Lärm-im-Meer",

  QUIZ_01_QUESTION_01 = "Quiz-01-Frage-01",
  QUIZ_01_QUESTION_02 = "Quiz-01-Frage-02",
  QUIZ_01_QUESTION_03 = "Quiz-01-Frage-03",
  QUIZ_01_NEXT_QUESTION = "Quiz-01-nächste-Frage",
  QUIZ_01_SEE_ANIMAL = "Quiz-01-Tier-erleben",

  QUIZ_02_QUESTION_01 = "Quiz-02-Frage-01",
  QUIZ_02_QUESTION_02 = "Quiz-02-Frage-02",
  QUIZ_02_QUESTION_03 = "Quiz-02-Frage-03",
  QUIZ_02_NEXT_QUESTION = "Quiz-02-nächste-Frage",
  QUIZ_02_SEE_ANIMAL = "Quiz-02-Tier-erleben",

  QUIZ_03_QUESTION_01 = "Quiz-03-Frage-01",
  QUIZ_03_QUESTION_02 = "Quiz-03-Frage-02",
  QUIZ_03_QUESTION_03 = "Quiz-03-Frage-03",
  QUIZ_03_NEXT_QUESTION = "Quiz-03-nächste-Frage",
  QUIZ_03_SEE_ANIMAL = "Quiz-03-Tier-erleben",

  QUIZ_04_QUESTION_01 = "Quiz-04-Frage-01",
  QUIZ_04_QUESTION_02 = "Quiz-04-Frage-02",
  QUIZ_04_QUESTION_03 = "Quiz-04-Frage-03",
  QUIZ_04_NEXT_QUESTION = "Quiz-04-nächste-Frage",
  QUIZ_04_SEE_ANIMAL = "Quiz-04-Tier-erleben",

  QUIZ_05_QUESTION_01 = "Quiz-05-Frage-01",
  QUIZ_05_QUESTION_02 = "Quiz-05-Frage-02",
  QUIZ_05_QUESTION_03 = "Quiz-05-Frage-03",
  QUIZ_05_NEXT_QUESTION = "Quiz-05-nächste-Frage",
  QUIZ_05_SEE_ANIMAL = "Quiz-05-Tier-erleben",

  QUIZ_06_QUESTION_01 = "Quiz-06-Frage-01",
  QUIZ_06_QUESTION_02 = "Quiz-06-Frage-02",
  QUIZ_06_QUESTION_03 = "Quiz-06-Frage-03",
  QUIZ_06_NEXT_QUESTION = "Quiz-06-nächste-Frage",
  QUIZ_06_SEE_ANIMAL = "Quiz-06-Tier-erleben",
}

export enum FunctionType {
  QUIZ = "Quiz",
  THEME = "Themenwelt",
}

export class SZMData {
  st: string;
  cp: string;
  co: string;
  sv: string;
  cn: string;
  sc: string;
}
