import GuiManager from "./gui-manager";
import { Model } from "./inventory";
import TrackingManager from "./tracking-manager";

export default class ModelViewer {
  public openModelViewer(model: Model) {
    let selectedAnimal: string;
    let trackingTitle: string;
    switch (model) {
      case 0:
        selectedAnimal = "porpoise";
        trackingTitle = "Schweinswal";
        break;
      case 1:
        selectedAnimal = "seaturtle";
        trackingTitle = "Karettschildkröte";
        break;
      case 2:
        selectedAnimal = "medaka";
        trackingTitle = "Fischschwarm";
        break;
      case 3:
        selectedAnimal = "jellyfish";
        trackingTitle = "Qualle";
        break;
      case 4:
        selectedAnimal = "anglerfish";
        trackingTitle = "Anglerfisch";
        break;
      case 5:
        selectedAnimal = "barreleye";
        trackingTitle = "Gespensterfisch";
        break;
      default:
        // console.log("[ModelViewer] Using default model");
        selectedAnimal = "porpoise";
        trackingTitle = "Schweinswal";
        break;
    }

    TrackingManager.getInstance().pageDisplay(
      trackingTitle,
      "/model-viewer.html"
    );

    // console.log("OpenModelViewer, selectedAnimal: " + selectedAnimal);
    let modelViewerWindow: Window = window.open("model-viewer.html", "_blank");
    // The property selectedAnimal doesn't explicitly exist in the new window, but you can create it and assign a value like this anyway
    if (modelViewerWindow as any) {
      (modelViewerWindow as any).selectedAnimal = selectedAnimal;
      (modelViewerWindow as any).trackingTitle = trackingTitle;
      (modelViewerWindow as any).trackingConsent =
        TrackingManager.getInstance().getTrackingConsent();
    } else console.warn("[ModelViewer] modelViewerWindow is null");
  }
}
