import ScrollCamera from "../scroll";
import { sleep } from "../utilities";
import AnimationManager from "./animation-manager";
import AudioManager from "./audio-manager";
import { Footer } from "./footer";
import GameManager from "./game-manager";
import GuiManager from "./gui-manager";
import { HotspotOverlay } from "./hotspot-overlay";
import HUD from "./hud";
import Quiz from "./quiz";
import TrackingManager, { PageTitle } from "./tracking-manager";
import WaypointManager from "./waypoint-manager";

export type NarrationInfo = {
  narrationHTML: string;
  animationGroupInfos?: AnimationGroupInfo[];
  movementInfos?: MovementInfo[];
  fishboidNames?: string[];
  spriteSheetAnims?: string[];
  narrationAudioFile?: string;
  atmoAudioFile?: string;
  soundAudioInfo?: SoundInfo;
  waypointId: number;
  pageId?: number;
  quizId?: number;
  hotspotInfos?: HotspotInfo[];
  showFooter?: boolean;
};

export type AnimationGroupInfo = {
  name: string;
  loop: boolean;
};

export type MovementInfo = {
  name: string;
  path?: string[];
  addWP?: AddWP;
};

export type AddWP = {
  wpName: string;
  instantTurn: boolean;
};

export type SoundInfo = {
  fileName: string;
  loop: boolean;
  nonWPSound?: boolean; // nonWPSounds are not stopped on scrolling
};

export type HotspotInfo = {
  hotspotId: number;
  enableHotspot: boolean;
};

export default class Narration {
  private _narrationDesktop: NarrationInfo[] = [
    {
      narrationHTML: `<div class="story-block">
          <div class='logo-block'>
            <img src="./assets/gui/svg/gradient-overlay-back-title.svg" class="title-background">
            <!-- <div style="width: 100%; height: 10px"></div> -->
            <img src="./assets/gui/svg/Title.svg" class="title">
          </div>
          <div class='secondary-button-container narration-secondary-button-container'>
            <button id='startButton' class='secondary-button'>
              REISE BEGINNEN
            </button>
          </div>
        </div>`,
      waypointId: 0,
      pageId: 0,
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line story-line-header">Beginne mit deiner Terra X Tiefseetauchfahrt!</div>
          <div class="story-line">Entdecke Fische mit dolchartigen Zähnen, räuberische Quallen und niedliche Babywale.</div>
          <div style="width: 100%; height: 20px"></div>
          <img src="./assets/gui/svg/Scrolle_Desktop.svg" class="scroll-swipe-hint">
        </div>`,
      waypointId: 1,
      pageId: 1,
      narrationAudioFile: "01_02_Beginne_mit_Entdecke.m4a",
      atmoAudioFile: "Schwarm_LEVEL_1_ATMO_LOOP_KORREKTUR_v2.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Schaffst du es auf deiner Reise 6 geheimnisvolle Meerestiere einzusammeln?</div>
          <div style="width: 100%; height: 10px"></div>
          <div id="teaserBubbles" class="story-line-img">
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
            <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          </div>
          <div style="width: 100%; height: 20px"></div>
          <img src="./assets/gui/svg/Scrolle_Desktop.svg" class="scroll-swipe-hint">
          </div>`,
      waypointId: 1,
      pageId: 2,
      narrationAudioFile: "03_Schaffst_du_es.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Die Meere sind der letzte Ort dieses Planeten, der größtenteils unerforscht ist ...</div>
          <div class="story-line">... und Heimat vieler Tiere, von denen du vermutlich noch nie etwas gehört hast.</div>
        </div>`,
      waypointId: 2,
      pageId: 3,
      narrationAudioFile: "04_05_Die_Meere_und_Heimat.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Kennst du den Schweinswal schon?</div>
          <div class="story-line">Er lebt direkt vor unserer Haustür in der Nord- und Ostsee.</div>
        </div>`,
      waypointId: 2,
      pageId: 4,
      narrationAudioFile: "06_07_Kennst_du_den_Schweinswal_er_lebt.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_1-2",
          path: ["02_PM_WP_02"],
        },
        {
          name: "Porpoise_Child_Scene_1-2",
          path: ["02_PC_WP_02"],
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 0,
          enableHotspot: false,
        },
        {
          hotspotId: 1,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 2,
      pageId: 5,
      hotspotInfos: [
        {
          hotspotId: 0,
          enableHotspot: true,
        },
        {
          hotspotId: 1,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Schweinswale leiden mehr und mehr unter menschengemachten Problemen ...</div>`,
      waypointId: 3,
      pageId: 6,
      narrationAudioFile: "08_Die_Schweinswale.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_3",
          path: ["03_PM_WP_02"],
        },
        {
          name: "Porpoise_Child_Scene_3",
          path: ["03_PC_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Der Schall von Schiffsschrauben oder U-Booten irritiert ihr feines Gehör.</div>
          <div class="story-line">Unter Wasser breitet sich Schall vier bis fünfmal schneller aus als an Land und selbst weit entfernte Schiffe werden so zur Störung.</div>
        </div>`,
      waypointId: 3,
      pageId: 7,
      narrationAudioFile: "09_10_Der_Schall_Unterwasser.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Schiffschraube.m4a",
        loop: false,
      },
      animationGroupInfos: [
        {
          name: "RippleEffect",
          loop: true,
        },
      ],
      spriteSheetAnims: ["Ripple_Sheet_1", "Ripple_Sheet_2", "Ripple_Sheet_3"],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ist der Schall zu laut, können sich die Tiere so sehr erschrecken, dass Mutter und Kalb getrennt werden.</div>`,
      waypointId: 3,
      pageId: 8,
      narrationAudioFile: "11_ist_der_Schall_zu_laut.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_3",
          addWP: {
            wpName: "03_PM_WP_03",
            instantTurn: false,
          },
        },
        {
          name: "Porpoise_Child_Scene_3",
          addWP: {
            wpName: "03_PC_WP_03",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
        <div class="story-line">Schweinswale verständigen sich durch Klick- und Pfeiftöne, aber das Meer ist groß.</div>
        <div class="story-line">Ab und an finden sich Mutter und Kalb nicht wieder ...</div>
      </div>`,
      waypointId: 3,
      pageId: 9,
      narrationAudioFile: "12_Schweinswale_verständigen.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Wahl_Calls_v2.m4a",
        loop: false,
      },
      hotspotInfos: [
        {
          hotspotId: 19,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 3,
      pageId: 10,
      hotspotInfos: [
        {
          hotspotId: 19,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 4,
      pageId: 11,
      quizId: 1,
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Endlich wiedergefunden! Das Kalb wird ein paar weitere Monate bei seiner Mutter verbringen, bevor es selbstständig wird.</div>`,
      waypointId: 5,
      pageId: 12,
      movementInfos: [
        {
          name: "Porpoise_Child_Scene_3_02",
          path: ["05_PC_WP_02"],
        },
        {
          name: "Porpoise_Mother_Scene_3_02",
          path: ["05_PM_WP_06"],
        },
      ],
      animationGroupInfos: [
        {
          name: "Porpoise_IDLE",
          loop: true,
        },
      ],
      narrationAudioFile: "13_Endlich_wiedergefunden.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Wahl_Calls_v2.m4a",
        loop: false,
      },
      hotspotInfos: [
        {
          hotspotId: 2,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 6,
      pageId: 13,
      fishboidNames: ["fishboid_scene04"],
      hotspotInfos: [
        {
          hotspotId: 2,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Wir bewegen uns weiter Richtung Atlantik.</div>
          <div class="story-line">Diese Echte Karettschildkröte sieht sehr entspannt aus.</div>
          <div class="story-line">Es scheint, als würde sie ein Bad nehmen, während die Fische sie putzen.</div>
        </div>`,
      waypointId: 7,
      pageId: 14,
      narrationAudioFile: "14_15_16_Wir_bewegen_uns.m4a",
      movementInfos: [
        {
          name: "5_medaka_01_WP01",
          path: ["5_medaka_01_WP02"],
        },
        {
          name: "5_medaka_02_WP01",
          path: ["5_medaka_02_WP02"],
        },
        {
          name: "5_medaka_03_WP01",
          path: ["5_medaka_03_WP02"],
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: false,
        },
        {
          hotspotId: 4,
          enableHotspot: false,
        },
        {
          hotspotId: 5,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 7,
      pageId: 15,
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: true,
        },
        {
          hotspotId: 4,
          enableHotspot: true,
        },
        {
          hotspotId: 5,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wo will sie so plötzlich hin?</div>`,
      waypointId: 7,
      pageId: 16,
      narrationAudioFile: "17_Wo_will_sie.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Underwater_Movement_med.m4a",
        loop: false,
      },
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
      movementInfos: [
        {
          name: "SeaTurtle_Scene_04",
          path: ["07_seaturtle_WP_02"],
        },
        {
          name: "5_medaka_01_WP01",
          addWP: {
            wpName: "5_medaka_01_WP03",
            instantTurn: true,
          },
        },
        {
          name: "5_medaka_02_WP01",
          addWP: {
            wpName: "5_medaka_02_WP03",
            instantTurn: true,
          },
        },
        {
          name: "5_medaka_03_WP01",
          addWP: {
            wpName: "5_medaka_03_WP03",
            instantTurn: true,
          },
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: false,
        },
        {
          hotspotId: 4,
          enableHotspot: false,
        },
        {
          hotspotId: 5,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 8,
      pageId: 17,
      quizId: 2,
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Was ist wohl das Ziel der Schildkröte?</div>
          <div class="story-line">Vielleicht treffen wir sie später noch einmal und finden es heraus ...</div>
        </div>`,
      waypointId: 8,
      pageId: 18,
      narrationAudioFile: "18_19_was_ist_wohl.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Die Dämmerschicht beginnt ab 150 Metern ...</div>
          <div class="story-line">... nur wenige Sonnenstrahlen dringen bis in diese Tiefe ...</div>
        </div>`,
      waypointId: 9,
      pageId: 19,
      narrationAudioFile: "20_21_Die_Dämmerschicht.m4a",
      // fishboidNames: ["09_fishboid_WP1"],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">... trotzdem kommt es jeden Abend zu einem wahren Naturspektakel ...</div>
          <div class="story-line">... der vertikalen Wanderung!</div>
        </div>`,
      waypointId: 9,
      pageId: 20,
      narrationAudioFile: "22_23_Trotzdem_kommt_es.m4a",
      movementInfos: [
        {
          name: "squid",
          path: ["09_scene_5_squid_WP_02"],
        },
        {
          name: "squid_02",
          path: ["09_scene_5_squid_02_WP_02"],
        },
        {
          name: "squid_03",
          path: ["09_scene_5_squid_03_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "Squid",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Meeresbewohner aus tieferen Schichten machen sich auf, um weiter oben nach Nahrung zu suchen.</div>
          <div class="story-line">Es ist die größte, synchronisierte Wanderung von Tieren und Kleinstlebewesen auf diesem Planeten.</div>
        </div>`,
      waypointId: 9,
      pageId: 21,
      narrationAudioFile: "24_25_Meeresbewohner.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Auch wir Menschen machen uns die vertikale Wanderung zu Nutze. Das Abfischen durch Schleppnetze ist in dieser Zeit besonders ergiebig.</div>`,
      waypointId: 9,
      pageId: 22,
      narrationAudioFile: "26_Auch_wir.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Underwater_Movement_heavy.m4a",
        loop: false,
      },
      movementInfos: [
        {
          name: "fishnet_scene5",
          path: ["09_fishnet_WP_02"],
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 9,
      pageId: 23,
      quizId: 3,
      hotspotInfos: [
        {
          hotspotId: 6,
          enableHotspot: false,
        },
        {
          hotspotId: 7,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 9,
      pageId: 24,
      hotspotInfos: [
        {
          hotspotId: 6,
          enableHotspot: true,
        },
        {
          hotspotId: 7,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Da ist ja unsere Echte Karettschildkröte wieder!</div>`,
      waypointId: 10,
      pageId: 25,
      narrationAudioFile: "27_Da_ist_ja.m4a",
      movementInfos: [
        {
          name: "SeaTurtle_Scene_06",
          path: ["10_seaturtle_WP_02"],
        },
        {
          name: "turtlenet_scene_06",
          path: ["10_turtlenet_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wusstest du, dass etwa 10% des Mülls in europäischen Gewässern Geisternetze sind, die herrenlos herumtreiben?</div>`,
      waypointId: 10,
      pageId: 26,
      narrationAudioFile: "28_Wusstest_du.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Schwimm schneller, Schildkröte! Fehlt dir etwa die Kraft?</div>`,
      waypointId: 10,
      pageId: 27,
      narrationAudioFile: "29_Schwimm_schneller.m4a",
      movementInfos: [
        {
          name: "SeaTurtle_Scene_06",
          addWP: {
            wpName: "10_seaturtle_WP_03",
            instantTurn: false,
          },
        },
        {
          name: "turtlenet_scene_06",
          addWP: {
            wpName: "10_turtlenet_WP_03",
            instantTurn: false,
          },
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 8,
          enableHotspot: false,
        },
      ],
    },
    // {
    //   narrationHTML: `<div class="story-block fade-in">
    //       <div class="story-line">Du schaffst das, befreie dich!</div>
    //       <div class="story-line">... sonst bedeutet es den Tod durch Ertrinken ...</div>
    //     </div>`,
    //   waypointId: 10,
    //   narrationAudioFile: "30_31_Du_schaffst.m4a",
    // },
    {
      narrationHTML: "",
      waypointId: 10,
      pageId: 28,
      hotspotInfos: [
        {
          hotspotId: 8,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Immerhin ... aber wer hilft ihr nun das Seil abzuschütteln?</div>`,
      waypointId: 11, //10a
      atmoAudioFile: "Schwarm_LEVEL_1_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "32_Immerhin.m4a",
      movementInfos: [
        {
          name: "10a_SeaTurtle_Scene_06",
          path: ["10a_seaturtle_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Schildkröte scheint trächtig zu sein ...</div>`,
      waypointId: 11, //10a
      narrationAudioFile: "33_Die_Schildkröte.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... hoffen wir, dass sie genug Kraft hat, um trotz des Seils ihre Nistkolonie zu erreichen.</div>`,
      waypointId: 11, //10a
      narrationAudioFile: "34_Hoffen_wir.m4a",
      movementInfos: [
        {
          name: "10a_SeaTurtle_Scene_06",
          addWP: {
            wpName: "10a_seaturtle_WP_03",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Ab 200 Metern gibt es kein pflanzliches Leben mehr ...</div>
          <div class="story-line">... und es wird immer dämmriger. Wer hier überleben will, muss von der Natur ganz besonders ausgestattet sein.</div>
        </div>`,
      waypointId: 11 + 1,
      atmoAudioFile: "Schwarm_LEVEL_2_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "35_26_Ab_200.m4a",
    },
    {
      // Audiomismatch fixed
      narrationHTML: `<div class="story-block story-line fade-in">Der Gespensterfisch rotiert seine Linsen in alle Richtungen und kann so auch über sich Nahrung erspähen.</div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "27_der_Gespensterfisch.m4a",
      movementInfos: [
        {
          name: "Barrelleye_Scene_07",
          path: ["12_barreleye_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Ein großes Problem ist Mikroplastik, das sich wie Schnee durchs Wasser bewegt.</div>
          <div class="story-line">Was ist essbar, was nicht?</div>
          <div class="story-line">Viele Fische erkennen den Unterschied nicht.</div>
        </div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "28_29_Ein_großes_Problem.m4a",
      // animationGroupInfos: [
      //   {
      //     name: "Barreleye_IDLE",
      //     loop: true,
      //   },
      // ],
      hotspotInfos: [
        {
          hotspotId: 9,
          enableHotspot: false,
        },
        {
          hotspotId: 11,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 13,
      hotspotInfos: [
        {
          hotspotId: 9,
          enableHotspot: true,
        },
        {
          hotspotId: 11,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eines der ältesten und absonderlichsten Tiere der Erdgeschichte ist die Qualle.</div>`,
      waypointId: 13 + 1,
      atmoAudioFile: "Schwarm_LEVEL_2_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "30_Eines_der_aeltesten.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Auch wenn sie zart wirkt und weder Zähne noch Klauen besitzt, ist sie ein gefährlicher und gefräßiger Räuber.</div>`,
      waypointId: 13 + 1,
      narrationAudioFile: "31_auch_wenn.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Manche Arten haben meterlange giftige Tentakel, die Fische bei Berührung lähmen und töten.</div>
          <div class="story-line">Andere besitzen Klebetentakel, an denen die Beute hängen bleibt.</div>
        </div>`,
      waypointId: 13 + 1,
      narrationAudioFile: "32_33_Manche_Arten.m4a",
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 14,
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 13 + 1,
      quizId: 4,
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Ab 1000 Metern herrscht tiefe Finsternis ...</div>
          <div class="story-line">... Gefahren können hier überall lauern ...</div>
          <div class="story-line">... wer ist Freund und wer Feind? In der Dunkelheit ist das schwer zu erkennen ...</div>
        </div>`,
      waypointId: 14 + 1,
      atmoAudioFile: "Schwarm_LEVEL_3_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "34_36_37_Ab_1000.m4a",
      movementInfos: [
        {
          name: "Lanternfish_02_Scene_09",
          path: ["14_Lanternfish_02_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Der Laternenfisch leuchtet, um einen Partner anzulocken ...</div>
          <div class="story-line">... denn in der ewigen Dunkelheit ist es schwierig sich zu finden.</div>
        </div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "37_38_Der_Laternenfisch.m4a",
      movementInfos: [
        {
          name: "Lanternfish_01_Scene_09",
          path: ["14_Lanternfish_01_WP_01"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Könnte das Leuchten in der Ferne ein anderer Laternenfisch sein?</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "39_Koennte_das.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... oder ist es womöglich ein gefährlicher Jäger?</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "40_oder_ist_es.m4a",
      movementInfos: [
        {
          name: "Lanternfish_01_Scene_09",
          addWP: {
            wpName: "14_Lanternfish_01_WP_02",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Der Laternenfisch hat tatsächlich ein Weibchen gefunden!</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "42_Der_Laternenfisch_hat.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wenn die beiden sich paaren, wird es bald unglaubliche 800–900 Eier legen.</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "43_Wenn_die_beiden_sich.m4a",
      hotspotInfos: [
        {
          hotspotId: 12,
          enableHotspot: false,
        },
        {
          hotspotId: 13,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 15,
      hotspotInfos: [
        {
          hotspotId: 12,
          enableHotspot: true,
        },
        {
          hotspotId: 13,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 15 + 1,
      movementInfos: [
        {
          name: "Scene_10_Anglerfish",
          path: ["10_Anglerfish_WP_02"],
        },
      ],
      quizId: 5,
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Wir nähern uns dem Meeresboden, einem kaum erforschten Gebiet.</div>
          <div class="story-line">Von den etwa 300 Millionen Quadratkilometern kennen wir bisher nur 5%.</div>
        </div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "44_45_Wir_naehern_uns_v2.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Hast du dir schon einmal darüber Gedanken gemacht, wie der Meeresboden aussehen könnte?</div>
          <div class="story-line">Wie eine Wüste? Karg und schlammig?</div>
        </div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "46_47_Hast_du_dir_schon.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Große Teile des Meeresbodens wimmeln vor Leben. Möglicherweise gibt es hier unten Millionen von Arten, die wir bisher noch nicht entdeckt haben!</div>`,
      waypointId: 17 + 1,
      narrationAudioFile: "48_große_Teile.m4a",
      hotspotInfos: [
        {
          hotspotId: 14,
          enableHotspot: false,
        },
        {
          hotspotId: 15,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 18,
      hotspotInfos: [
        {
          hotspotId: 14,
          enableHotspot: true,
        },
        {
          hotspotId: 15,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eine Riesenassel ist auf der Suche nach Nahrung.</div>`,
      waypointId: 18 + 1,
      narrationAudioFile: "49_eine_Riesenassel.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Riesenassel_Tap_Scratch_2.m4a",
        loop: true,
      },
      animationGroupInfos: [
        {
          name: "Isopode_Anim",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Sie wächst ihr Leben lang und ist mit 45 Zentimetern eine der größten Asseln der Welt.</div>`,
      waypointId: 18 + 1,
      narrationAudioFile: "50_Sie_waechst.m4a",
      hotspotInfos: [
        {
          hotspotId: 16,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 19,
      hotspotInfos: [
        {
          hotspotId: 16,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eine sehr ergiebige Nahrungsquelle für Riesenasseln, Würmer, Krebse und Aale sind Walkadaver.</div>`,
      waypointId: 19 + 1,
      narrationAudioFile: "51_Eine_sehr.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Etwa 69.000 Wale sterben jedes Jahr, von denen 90% zu Boden sinken.</div>
          <div class="story-line">Ein Kadaver kann Jahrzehntelang als Nahrungsquelle dienen.</div>
        </div>`,
      waypointId: 19 + 1,
      narrationAudioFile: "52_53_Etwa_69000.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Außerdem sinken tote Tiere, Pflanzenreste und Einzeller auf den Meeresboden und ernähren die Bodenbewohner.</div>`,
      waypointId: 20 + 1,
      narrationAudioFile: "54_Außerdem_sinken.m4a",
      movementInfos: [
        {
          name: "Barrelleye_Scene_12",
          path: ["20_Barrelleye_WP_02", "20_Barrelleye_WP_03"],
        },
      ],
    },
    {
      // Audio mismatch fixed
      narrationHTML: `<div class="story-block story-line fade-in">Auch der tote Gespensterfisch.</div>`,
      waypointId: 20 + 1,
      narrationAudioFile: "55_auch_der_tote.m4a",
    },
    {
      // Audio mismatch fixed
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line">Und auch Plastikmüll sinkt bis hier unten hinab.</div>
          <div class="story-line">Wie viel schon am Meeresboden liegt, weiß man nicht.</div>
        </div>`,
      waypointId: 21 + 1,
      narrationAudioFile: "56_48_und_auch_Plastikmuell.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Es ist gut möglich, dass wir mit unserem Müll Ökosysteme zerstören und Meerestiere ausrotten, bevor wir sie jemals zu Gesicht bekommen haben.</div>`,
      waypointId: 22 + 1,
      narrationAudioFile: "48_es_ist_gut_moeglich.m4a",
    },
    {
      narrationHTML: "",
      waypointId: 22 + 1,
      quizId: 6,
      hotspotInfos: [
        {
          hotspotId: 17,
          enableHotspot: false,
        },
        {
          hotspotId: 18,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 22 + 1,
      hotspotInfos: [
        {
          hotspotId: 17,
          enableHotspot: true,
        },
        {
          hotspotId: 18,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line story-line-end">Du bist am Ende deiner Tauchfahrt angelangt</div>
          <div class="story-line">Hast du alle AR Tiere entdeckt?</div>
          <div class='primary-button-container narration-primary-button-container'>
        <button id='toTopButton' class='primary-button'>
          TAUCHE WIEDER AUF
        </button>
        </div>
      </div>`,
      waypointId: 24,
      showFooter: false,
    },
    {
      narrationHTML: "",
      waypointId: 24,
      showFooter: true,
    },
  ];

  private _narrationMobile: NarrationInfo[] = [
    {
      narrationHTML: `<div class="story-block">
        <div class='logo-block'>
          <img src="./assets/gui/svg/gradient-overlay-back-title.svg" class="title-background">
          <!-- <div style="width: 100%; height: 10px"></div> -->
          <img src="./assets/gui/svg/Title.svg" class="title">
        </div>
        <div class='secondary-button-container narration-secondary-button-container'>
        <button id='startButton' class='secondary-button'>
          REISE BEGINNEN
        </button>
        </div>
        </div>`,
      waypointId: 0,
    },
    {
      narrationHTML: `<div class="story-block fade-in">
        <div class="story-line story-line-header">Beginne mit deiner Terra X Tiefseetauchfahrt!</div>
        <div class="story-line">Entdecke Fische mit dolchartigen Zähnen, räuberische Quallen und niedliche Babywale.</div>
        <div style="width: 100%; height: 20px"></div>
        <img src="./assets/gui/svg/hint_swipe.svg" class="scroll-swipe-hint">
        </div>`,
      waypointId: 1,
      atmoAudioFile: "Schwarm_LEVEL_1_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "01_02_Beginne_mit_Entdecke.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
        <div class="story-line">Schaffst du es auf deiner Reise 6 geheimnisvolle Meerestiere einzusammeln?</div>
        <div style="width: 100%; height: 10px"></div>
        <div id="teaserBubbles" class="story-line-img">
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
          <img src="./assets/gui/svg/Bubble.svg" class='narration-image-bubble'>
        </div>
        <div style="width: 100%; height: 40px"></div>
        <img src="./assets/gui/svg/hint_swipe.svg" class="scroll-swipe-hint">
      </div>`,
      waypointId: 1,
      narrationAudioFile: "03_Schaffst_du_es.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Meere sind der letzte Ort dieses Planeten, der größtenteils unerforscht ist ...</div>`,
      waypointId: 2,
      narrationAudioFile: "04_Die_Meere.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... und Heimat vieler Tiere, von denen du vermutlich noch nie etwas gehört hast.</div>`,
      waypointId: 2,
      narrationAudioFile: "05_und_Heimat.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Kennst du den Schweinswal schon?</div>`,
      waypointId: 2,
      narrationAudioFile: "06_Kennst_du_den_Schweinswal.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_1-2",
          path: ["02_PM_WP_02"],
        },
        {
          name: "Porpoise_Child_Scene_1-2",
          path: ["02_PC_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Er lebt direkt vor unserer Haustür in der Nord- und Ostsee.</div>`,
      waypointId: 2,
      narrationAudioFile: "07_er_lebt_direkt.m4a",
      hotspotInfos: [
        {
          hotspotId: 0,
          enableHotspot: false,
        },
        {
          hotspotId: 1,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 2,
      hotspotInfos: [
        {
          hotspotId: 0,
          enableHotspot: true,
        },
        {
          hotspotId: 1,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Schweinswale leiden mehr und mehr unter menschengemachten Problemen ...</div>`,
      waypointId: 3,
      narrationAudioFile: "08_Die_Schweinswale.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_3",
          path: ["03_PM_WP_02"],
        },
        {
          name: "Porpoise_Child_Scene_3",
          path: ["03_PC_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Der Schall von Schiffsschrauben oder U-Booten irritiert ihr feines Gehör.</div>`,
      waypointId: 3,
      narrationAudioFile: "09_Der_Schall.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Schiffschraube.m4a",
        loop: false,
      },
      animationGroupInfos: [
        {
          name: "RippleEffect",
          loop: true,
        },
      ],
      spriteSheetAnims: ["Ripple_Sheet_1", "Ripple_Sheet_2", "Ripple_Sheet_3"],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Unter Wasser breitet sich Schall vier bis fünfmal schneller aus als an Land und selbst weit entfernte Schiffe werden so zur Störung.</div>`,
      waypointId: 3,
      narrationAudioFile: "10_Unterwasser.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ist der Schall zu laut, können sich die Tiere so sehr erschrecken, dass Mutter und Kalb getrennt werden.</div>`,
      waypointId: 3,
      narrationAudioFile: "11_ist_der_Schall_zu_laut.m4a",
      movementInfos: [
        {
          name: "Porpoise_Mother_Scene_3",
          addWP: {
            wpName: "03_PM_WP_03",
            instantTurn: false,
          },
        },
        {
          name: "Porpoise_Child_Scene_3",
          addWP: {
            wpName: "03_PC_WP_03",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Schweinswale verständigen sich durch Klick- und Pfeiftöne, aber das Meer ist groß. Ab und an finden sich Mutter und Kalb nicht wieder ...</div>`,
      waypointId: 3,
      narrationAudioFile: "12_Schweinswale_verständigen.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Wahl_Calls_v2.m4a",
        loop: false,
      },
      hotspotInfos: [
        {
          hotspotId: 19,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 3,
      hotspotInfos: [
        {
          hotspotId: 19,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 4,
      quizId: 1,
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Endlich wiedergefunden! Das Kalb wird ein paar weitere Monate bei seiner Mutter verbringen, bevor es selbstständig wird.</div>`,
      waypointId: 5,
      narrationAudioFile: "13_Endlich_wiedergefunden.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Wahl_Calls_v2.m4a",
        loop: false,
      },
      movementInfos: [
        {
          name: "Porpoise_Child_Scene_3_02",
          path: ["05_PC_WP_02"],
        },
        {
          name: "Porpoise_Mother_Scene_3_02",
          path: ["05_PM_WP_06"],
        },
      ],
      animationGroupInfos: [
        {
          name: "Porpoise_IDLE",
          loop: true,
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 2,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 6,
      fishboidNames: ["fishboid_scene04"],
      hotspotInfos: [
        {
          hotspotId: 2,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wir bewegen uns weiter Richtung Atlantik.</div>`,
      waypointId: 7,
      narrationAudioFile: "14_Wir_bewegen_uns.m4a",
      movementInfos: [
        {
          name: "5_medaka_01_WP01",
          path: ["5_medaka_01_WP02"],
        },
        {
          name: "5_medaka_02_WP01",
          path: ["5_medaka_02_WP02"],
        },
        {
          name: "5_medaka_03_WP01",
          path: ["5_medaka_03_WP02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Diese Echte Karettschildkröte sieht sehr entspannt aus.</div>`,
      waypointId: 7,
      narrationAudioFile: "15_Diese_echte_Karettschildkröte.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Es scheint, als würde sie ein Bad nehmen, während die Fische sie putzen.</div>`,
      waypointId: 7,
      narrationAudioFile: "16_Es_scheint.m4a",
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: false,
        },
        {
          hotspotId: 4,
          enableHotspot: false,
        },
        {
          hotspotId: 5,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 7,
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: true,
        },
        {
          hotspotId: 4,
          enableHotspot: true,
        },
        {
          hotspotId: 5,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wo will sie so plötzlich hin?</div>`,
      waypointId: 7,
      narrationAudioFile: "17_Wo_will_sie.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Underwater_Movement_med.m4a",
        loop: false,
      },
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
      movementInfos: [
        {
          name: "SeaTurtle_Scene_04",
          path: ["07_seaturtle_WP_02"],
        },
        {
          name: "5_medaka_01_WP01",
          addWP: {
            wpName: "5_medaka_01_WP03",
            instantTurn: true,
          },
        },
        {
          name: "5_medaka_02_WP01",
          addWP: {
            wpName: "5_medaka_02_WP03",
            instantTurn: true,
          },
        },
        {
          name: "5_medaka_03_WP01",
          addWP: {
            wpName: "5_medaka_03_WP03",
            instantTurn: true,
          },
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 3,
          enableHotspot: false,
        },
        {
          hotspotId: 4,
          enableHotspot: false,
        },
        {
          hotspotId: 5,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 8,
      quizId: 2,
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Was ist wohl das Ziel der Schildkröte?</div>`,
      waypointId: 8,
      narrationAudioFile: "18_was_ist_wohl.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Vielleicht treffen wir sie später noch einmal und finden es heraus ...</div>`,
      waypointId: 8,
      narrationAudioFile: "19_Vielleicht_treffen.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Dämmerschicht beginnt ab 150 Metern ...</div>`,
      waypointId: 9,
      narrationAudioFile: "20_Die_Dämmerschicht.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... nur wenige Sonnenstrahlen dringen bis in diese Tiefe ...</div>`,
      waypointId: 9,
      narrationAudioFile: "21_Nur_wenige.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... trotzdem kommt es jeden Abend zu einem wahren Naturspektakel ...</div>`,
      waypointId: 9,
      narrationAudioFile: "22_Trotzdem_kommt_es.m4a",
      movementInfos: [
        {
          name: "squid",
          path: ["09_scene_5_squid_WP_02"],
        },
        {
          name: "squid_02",
          path: ["09_scene_5_squid_02_WP_02"],
        },
        {
          name: "squid_03",
          path: ["09_scene_5_squid_03_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "Squid",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... der vertikalen Wanderung!</div>`,
      waypointId: 9,
      narrationAudioFile: "23_der_vertikalen.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Meeresbewohner aus tieferen Schichten machen sich auf, um weiter oben nach Nahrung zu suchen.</div>`,
      waypointId: 9,
      narrationAudioFile: "24_Meeresbewohner.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Es ist die größte, synchronisierte Wanderung von Tieren und Kleinstlebewesen auf diesem Planeten.</div>`,
      waypointId: 9,
      narrationAudioFile: "25_Es_ist.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
        <div class="story-line">Auch wir Menschen machen uns die vertikale Wanderung zu Nutze.</div>
        <div class="story-line">Das Abfischen durch Schleppnetze ist in dieser Zeit besonders ergiebig.</div>
      </div>`,
      waypointId: 9,
      narrationAudioFile: "26_Auch_wir.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Underwater_Movement_heavy.m4a",
        loop: false,
      },
      movementInfos: [
        {
          name: "fishnet_scene5",
          path: ["09_fishnet_WP_02"],
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 9,
      quizId: 3,
      hotspotInfos: [
        {
          hotspotId: 6,
          enableHotspot: false,
        },
        {
          hotspotId: 7,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 9,
      hotspotInfos: [
        {
          hotspotId: 6,
          enableHotspot: true,
        },
        {
          hotspotId: 7,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Da ist ja unsere Echte Karettschildkröte wieder!</div>`,
      waypointId: 10,
      atmoAudioFile: "Schwarm_LEVEL_1_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "27_Da_ist_ja.m4a",
      movementInfos: [
        {
          name: "SeaTurtle_Scene_06",
          path: ["10_seaturtle_WP_02"],
        },
        {
          name: "turtlenet_scene_06",
          path: ["10_turtlenet_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wusstest du, dass etwa 10% des Mülls in europäischen Gewässern Geisternetze sind, die herrenlos herumtreiben?</div>`,
      waypointId: 10,
      narrationAudioFile: "28_Wusstest_du.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Schwimm schneller, Schildkröte! Fehlt dir etwa die Kraft?</div>`,
      waypointId: 10,
      narrationAudioFile: "29_Schwimm_schneller.m4a",
      movementInfos: [
        {
          name: "SeaTurtle_Scene_06",
          addWP: {
            wpName: "10_seaturtle_WP_03",
            instantTurn: false,
          },
        },
        {
          name: "turtlenet_scene_06",
          addWP: {
            wpName: "10_turtlenet_WP_03",
            instantTurn: false,
          },
        },
      ],
      hotspotInfos: [
        {
          hotspotId: 8,
          enableHotspot: false,
        },
      ],
    },
    // {
    //   narrationHTML: `<div class="story-block story-line fade-in">Du schaffst das, befreie dich!</div>`,
    //   waypointId: 10,
    //   narrationAudioFile: "30_Du_schaffst.m4a",
    // },
    // {
    //   narrationHTML: `<div class="story-block story-line fade-in">... sonst bedeutet es den Tod durch Ertrinken ...</div>`,
    //   waypointId: 10,
    //   narrationAudioFile: "31_Sonst.m4a",
    // },
    {
      narrationHTML: "",
      waypointId: 10,
      hotspotInfos: [
        {
          hotspotId: 8,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Immerhin ... aber wer hilft ihr nun das Seil abzuschütteln?</div>`,
      waypointId: 11, // 10a
      narrationAudioFile: "32_Immerhin.m4a",
      movementInfos: [
        {
          name: "10a_SeaTurtle_Scene_06",
          path: ["10a_seaturtle_WP_02"],
        },
      ],
      animationGroupInfos: [
        {
          name: "SeaTurtle_Swim_IDLE",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Die Schildkröte scheint trächtig zu sein ...</div>`,
      waypointId: 11, // 10a,
      narrationAudioFile: "33_Die_Schildkröte.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... hoffen wir, dass sie genug Kraft hat, um trotz des Seils ihre Nistkolonie zu erreichen.</div>`,
      waypointId: 11, // 10a,
      narrationAudioFile: "34_Hoffen_wir.m4a",
      movementInfos: [
        {
          name: "10a_SeaTurtle_Scene_06",
          addWP: {
            wpName: "10a_seaturtle_WP_03",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ab 200 Metern gibt es kein pflanzliches Leben mehr ...</div>`,
      waypointId: 11 + 1,
      atmoAudioFile: "Schwarm_LEVEL_2_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "35_Ab_200.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... und es wird immer dämmriger. Wer hier überleben will, muss von der Natur ganz besonders ausgestattet sein.</div>`,
      waypointId: 11 + 1,
      narrationAudioFile: "26_Und_es_wird.m4a",
    },
    {
      // Audio mismatch fixed
      narrationHTML: `<div class="story-block story-line fade-in">Der Gespensterfisch rotiert seine Linsen in alle Richtungen und kann so auch über sich Nahrung erspähen.</div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "27_der_Gespensterfisch.m4a",
      movementInfos: [
        {
          name: "Barrelleye_Scene_07",
          path: ["12_barreleye_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ein großes Problem ist Mikroplastik, das sich wie Schnee durchs Wasser bewegt.</div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "28_Ein_großes_Problem.m4a",
      // animationGroupInfos: [
      //   {
      //     name: "Barreleye_IDLE",
      //     loop: true,
      //   },
      // ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Was ist essbar, was nicht?</div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "29a_Was_ist_Essbar.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Viele Fische erkennen den Unterschied nicht.</div>`,
      waypointId: 12 + 1,
      narrationAudioFile: "29b_Was_ist_Essbar.m4a",
      hotspotInfos: [
        {
          hotspotId: 9,
          enableHotspot: false,
        },
        {
          hotspotId: 11,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 13,
      hotspotInfos: [
        {
          hotspotId: 9,
          enableHotspot: true,
        },
        {
          hotspotId: 11,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eines der ältesten und absonderlichsten Tiere der Erdgeschichte ist die Qualle.</div>`,
      waypointId: 13 + 1,
      atmoAudioFile: "Schwarm_LEVEL_2_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "30_Eines_der_aeltesten.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Auch wenn sie zart wirkt und weder Zähne noch Klauen besitzt, ist sie ein gefährlicher und gefräßiger Räuber.</div>`,
      waypointId: 13 + 1,
      narrationAudioFile: "31_auch_wenn.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Manche Arten haben meterlange giftige Tentakel, die Fische bei Berührung lähmen und töten.</div>`,
      waypointId: 13 + 1,
      narrationAudioFile: "32_Manche_Arten.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Andere besitzen Klebetentakel, an denen die Beute hängen bleibt.</div>`,
      waypointId: 13 + 1,
      narrationAudioFile: "33_Andere_besitzen.m4a",
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 14,
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 13 + 1,
      quizId: 4,
      hotspotInfos: [
        {
          hotspotId: 10,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ab 1000 Metern herrscht tiefe Finsternis ...</div>`,
      waypointId: 14 + 1,
      atmoAudioFile: "Schwarm_LEVEL_3_ATMO_LOOP_KORREKTUR_v2.m4a",
      narrationAudioFile: "34_Ab_1000.m4a",
      movementInfos: [
        {
          name: "Lanternfish_02_Scene_09",
          path: ["14_Lanternfish_02_WP_02"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... Gefahren können hier überall lauern ...</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "35_Gefahren_können.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... wer ist Freund und wer Feind? In der Dunkelheit ist das schwer zu erkennen ...</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "36_Wer_ist_Freund.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Der Laternenfisch leuchtet, um einen Partner anzulocken ...</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "37_Der_Laternenfisch.m4a",
      movementInfos: [
        {
          name: "Lanternfish_01_Scene_09",
          path: ["14_Lanternfish_01_WP_01"],
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... denn in der ewigen Dunkelheit ist es schwierig sich zu finden.</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "38_Denn_in_der.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Könnte das Leuchten in der Ferne ein anderer Laternenfisch sein?</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "39_Koennte_das.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">... oder ist es womöglich ein gefährlicher Jäger?</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "40_oder_ist_es.m4a",
      movementInfos: [
        {
          name: "Lanternfish_01_Scene_09",
          addWP: {
            wpName: "14_Lanternfish_01_WP_02",
            instantTurn: false,
          },
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Der Laternenfisch hat tatsächlich ein Weibchen gefunden!</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "42_Der_Laternenfisch_hat.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wenn die beiden sich paaren, wird es bald unglaubliche 800–900 Eier legen.</div>`,
      waypointId: 14 + 1,
      narrationAudioFile: "43_Wenn_die_beiden_sich.m4a",
      hotspotInfos: [
        {
          hotspotId: 12,
          enableHotspot: false,
        },
        {
          hotspotId: 13,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 15,
      hotspotInfos: [
        {
          hotspotId: 12,
          enableHotspot: true,
        },
        {
          hotspotId: 13,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 15 + 1,
      movementInfos: [
        {
          name: "Scene_10_Anglerfish",
          path: ["10_Anglerfish_WP_02"],
        },
      ],
      quizId: 5,
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wir nähern uns dem Meeresboden, einem kaum erforschten Gebiet.</div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "44_Wir_naehern_uns.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Von den etwa 300 Millionen Quadratkilometern kennen wir bisher nur 5%.</div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "45_von_den_etwa_300_Millionen.m4a", // TODO: This file still needs to be converted
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Hast du dir schon einmal darüber Gedanken gemacht, wie der Meeresboden aussehen könnte?</div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "46_Hast_du_dir_schon.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wie eine Wüste? Karg und schlammig?</div>`,
      waypointId: 16 + 1,
      narrationAudioFile: "47_wie_eine_wueste.m4a",
    },
    {
      narrationHTML: `<div class="story-block fade-in">
        <div class="story-line">Große Teile des Meeresbodens wimmeln vor Leben.</div>
        <div class="story-line">Möglicherweise gibt es hier unten Millionen von Arten, die wir bisher noch nicht entdeckt haben!</div>
      </div>`,
      waypointId: 17 + 1,
      narrationAudioFile: "48_große_Teile.m4a",
      hotspotInfos: [
        {
          hotspotId: 14,
          enableHotspot: false,
        },
        {
          hotspotId: 15,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 18,
      hotspotInfos: [
        {
          hotspotId: 14,
          enableHotspot: true,
        },
        {
          hotspotId: 15,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eine Riesenassel ist auf der Suche nach Nahrung.</div>`,
      waypointId: 18 + 1,
      narrationAudioFile: "49_eine_Riesenassel.m4a",
      soundAudioInfo: {
        fileName: "Schwarm_SFX_Riesenassel_Tap_Scratch_2.m4a",
        loop: true,
      },
      animationGroupInfos: [
        {
          name: "Isopode_Anim",
          loop: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Sie wächst ihr Leben lang und ist mit 45 Zentimetern eine der größten Asseln der Welt.</div>`,
      waypointId: 18 + 1,
      narrationAudioFile: "50_Sie_waechst.m4a",
      hotspotInfos: [
        {
          hotspotId: 16,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 19,
      hotspotInfos: [
        {
          hotspotId: 16,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Eine sehr ergiebige Nahrungsquelle für Riesenasseln, Würmer, Krebse und Aale sind Walkadaver.</div>`,
      waypointId: 19 + 1,
      narrationAudioFile: "51_Eine_sehr.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Etwa 69.000 Wale sterben jedes Jahr, von denen 90% zu Boden sinken.</div>`,
      waypointId: 19 + 1,
      narrationAudioFile: "52_Etwa_69000.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Ein Kadaver kann Jahrzehntelang als Nahrungsquelle dienen.</div>`,
      waypointId: 19 + 1,
      narrationAudioFile: "53_Ein_Kadaver.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Außerdem sinken tote Tiere, Pflanzenreste und Einzeller auf den Meeresboden und ernähren die Bodenbewohner.</div>`,
      waypointId: 20 + 1,
      narrationAudioFile: "54_Außerdem_sinken.m4a",
      movementInfos: [
        {
          name: "Barrelleye_Scene_12",
          path: ["20_Barrelleye_WP_02", "20_Barrelleye_WP_03"],
        },
      ],
    },
    {
      // Audio mismatch fixed
      narrationHTML: `<div class="story-block story-line fade-in">Auch der tote Gespensterfisch.</div>`,
      waypointId: 20 + 1,
      narrationAudioFile: "55_auch_der_tote.m4a",
    },
    {
      // Audio mismatch fixed
      narrationHTML: `<div class="story-block story-line fade-in">Und auch Plastikmüll sinkt bis hier unten hinab.</div>`,
      waypointId: 21 + 1,
      narrationAudioFile: "56_und_auch_Plastikmuell.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Wie viel schon am Meeresboden liegt, weiß man nicht.</div>`,
      waypointId: 21 + 1,
      narrationAudioFile: "47_wie_vieldavon.m4a",
    },
    {
      narrationHTML: `<div class="story-block story-line fade-in">Es ist gut möglich, dass wir mit unserem Müll Ökosysteme zerstören und Meerestiere ausrotten, bevor wir sie jemals zu Gesicht bekommen haben.</div>`,
      waypointId: 22 + 1,
      narrationAudioFile: "48_es_ist_gut_moeglich.m4a",
    },
    {
      narrationHTML: "",
      waypointId: 22 + 1,
      quizId: 6,
      hotspotInfos: [
        {
          hotspotId: 17,
          enableHotspot: false,
        },
        {
          hotspotId: 18,
          enableHotspot: false,
        },
      ],
    },
    {
      narrationHTML: "",
      waypointId: 22 + 1,
      hotspotInfos: [
        {
          hotspotId: 17,
          enableHotspot: true,
        },
        {
          hotspotId: 18,
          enableHotspot: true,
        },
      ],
    },
    {
      narrationHTML: `<div class="story-block fade-in">
          <div class="story-line story-line-end">Du bist am Ende deiner Tauchfahrt angelangt</div>
          <div class="story-line">Hast du alle AR Tiere entdeckt?</div>
          <div class='primary-button-container narration-primary-button-container'>
        <button id='toTopButton' class='primary-button'>
          TAUCHE WIEDER AUF
        </button>
        </div>
      </div>`,
      waypointId: 24,
      showFooter: false,
    },
    {
      narrationHTML: "",
      waypointId: 24,
      showFooter: true,
    },
  ];

  private static instance: Narration;

  private _narrationOverlayContainer: HTMLDivElement;
  private _overlayIsVisible: boolean = false;
  public currentNarrationIndex: number = 0;

  // @ts-ignore ignoring the super call as we don't want to re-init
  constructor(html: string) {
    if (Narration.instance) {
      return null;
    }

    this._narrationOverlayContainer = document.createElement("div");
    this._narrationOverlayContainer.id = "narrationOverlayContainer";
    this._narrationOverlayContainer.className = "narration-overlay-hidden";
    this._narrationOverlayContainer.innerHTML = html;

    document
      .getElementById("guiContainerOverlay")
      .appendChild(this._narrationOverlayContainer);
  }

  public static getInstance(): Narration {
    if (!Narration.instance) {
      Narration.instance = new Narration("NarrationOverlay");
    }
    return Narration.instance;
  }

  public setHTML(html: string) {
    this._narrationOverlayContainer.innerHTML = html;
  }

  public async showNarrationOverlay(narrationInfo: NarrationInfo) {
    // Track scroll event
    let infoIdx = (
      GuiManager.getInstance().isMobile()
        ? this._narrationMobile
        : this._narrationDesktop
    ).findIndex((info) => info === narrationInfo);
    if (infoIdx > -1) {
      if (WaypointManager.getInstance().isFirstWaypoint()) {
        TrackingManager.getInstance().pageDisplay(PageTitle.STARTPAGE);
      } // else TrackingManager.getInstance().scroll();
    }

    Narration.getInstance()._narrationOverlayContainer.classList.replace(
      "narration-overlay-hidden",
      "narration-overlay-visible"
    );

    this.setHTML(narrationInfo.narrationHTML);

    this.applyDesktopOrMobileLayout();

    this._overlayIsVisible = true;

    // Display ZDF logo and hide gauges at the start to make space for partner logos
    if (narrationInfo.waypointId === 0) {
      document
        .querySelector("#startButton")
        .addEventListener("click", (e: Event) => this.startClick());
      HUD.getInstance().toggleGauge(false);
      HUD.getInstance().toggleLogo(true);
      HUD.getInstance().togglePartners(true);
      AudioManager.getInstance().resetAtmo();
    } else {
      HUD.getInstance().toggleGauge(true);
      HUD.getInstance().toggleLogo(false);
    }

    if (
      narrationInfo.waypointId ===
      WaypointManager.getInstance().getWaypoints().length - 1
    ) {
      if (document.getElementById("toTopButton")) {
        document
          .querySelector("#toTopButton")
          .addEventListener("click", (e: Event) => this.toTopClick());
      }
    }

    if (narrationInfo.atmoAudioFile)
      AudioManager.getInstance().playAtmo(narrationInfo.atmoAudioFile);

    // play sound
    if (narrationInfo.narrationAudioFile)
      AudioManager.getInstance().playNarrationAudio(
        narrationInfo.narrationAudioFile
      );

    if (narrationInfo.soundAudioInfo)
      AudioManager.getInstance().playSound(narrationInfo.soundAudioInfo);

    // play animations
    if (narrationInfo.animationGroupInfos)
      narrationInfo.animationGroupInfos.forEach((animationGroupInfo) => {
        AnimationManager.getInstance().playAnimationGroup(animationGroupInfo);
      });

    if (narrationInfo.movementInfos) {
      narrationInfo.movementInfos.forEach((movementInfo) => {
        AnimationManager.getInstance().playMovement(movementInfo);
      });
    }

    if (narrationInfo.fishboidNames)
      narrationInfo.fishboidNames.forEach((fishboidName) => {
        AnimationManager.getInstance().playBoid(fishboidName);
      });

    if (narrationInfo.spriteSheetAnims)
      narrationInfo.spriteSheetAnims.forEach((spriteSheetAnim) => {
        AnimationManager.getInstance().playSpriteSheetAnimation(
          spriteSheetAnim
        );
      });

    if (narrationInfo.quizId) {
      switch (narrationInfo.quizId) {
        case 1: {
          Quiz.getInstance().startQuiz1();
          break;
        }
        case 2: {
          Quiz.getInstance().startQuiz2();
          break;
        }
        case 3: {
          Quiz.getInstance().startQuiz3();
          break;
        }
        case 4: {
          Quiz.getInstance().startQuiz4();
          break;
        }
        case 5: {
          Quiz.getInstance().startQuiz5();
          break;
        }
        case 6: {
          Quiz.getInstance().startQuiz6();
          break;
        }
      }
    }

    if (narrationInfo.hotspotInfos) {
      let hotspotOverlay: HotspotOverlay = new HotspotOverlay();

      narrationInfo.hotspotInfos.forEach((hotspotInfo) => {
        if (hotspotInfo.enableHotspot) {
          hotspotOverlay.loadHotspot(hotspotInfo.hotspotId);
        } else {
          // Hotspots don't necessarily have to be removed here. They also get cleared by ScrollCamera when we move to the next waypoint. Removing them here only makes sense if they are not the last thing we see at a waypoint. Or sometimes if we scroll backwards.
          hotspotOverlay.removeHotspot(hotspotInfo.hotspotId);
        }
      });
    }

    if (typeof narrationInfo.showFooter !== "undefined") {
/*       console.log(
        "[Narration] showFooter is set for this page: " +
          narrationInfo.showFooter
      ); */
      Footer.getInstance().toggleFooter(narrationInfo.showFooter);
    }

    // Force a delay until the next scroll is possible
    await sleep(ScrollCamera.scrollDelay);
    ScrollCamera.scrollNarrationInProgress = false;
  }

  // Apply mobile, tablet or desktop style to narration overlays
  public applyDesktopOrMobileLayout() {
    if (GuiManager.getInstance().isTablet()) {
      if (GuiManager.getInstance().orientationIsPortrait()) {
        document.getElementById("narrationOverlayContainer").classList.add("narration-overlay-tablet");
        document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-mobile");
      }
      else {
        document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-mobile");
        document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-tablet");
      }
    }
    else if (GuiManager.getInstance().isMobile()) {
      document.getElementById("narrationOverlayContainer").classList.add("narration-overlay-mobile");
      document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-tablet");
    }
    else {
      document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-mobile");
      document.getElementById("narrationOverlayContainer").classList.remove("narration-overlay-tablet");
    }

    // Apply font styles to narration overlays for smartphones, desktop and talets get the desktop style.
    let storyLines = document.getElementsByClassName("story-line");
    for (let i = 0; i < storyLines.length; i++) {
      if (GuiManager.getInstance().isMobile()) {
        storyLines[i].classList.remove("font-copy-large");
        storyLines[i].classList.add("font-copy-large-mobile");
      } else {
        storyLines[i].classList.add("font-copy-large");
        storyLines[i].classList.remove("font-copy-large-mobile");
      }
    }

    // Header ("Beginne mit...") is of class story-line but needs to be styled differently
    let storyLineHeaders = document.getElementsByClassName("story-line-header");
    for (let i = 0; i < storyLineHeaders.length; i++) {
      if (GuiManager.getInstance().isMobile()) {
        storyLineHeaders[i].classList.add("font-h-giant-mobile");
        storyLineHeaders[i].classList.remove("font-h-giant");
        storyLineHeaders[i].classList.remove("font-copy-large-mobile");
      } else {
        storyLineHeaders[i].classList.add("font-h-giant");
        storyLineHeaders[i].classList.remove("font-h-giant-mobile");
        storyLineHeaders[i].classList.remove("font-copy-large");
      }
    }

    // End is of class story-line but needs to be styled differently
    let storyLineEnd = document.getElementsByClassName("story-line-end");
    for (let i = 0; i < storyLineEnd.length; i++) {
      if (GuiManager.getInstance().isMobile()) {
        storyLineEnd[i].classList.add("font-h-two-mobile");
        storyLineEnd[i].classList.remove("font-h-one");
        storyLineEnd[i].classList.remove("font-copy");
      } else {
        storyLineEnd[i].classList.add("font-h-one");
        storyLineEnd[i].classList.remove("font-h-two-mobile");
        storyLineEnd[i].classList.remove("font-copy-large");
      }
    }

    // Styling of AR teaser bubbles
    let imageBubbles = document.getElementsByClassName(
      "narration-image-bubble"
    );
    for (let i = 0; i < imageBubbles.length; i++) {
      if (GuiManager.getInstance().isMobile()) {
        imageBubbles[i].classList.add("narration-image-bubble-mobile");
      } else {
        imageBubbles[i].classList.remove("narration-image-bubble-mobile");
      }
    }

    let scrollSwipeHints = document.getElementsByClassName("scroll-swipe-hint");
    for (let i = 0; i < scrollSwipeHints.length; i++) {
      if (GuiManager.getInstance().isMobile()) {
        scrollSwipeHints[i].classList.add("scroll-swipe-hint-mobile");
      } else {
        scrollSwipeHints[i].classList.remove("scroll-swipe-hint-mobile");
      }
    }
  }

  public hideNarrationOverlay() {
    Narration.getInstance()._narrationOverlayContainer.classList.replace(
      "narration-overlay-visible",
      "narration-overlay-hidden"
    );
    this._overlayIsVisible = false;
  }

  public isVisible(): boolean {
    return this._overlayIsVisible;
  }

  // Returns NarrationInfo property for desktop or mobile layout
  public getNarrationInfos(mobile: boolean): NarrationInfo[] {
    return mobile ? this._narrationMobile : this._narrationDesktop;
  }

  public getNarrationIndex(): number {
    return this.currentNarrationIndex;
  }

  public startClick() {
    AudioManager.getInstance().unlockEngine();
    ScrollCamera.getInstance().startScroll();
    HUD.getInstance().togglePartners(false);
    TrackingManager.getInstance().clickNavigation(
      PageTitle.STARTPAGE,
      PageTitle.CANVAS,
      PageTitle.CANVAS
    );
    TrackingManager.getInstance().pageDisplay(PageTitle.CANVAS);
    setTimeout(() => {
      AudioManager.getInstance().playSound({
        fileName: "Schwarm_SFX_Abtauchen_v2.m4a",
        loop: false,
        nonWPSound: true,
      });
    }, 500);
  }

  public toTopClick() {
    // console.log("toTopClick clicked");
    this.hideNarrationOverlay();
    Footer.getInstance().toggleFooter(false);
    ScrollCamera.getInstance().resetCamera();
  }

  // Find ID of the waypoint this quiz gets displayed at
  public getWaypointIdOfQuiz(quizId: number): number {
    let narrationInfos: NarrationInfo[] = this.getNarrationInfos(
      GuiManager.getInstance().isMobile()
    );
    let waypointId: number = -1;

    narrationInfos.forEach((narrationInfo) => {
      if (narrationInfo.quizId) {
        if (narrationInfo.quizId == quizId) {
          waypointId = narrationInfo.waypointId;
          return waypointId;
        }
      }
    });

    return waypointId;
  }

  // Find out which atmo file should be playing at this waypoint even if it's not explicitly specified
  public getAtmoAtWaypoint(waypointId: number): string {
    let narrationInfos: NarrationInfo[] = this.getNarrationInfos(
      GuiManager.getInstance().isMobile()
    );

    // If the atmo is explicitly specified for this waypoint
    if (narrationInfos[waypointId].atmoAudioFile) {
      return narrationInfos[waypointId].atmoAudioFile;
    }

    let atmoAudioFile: string;

    // Find the last specified atmo
    narrationInfos.forEach((narrationInfo) => {
      if (narrationInfo.waypointId <= waypointId) {
        if (narrationInfo.atmoAudioFile) {
          atmoAudioFile = narrationInfo.atmoAudioFile;
        }
      }
    });

    return atmoAudioFile;
  }
}
