import GuiManager from "./gui-manager";
import TrackingManager, {
  FunctionType,
  PageTitle,
} from "./tracking-manager";

export class HotspotOverlay {
  public static hotspotList: Hotspot[] = [
    {
      id: 0,
      displayText: "ERFAHRE MEHR ÜBER DEN SCHWEINSWAL",
      displayTextLeft: true,
      positionTop: 0.45,
      positionLeft: 0.45,
      pageTitle: PageTitle.WHALE_02,
    },
    {
      id: 1,
      displayText: "ERFAHRE MEHR ÜBER DIE SEEGRASWIESEN",
      displayTextLeft: false,
      positionTop: 0.65,
      positionLeft: 0.55,
      pageTitle: PageTitle.WHALE_01,
    },
    {
      id: 2,
      displayText: 'ERFAHRE MEHR ÜBER DAS "LEBEN UNTER WASSER"',
      displayTextLeft: false,
      positionTop: 0.5,
      positionLeft: 0.5,
      pageTitle: PageTitle.ANTIPOLLUTION_02,
    },
    {
      id: 3,
      displayText: "ERFAHRE MEHR ÜBER DIE PARASITEN",
      displayTextLeft: true,
      positionTop: 0.3,
      positionLeft: 0.6,
      pageTitle: PageTitle.ANTIPOLLUTION_01,
    },
    {
      id: 4,
      displayText: "ERFAHRE MEHR ÜBER DIE ECHTE KARETTSCHILDKRÖTE",
      displayTextLeft: false,
      positionTop: 0.8,
      positionLeft: 0.45,
      pageTitle: PageTitle.ANTIPOLLUTION_04,
    },
    {
      id: 5,
      displayText: "ERFAHRE MEHR ÜBER DIE PUTZERFISCHE",
      displayTextLeft: false,
      positionTop: 0.575,
      positionLeft: 0.35,
      pageTitle: PageTitle.ANTIPOLLUTION_03,
    },
    {
      id: 6,
      displayText: "ERFAHRE MEHR ÜBER DAS PLANKTON",
      displayTextLeft: true,
      positionTop: 0.6,
      positionLeft: 0.65,
      pageTitle: PageTitle.PLANKTON_01,
    },
    {
      id: 7,
      displayText: "ERFAHRE MEHR ÜBER DIE VERTIKALE WANDERUNG",
      displayTextLeft: false,
      positionTop: 0.4,
      positionLeft: 0.35,
      pageTitle: PageTitle.PLANKTON_02,
    },
    {
      id: 8,
      displayText: "ERFAHRE MEHR ÜBER DIE GEISTERNETZE",
      displayTextLeft: true,
      positionTop: 0.4,
      positionLeft: 0.6,
      pageTitle: PageTitle.HABITAT_OCEAN_01,
    },
    {
      id: 9,
      displayText: "ERFAHRE MEHR ÜBER DEN MEERESSCHNEE",
      displayTextLeft: false,
      positionTop: 0.7,
      positionLeft: 0.5,
      pageTitle: PageTitle.HABITAT_OCEAN_02,
    },
    {
      id: 10,
      displayText: "ERFAHRE MEHR ÜBER DIE QUALLEN",
      displayTextLeft: false,
      positionTop: 0.45,
      positionLeft: 0.4,
      pageTitle: PageTitle.SPECIAL_ANIMALS_01,
    },
    {
      id: 11,
      displayText: "ERFAHRE MEHR ÜBER DIE ARTENVIELFALT DER TIEFSEE",
      displayTextLeft: true,
      positionTop: 0.3,
      positionLeft: 0.45,
      pageTitle: PageTitle.HABITAT_OCEAN_03,
    },
    {
      id: 12,
      displayText: "ERFAHRE MEHR ÜBER DEN LATERNENFISCH",
      displayTextLeft: true,
      positionTop: 0.6,
      positionLeft: 0.5,
      pageTitle: PageTitle.SPECIAL_ANIMALS_02,
    },
    {
      id: 13,
      displayText: "ERFAHRE MEHR ÜBER DIE BIOLUMINESZENZ",
      displayTextLeft: false,
      positionTop: 0.725,
      positionLeft: 0.4,
      pageTitle: PageTitle.SPECIAL_ANIMALS_03,
    },
    {
      id: 14,
      displayText: "ERFAHRE MEHR ÜBER DIE SEEGURKEN",
      displayTextLeft: false,
      positionTop: 0.8,
      positionLeft: 0.4,
      pageTitle: PageTitle.SEA_BOTTOM_02,
    },
    {
      id: 15,
      displayText: "ERFAHRE MEHR ÜBER DEN LEBENSRAUM TIEFSEE",
      displayTextLeft: true,
      positionTop: 0.6,
      positionLeft: 0.55,
      pageTitle: PageTitle.SEA_BOTTOM_01,
    },
    {
      id: 16,
      displayText: "ERFAHRE MEHR ÜBER DIE RIESENASSEL",
      displayTextLeft: false,
      positionTop: 0.5,
      positionLeft: 0.5,
      pageTitle: PageTitle.SEA_BOTTOM_03,
    },
    {
      id: 17,
      displayText: "ERFAHRE MEHR ÜBER DEN MÜLL IM MEER",
      displayTextLeft: false,
      positionTop: 0.4,
      positionLeft: 0.5,
      pageTitle: PageTitle.TRASH_IN_THE_OCEAN_02,
    },
    {
      id: 18,
      displayText: "ERFAHRE MEHR ÜBER<br>PLASTIK IM MEER",
      displayTextLeft: true,
      positionTop: 0.8,
      positionLeft: 0.4,
      pageTitle: PageTitle.TRASH_IN_THE_OCEAN_01,
    },
    {
      id: 19,
      displayText: "ERFAHRE MEHR ÜBER DEN LÄRM IM MEER",
      displayTextLeft: true,
      positionTop: 0.15,
      positionLeft: 0.55,
      pageTitle: PageTitle.TRASH_IN_THE_OCEAN_03,
    },
  ];

  public loadHotspot(hotspotId: number): void {
    if (hotspotId >= HotspotOverlay.hotspotList.length || hotspotId < 0) {
      console.warn(
        "[HotspotOverlay] Cannot load hotspot with ID " +
          hotspotId +
          ". ID is invalid."
      );
      return;
    }

    if (
      document.getElementById("hotspotOverlayContainer" + hotspotId.toString())
    ) {
      console.warn(
        "[HotspotOverlay] Cannot load hotspot with ID " +
          hotspotId +
          ". Element hotspotOverlayContainer" +
          hotspotId.toString() +
          " already exists."
      );
      return;
    }

    // console.log("[HotspotOverlay] Loading hotspot with ID " + hotspotId);

    let overlayContainer = document.createElement("div");
    overlayContainer.id = "hotspotOverlayContainer" + hotspotId.toString();
    overlayContainer.className = "hotspot-overlay-container";

    overlayContainer.innerHTML =
      `
      <div id="hotspotContainer` +
      hotspotId +
      `" class="hotspot-container fade-in">
        <div id="hotspotImageContainer` +
      hotspotId +
      `" class="hotspot-image-container">
          <img
            id="hotspotImageHover"
            src = "./assets/gui/svg/hotspot-hover_new.png"
            class="hotspot-image-hover"
          >
          <img
            id="hotspotImage"
            src="./assets/gui/svg/Hotspot.png"
            class="hotspot-image"
          >
        </div>
        <div id="hotspotWhitespace" class="hotspot-whitespace"></div>
        <div id="hotspotText` +
      hotspotId +
      `" class="hotspot-text font-button">
          ERFAHRE MEHR ÜBER DIESEN HOTSPOT
        </div>
      <div>
      `;

    document
      .getElementById("guiContainerOverlay")
      .appendChild(overlayContainer);

    document.getElementById("hotspotText" + hotspotId.toString()).innerHTML =
      HotspotOverlay.hotspotList[hotspotId].displayText;

    HotspotOverlay.hotspotList[hotspotId].displayTextLeft
      ? document
          .getElementById("hotspotContainer" + hotspotId.toString())
          .classList.add("hotspot-container-reverse")
      : document
          .getElementById("hotspotContainer" + hotspotId.toString())
          .classList.remove("hotspot-container-reverse");

    // positionTop and positionLeft are percentages divided by 100
    // Mobile hotspots are 60px x 240px, desktop hotspots are 75px x 300px
    document.getElementById("hotspotContainer" + hotspotId.toString()).style.top = 
      GuiManager.getInstance().useMobileLayout()
        ? "calc(" + HotspotOverlay.hotspotList[hotspotId].positionTop + " * (100% - 60px))"
        : "calc(" + HotspotOverlay.hotspotList[hotspotId].positionTop + " * (100% - 75px))";

    document.getElementById("hotspotContainer" + hotspotId.toString()).style.left =
      GuiManager.getInstance().useMobileLayout()
        ? "calc(" + HotspotOverlay.hotspotList[hotspotId].positionLeft + " * (100% - 240px))"
        : "calc(" + HotspotOverlay.hotspotList[hotspotId].positionLeft + " * (100% - 300px))";

    GuiManager.getInstance().useMobileLayout()
      ? overlayContainer.classList.add("hotspot-overlay-container-mobile")
      : overlayContainer.classList.remove("hotspot-overlay-container-mobile");

    // Disable hover states for hotspots on mobile devices and tablets
    let hoverImages = document.getElementsByClassName("hotspot-image-hover");

    for (let i = 0; i < hoverImages.length; i++) {
      if (GuiManager.getInstance().isMobileOrTablet()) {
        hoverImages[i].classList.add("element-hidden");
      } else {
        hoverImages[i].classList.remove("element-hidden");
      }
    }

    TrackingManager.getInstance().selfPromotionDisplay(
      PageTitle.CANVAS,
      HotspotOverlay.hotspotList[hotspotId].pageTitle, // targetTitle
      FunctionType.THEME,
      HotspotOverlay.hotspotList[hotspotId].displayText
    );
    document
      .getElementById("hotspotImageContainer" + hotspotId)
      .addEventListener("click", () => {
        GuiManager.getInstance().openMediaOverlay(hotspotId);
        TrackingManager.getInstance().selfPromotionClick(
          PageTitle.CANVAS,
          HotspotOverlay.hotspotList[hotspotId].pageTitle, // targetTitle
          FunctionType.THEME,
          HotspotOverlay.hotspotList[hotspotId].displayText
        );
        TrackingManager.getInstance().pageDisplay(
          HotspotOverlay.hotspotList[hotspotId].displayText
        );
      });
  }

  public removeHotspot(hotspotId: number): void {
    if (hotspotId >= HotspotOverlay.hotspotList.length || hotspotId < 0) {
/*       console.warn(
        "[HotspotOverlay] Cannot remove hotspot with ID " +
          hotspotId +
          ". ID is invalid."
      ); */
      return;
    }

    let hotspotOverlayContainer: HTMLElement = document.getElementById(
      "hotspotOverlayContainer" + hotspotId.toString()
    );
    if (hotspotOverlayContainer) {
      document
        .getElementById("hotspotOverlayContainer" + hotspotId.toString())
        .remove();
      // console.log("[HotspotOverlay] Removing hotspot with ID " + hotspotId);
    } else {
/*       console.warn(
        "[HotspotOverlay] Cannot remove hotspot with ID " +
          hotspotId +
          ". Element " +
          "hotspotOverlayContainer" +
          hotspotId.toString() +
          " not found."
      ); */
    }
  }

  public static clearAllHotspots() {
    // console.log("[HotspotManager] Clearing all hotspots");

    // hotspotOverlyContainer is a dynamic collection so its length changes as we remove elements. You can't use a regular for-loop.
    while (
      document.getElementsByClassName("hotspot-overlay-container").length > 0
    ) {
      let hotspotOverlayContainers = document.getElementsByClassName(
        "hotspot-overlay-container"
      );
      hotspotOverlayContainers[0].remove();
    }
  }
}

export type Hotspot = {
  id: number;
  displayText: string;
  displayTextLeft: boolean;
  positionTop: number; // Distance from top of window on a scale from 0 to 1
  positionLeft: number; // Distance from left side of window on a scale from 0 to 1
  pageTitle: PageTitle; // for tracking
};
