export const lerp = (start: number, end: number, amount: number) => start * (1 - amount) + end * amount;

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function findHtmlAncestors(element: Element, ancestorSelector: string) {  
    var ancestor = document.querySelector(ancestorSelector);
    if (ancestor.contains(element)) {
      return true;
    }
    else {
      return false;
    }
}