import {
    Color3,
    Mesh,
    MeshBuilder,
    StandardMaterial,
    Vector3,
} from "@babylonjs/core";

export default class BoidsManager {
    private _cohesion: number;
    private _separation: number;
    private _separationMinDistance: number;
    private _alignment: number;
    private _maxSpeed: number;
    private _boundsMin: Vector3;
    private _boundsMax: Vector3;
    private _otherForces: any[];
    private _center: Vector3;
    private _avgVel: Vector3;
    private _debug: Debug;
    private _boids: Boid[];
    public getBoids(): Boid[] {
        return this._boids;
    }

    constructor(
        total: number,
        center: Vector3,
        cohesion: number = 0.3,
        separation: number = 0.4,
        separationMinDistance: number = 3.0,
        alignment: number = 1.0,
        initialRadius: number = 1.0,
        boundRadiusScale: number = 100.0,
        initialVelocity: Vector3 = null
    ) {
        // control factors
        this._cohesion = cohesion;
        this._separation = separation;
        this._separationMinDistance = separationMinDistance;
        this._alignment = alignment;

        this._maxSpeed = 1.0; // in units per second

        // set bounds
        this._boundsMin = new Vector3(
            center.x - boundRadiusScale,
            center.y - boundRadiusScale,
            center.z - boundRadiusScale
        );
        this._boundsMax = new Vector3(
            center.x + boundRadiusScale,
            center.y + boundRadiusScale,
            center.z + boundRadiusScale
        );

        this._otherForces = []; // other force callbacks
        this._boids = []; // list of boids

        this._debug = {
            show: false,
            arrows: [],
        };

        if (!initialVelocity) {
            initialVelocity = new Vector3(0.3, 0.1, 0.3);
        }
        const initialSpeed = initialVelocity.length();

        // internal data, cached per frame
        this._center = center.clone();
        this._avgVel = new Vector3(0.0, 0.0, 0.0);

        for (let i = 0; i < total; i++) {
            const position = this._center.add(
                new Vector3(
                    (Math.random() - 0.5) * initialRadius,
                    (Math.random() - 0.5) * initialRadius,
                    (Math.random() - 0.5) * initialRadius
                )
            );
            const velocity = new Vector3(
                initialVelocity.x +
                    ((Math.random() - 0.5) / 10.0) * initialSpeed,
                initialVelocity.y +
                    ((Math.random() - 0.5) / 10.0) * initialSpeed,
                initialVelocity.z +
                    ((Math.random() - 0.5) / 10.0) * initialSpeed
            );
            const boid = new Boid(i, position, velocity);
            this._boids.push(boid);
        }
    }

    /**
     * Updates the boids.
     *
     * @param {Number} deltaTime The time since last frame in seconds
     */
    public update(deltaTime: number): void {
        this._updateCenter();
        const maxSpeedSquared = this._maxSpeed * this._maxSpeed;
        this._boids.forEach((boid) => {
          const f1 = this._forceCentreMass(boid);
          const f2 = this._forceSeparation(boid);
          const f3 = this._forceMatchVelocity(boid);
          const f4 = this._forceBoundaries(boid);
          const f = f1.add(f2).add(f3).add(f4);

          this._otherForces.forEach((forceCallback) => {
            f.add(forceCallback(this, boid));
          });

          // force = mass * acceleration
          boid.force.copyFrom(f);
          boid.velocity.addInPlace(f.scale(deltaTime));

          // clamp velocity
          if (boid.velocity.lengthSquared() > maxSpeedSquared) {
            boid.velocity = boid.velocity.normalize().scale(this._maxSpeed);
          }

          boid.position.addInPlace(boid.velocity.scale(deltaTime));
        });
        this._updateDebug();
    }

    /**
     * Recalculates the center of mass amd average velocity
     */
    _updateCenter() {
        if (!this._boids.length) {
            return;
        }
        const center = new Vector3(0, 0, 0);
        const avgVel = new Vector3(0, 0, 0);

        this._boids.forEach((boid) => {
            center.addInPlace(boid.position);
            avgVel.addInPlace(boid.velocity);
        });

        center.scaleInPlace(1.0 / this._boids.length);
        avgVel.scaleInPlace(1.0 / this._boids.length);

        this._center = center;
        this._avgVel = avgVel;
    }

    /**
     * Boids try to fly towards the centre of mass of neighbouring boids.
     * @param {Boid} boid
     */
    _forceCentreMass(boid) {
        // TODO: we could remove the boid position from the average
        return this._center.subtract(boid.position).scale(this._cohesion);
    }

    /**
     * Boids try to keep a small distance away from other objects (including other boids).
     * @param {Boid} boid
     */
    _forceSeparation(boid) {
        const f = new Vector3(0, 0, 0);

        // TODO this is n^2, improve
        this._boids.forEach((other) => {
            if (boid.id === other.id) {
                return;
            }
            const v = boid.position.subtract(other.position);
            const d2 = v.length();
            if (d2 < this._separationMinDistance) {
                f.addInPlace(v.scale(this._separationMinDistance - d2));
            }
        });
        return f.scale(this._separation);
    }

    /**
     * Boids try to match velocity with near boids.
     * @param {Bird} boid
     */
    _forceMatchVelocity(boid) {
        // TODO: we could remove the boid position from the average
        return this._avgVel.subtract(boid.velocity).scale(this._alignment);
    }

    /**
     * Boids want to get away from boundaries
     * @param {Bird} boid
     */
    _forceBoundaries(boid) {
        const f = new Vector3(0, 0, 0);
        const amount = 0.2;
        // clamp to area
        if (boid.position.x < this._boundsMin.x * 0.9) {
            f.x = amount;
        } else if (boid.position.x > this._boundsMax.x * 0.9) {
            f.x = -amount;
        }
        if (boid.position.y < this._boundsMin.y * 0.9) {
            f.y = amount;
        } else if (boid.position.y > this._boundsMax.y * 0.9) {
            f.y = -amount;
        }
        if (boid.position.z < this._boundsMin.z * 0.9) {
            f.z = amount;
        } else if (boid.position.z > this._boundsMax.z * 0.9) {
            f.z = -amount;
        }
        return f;
    }

    /**
     * Adds a new force to the callback list. The callback receives a {Bird} as argument.
     *
     * @param {callback} boid
     */
    addForce(c) {
        this._otherForces.push(c);
    }

    /**
     * Turns on debug menu and helpers.
     * @param {BABYLON.Scene} scene
     */
    showDebug(scene) {
        if (this._debug.show) {
            return;
        }

        // build a material
        const centerMaterial = new StandardMaterial("debug_center", scene);
        centerMaterial.diffuseColor = Color3.FromHexString("#FF0000");
        this._debug.center = MeshBuilder.CreateSphere("center", {
            diameter: 0.1,
            segments: 8,
        });

        // build bbox
        const bboxMaterial = new StandardMaterial("debug_bbox", scene);
        bboxMaterial.diffuseColor = Color3.FromHexString("#00FF00");
        bboxMaterial.wireframe = true;
        this._debug.center.material = centerMaterial;

        this._debug.bbox = Mesh.CreateBox("boids_bbox", 1.0, scene);
        this._debug.bbox.scaling.x = Math.abs(
            this._boundsMax.x - this._boundsMin.x
        );
        this._debug.bbox.scaling.y = Math.abs(
            this._boundsMax.y - this._boundsMin.y
        );
        this._debug.bbox.scaling.z = Math.abs(
            this._boundsMax.z - this._boundsMin.z
        );
        this._debug.bbox.position.x =
            Math.abs(this._boundsMax.x - this._boundsMin.x) / 2;
        this._debug.bbox.position.y =
            Math.abs(this._boundsMax.y - this._boundsMin.y) / 2;
        this._debug.bbox.position.z =
            Math.abs(this._boundsMax.z - this._boundsMin.z) / 2;
        this._debug.bbox.material = bboxMaterial;

        const wireframeMaterial = new StandardMaterial(
            "debug_wireframe",
            scene
        );
        wireframeMaterial.diffuseColor = Color3.FromHexString("#FFFFFF");
        wireframeMaterial.wireframe = true;
        for (const boid of this._boids) {
            boid.debug = {
                show: true,
                arrows: [],
            };
            boid.debug.force = MeshBuilder.CreateTube(
                `boid_arrow_${boid.id}`,
                {
                    path: [
                        boid.position.add(boid.velocity),
                        boid.position.clone(),
                    ],
                    radius: 0.01,
                    updatable: true,
                },
                scene
            );
            boid.debug.influence = MeshBuilder.CreateSphere(
                `boid_influence_${boid.id}`,
                {
                    diameter: 1.0,
                    segments: 8,
                }
            );
            boid.debug.influence.scaling.setAll(this._separationMinDistance);
            boid.debug.influence.material = wireframeMaterial;
        }
        this._debug.show = true;
    }

    /**
     * Hides debug helpers.
     *
     */
    hideDebug() {
        this._debug.show = false;
        this._debug.center = undefined;
        for (const boid of this._boids) {
            if (boid.debug) {
                boid.debug.force.dispose();
                boid.debug.influence.dispose();
                boid.debug = {
                    show: false,
                    arrows: [],
                };
            }
        }
    }

    /**
     * Updates debug data internally.
     */
    _updateDebug() {
        if (!this._debug.show || !this._center) {
            return;
        }
        this._debug.center.position.copyFrom(this._center);
        for (const boid of this._boids) {
            const path = [
                boid.position.add(boid.force.scale(20.0)),
                boid.position.clone(),
            ];
            boid.debug.force = MeshBuilder.CreateTube(boid.debug.force.name, {
                path,
                radius: 0.01,
                instance: boid.debug.force,
            });
            boid.debug.influence.position.copyFrom(boid.position);
        }
    }
}

class Boid {
    public id: number;
    public position: Vector3;
    public velocity: Vector3;
    public force: Vector3;
    public debug: Debug;

    constructor(id, position, velocity) {
        this.id = id;
        this.position = position;
        this.velocity = velocity;
        this.force = new Vector3(0, 0, 0);
    }

    get orientation() {
        return this.velocity.normalize();
    }
}

class Debug {
    show: boolean;
    arrows: any[];

    influence?: Mesh;
    center?: Mesh;
    bbox?: Mesh;
    force?: Mesh;
}
