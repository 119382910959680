import { ScriptMap } from "./tools";

/**
 * Defines the interface that exposes all exported scripts in this project.
 */
export interface ISceneScriptMap {
	"src/scenes/boids-manager.ts": ScriptMap;
	"src/scenes/close-net.ts": ScriptMap;
	"src/scenes/fishboid.ts": ScriptMap;
	"src/scenes/fishmovement.ts": ScriptMap;
	"src/scenes/fishnet-movement.ts": ScriptMap;
	"src/scenes/GameManager/animation-manager.ts": ScriptMap;
	"src/scenes/GameManager/audio-manager.ts": ScriptMap;
	"src/scenes/GameManager/credits.ts": ScriptMap;
	"src/scenes/GameManager/depth-meter.ts": ScriptMap;
	"src/scenes/GameManager/fog-manager.ts": ScriptMap;
	"src/scenes/GameManager/footer.ts": ScriptMap;
	"src/scenes/GameManager/game-manager.ts": ScriptMap;
	"src/scenes/GameManager/gui-manager.ts": ScriptMap;
	"src/scenes/GameManager/hotspot-overlay.ts": ScriptMap;
	"src/scenes/GameManager/hud.ts": ScriptMap;
	"src/scenes/GameManager/inventory.ts": ScriptMap;
	"src/scenes/GameManager/media-overlay.ts": ScriptMap;
	"src/scenes/GameManager/model-viewer.ts": ScriptMap;
	"src/scenes/GameManager/narration.ts": ScriptMap;
	"src/scenes/GameManager/player.ts": ScriptMap;
	"src/scenes/GameManager/quiz.ts": ScriptMap;
	"src/scenes/GameManager/rotation-hint.ts": ScriptMap;
	"src/scenes/GameManager/sidenav.ts": ScriptMap;
	"src/scenes/GameManager/tracking-manager.ts": ScriptMap;
	"src/scenes/GameManager/waypoint-manager.ts": ScriptMap;
	"src/scenes/material-loader.ts": ScriptMap;
	"src/scenes/net-movement.ts": ScriptMap;
	"src/scenes/particle-system-loader.ts": ScriptMap;
	"src/scenes/scroll.ts": ScriptMap;
	"src/scenes/snippet-shader.ts": ScriptMap;
	"src/scenes/spritesheet-manager.ts": ScriptMap;
	"src/scenes/utilities.ts": ScriptMap;
}

/**
 * Defines the map of all available scripts in the project.
 */
export const scriptsMap: ISceneScriptMap = {
	"src/scenes/boids-manager.ts": require("./boids-manager"),
	"src/scenes/close-net.ts": require("./close-net"),
	"src/scenes/fishboid.ts": require("./fishboid"),
	"src/scenes/fishmovement.ts": require("./fishmovement"),
	"src/scenes/fishnet-movement.ts": require("./fishnet-movement"),
	"src/scenes/GameManager/animation-manager.ts": require("./GameManager/animation-manager"),
	"src/scenes/GameManager/audio-manager.ts": require("./GameManager/audio-manager"),
	"src/scenes/GameManager/credits.ts": require("./GameManager/credits"),
	"src/scenes/GameManager/depth-meter.ts": require("./GameManager/depth-meter"),
	"src/scenes/GameManager/fog-manager.ts": require("./GameManager/fog-manager"),
	"src/scenes/GameManager/footer.ts": require("./GameManager/footer"),
	"src/scenes/GameManager/game-manager.ts": require("./GameManager/game-manager"),
	"src/scenes/GameManager/gui-manager.ts": require("./GameManager/gui-manager"),
	"src/scenes/GameManager/hotspot-overlay.ts": require("./GameManager/hotspot-overlay"),
	"src/scenes/GameManager/hud.ts": require("./GameManager/hud"),
	"src/scenes/GameManager/inventory.ts": require("./GameManager/inventory"),
	"src/scenes/GameManager/media-overlay.ts": require("./GameManager/media-overlay"),
	"src/scenes/GameManager/model-viewer.ts": require("./GameManager/model-viewer"),
	"src/scenes/GameManager/narration.ts": require("./GameManager/narration"),
	"src/scenes/GameManager/player.ts": require("./GameManager/player"),
	"src/scenes/GameManager/quiz.ts": require("./GameManager/quiz"),
	"src/scenes/GameManager/rotation-hint.ts": require("./GameManager/rotation-hint"),
	"src/scenes/GameManager/sidenav.ts": require("./GameManager/sidenav"),
	"src/scenes/GameManager/tracking-manager.ts": require("./GameManager/tracking-manager"),
	"src/scenes/GameManager/waypoint-manager.ts": require("./GameManager/waypoint-manager"),
	"src/scenes/material-loader.ts": require("./material-loader"),
	"src/scenes/net-movement.ts": require("./net-movement"),
	"src/scenes/particle-system-loader.ts": require("./particle-system-loader"),
	"src/scenes/scroll.ts": require("./scroll"),
	"src/scenes/snippet-shader.ts": require("./snippet-shader"),
	"src/scenes/spritesheet-manager.ts": require("./spritesheet-manager"),
	"src/scenes/utilities.ts": require("./utilities"),
}
